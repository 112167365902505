@import "../../assets/styles/_colors";
@import "../../assets/styles/_measures";

.info-card {
  display: flex;
  flex-direction: row;
  gap: $margin;
  border-radius: 8px;
  background-color: lighten($neutral, 35%);
  padding: $margin;

  + .info-card {
    margin-top: $margin;
  }

  &__step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid $background;
    border-radius: 50%;
    background: mix($primary, $background, 20%);
    height: 42px;
    width: 42px;
    color: $primary;
    font-size: 1.4rem;
    font-family: Quicksand, serif;
    font-weight: bold;
    text-align: center;

    > span {
      display: block;
    }
  }

  &__body {
    font-size: 13px;

    &__title {
      margin-bottom: $margin / 2;
      color: $primary;
      font-size: 1rem;
      font-family: Quicksand, serif;
      font-weight: bold;
    }
  }
}
