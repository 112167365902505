@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.customers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $customers-header-height;
  background-color: $menu-color;
  width: 100%;
  padding: 0 1rem;
  .customers-header-buttons {
    display: flex;
    align-items: center;
    & .add-customer {
      margin-left: 1rem;
    }
  }
  .btn {
    line-height: 1rem;
  }


  .toggle.btn {
    border: none;
    background-color: white;
    color: $primary;
    width: 38px;
    padding: 2px 5px 0;
    font-size: 24px;
    margin: 0;
  }
  .btn.right {
    float: right;
  }
  .btn.search {
    background-color: white;
    color: $primary;
    margin-right: 7.5px;
  }
  .toggle.selected.btn {
    border: none;
    color: white;
    background-color: $primary;
  }
}

@media (max-width: $breakpoint) {
  .add-customer {
    display: none;
  }
  .title {
    display: none;
  }
}
// for desktop
@media (min-width: $breakpoint_safe_min) {
  .customers-header {
    padding: 0 $padding-rem;
  }
  .title {
    display: inline;
    padding-right: 10px;
    vertical-align: -3px;
    font-family: Quicksand, sans-serif;
    font-size: 27px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  .btn.add {
    span {
      display: inline;
      width: 90px;
      height: 11px;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: white;
      line-height: -1px;
      vertical-align: 3px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
