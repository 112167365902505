@import "./boostrap-override.scss";
@import "../icons/icons.css";
@import "_fonts.scss";
@import "overide-datepicker.scss";

html,body, #root {
  height: 100%;
  overflow-x: hidden;
}

html{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

a.btn {
  &.btn-primary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: $background;
    background-color: $btn-background;
    border: none;
    &:hover:not([disabled]) {
      background-color: $hover-color;
      cursor: pointer;
    }
    &:disabled {
      background-color: white;
      color: $btn-disabled-color;
      border: solid 2px $btn-disabled-background;
    }
  }
  &.btn-secondary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: $btn-background;
    background-color: $secondary-btn;
    border: none;
    &:hover:not([disabled]) {
      cursor: pointer;
      color: $hover-color;
    }
    &:disabled {
      background-color: white;
      color: $btn-disabled-color;
      border: solid 2px $btn-disabled-background;
    }
  }
}

input::placeholder {
  opacity: .5!important;
}

.icon-sort {
  width: 13px;

  &:before {
    border: 4px solid transparent;
    border-bottom-color: $primary;
    margin-top: -9px;

    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  &:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    border-top-color: $primary;
    margin-top: 1px;
  }
}
.icon-sort.up {
  &:before {
    opacity: 0.3;
  }
}
.icon-sort.down {
  &:after {
    opacity: 0.3;
  }
}

@media (min-width: $breakpoint_safe_min) {
  body {
    overflow-y: hidden;
  }
}
.form-wrapper {
  padding: 15px 10px 15px 10px;
}

.form-container {
  background: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.icon-sort {
  width: 15px;

  &:before {
    border: 4px solid transparent;
    border-bottom-color: $primary;
    margin-top: -9px;

    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    opacity: 0.3;
  }
  &:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    border-top-color: $primary;
    margin-top: 1px;
    opacity: 0.3;
  }
}
.icon-sort.up {
  &.before,
  &.after {
    border-top-color: $primary;
  }

  &:before {
    opacity: 1;
  }
}
.icon-sort.down {
  &.before,
  &.after {
    border-top-color: $primary;
  }

  &:after {
    opacity: 1;
  }
}

.icon-full-arrow-right {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: $primary;
  box-sizing: border-box;
  width: 18px;
  height: 20px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  margin: 10px;
  transform: rotate(45deg) scale(0.6);
  border-radius: 0 2px 0 0;
  &:before,
  &:after {
    border-radius: 2px;
    position: absolute;
    content: "";
    box-sizing: border-box;
  }
  &:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    transform: rotate(-45deg);
    transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  &:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
}

@media(max-width: $breakpoint){
  .mobile-helper {
    padding: 0;
  }
}

.text-warning {
  color: $warning;
}

.text-success {
  color: $correct;
}

.text-primary {
  color: $primary!important; // override bootstrap default color
}
