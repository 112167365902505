.modbus-icon {
  padding: 4.8px;

  .st0 {
    fill: #0090aa;
  }

  .st1 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill: none;
    stroke: #0090aa;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st3 {
    fill: #ffffff;
    stroke: #0090aa;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st4 {
    fill: #ffffff;
    stroke: #0090aa;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill: #ffffff;
  }

  .st6 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 0.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st7 {
    fill: #0090aa;
    stroke: #0090aa;
    stroke-width: 0.2341;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st8 {
    fill: #0090aa;
    stroke: #0090aa;
    stroke-width: 0.2038;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st9 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-width: 1.75;
    stroke-miterlimit: 10;
  }

  .st10 {
    fill: #ffffff;
    stroke: #ffffff;
    stroke-width: 1.1969;
    stroke-miterlimit: 10;
  }

  .st11 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-miterlimit: 10;
  }

  .st12 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
  }

  .st13 {
    fill: none;
    stroke: #ddd;
    stroke-width: 1.25;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st14 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 1.25;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st15 {
    fill: none;
    stroke: #ddd;
    stroke-miterlimit: 10;
  }

  .st16 {
    fill: none;
    stroke: #0090aa;
    stroke-miterlimit: 10;
  }

  .st17 {
    clip-path: url(#SVGID_2_);
  }

  .st18 {
    fill: none;
    stroke: #ddd;
    stroke-width: 4.4846;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st19 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4.4846;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st20 {
    fill: none;
    stroke: #e7e6e6;
    stroke-width: 2.4779;
    stroke-miterlimit: 10;
  }

  .st21 {
    fill: #afdbe5;
  }

  .st22 {
    fill: #067480;
  }

  .st23 {
    fill: #0991aa;
  }

  .st24 {
    fill: #5cb7c9;
  }

  .st25 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 0.25;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
}
