@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.isolated {
  & .isolated-header {
    display: flex;
    height: 5.75rem;
    background-color: $menu-color;
    & .go-back {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      & i {
        transform: rotate(-135deg) scale(0.8);
        cursor: pointer;
      }
    }
    & .header-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      & .header-title {
        font-weight: bold;
        font-family: Quicksand, serif;
        font-size: 1.7rem;
      }
      & .header-subtitle {
        color: $font;
        font-size: 0.85rem;
        font-family: Quicksand, sans-serif;
      }
    }
  }
  & .isolated-content {
    justify-content: center;
    height: calc(100vh - 5.75rem);
    overflow: auto;
    & .isolated-content-container {
      height: 100%;
    }
    & .spinner {
      flex-grow: 1;
      height: 100%;
      &::after {
        background-color: transparent;
      }
    }
    & .list-wrapper {
      margin: 0;
      height: 100%;
    }
    & .table-row {
      height: auto;
      overflow: unset;
    }
  }
}

@media (max-width: $breakpoint) {
  .isolated {
    & .isolated-header {
      height: 4rem;
      & .go-back {
        padding: 0 1rem;
      }
      & .header-content {
        & .header-title {
          font-size: 1.2rem;
        }
        & .header-subtitle {
          font-size: 0.75rem;
        }
      }
    }
    & .isolated-content {
      height: calc(100vh - 4rem);
      min-height: unset;
    }
  }
}
