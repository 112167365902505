@import "../../../assets/styles/_colors.scss";
.btn-loader.loading:disabled::after {
  border-color: #d3d3dd transparent transparent transparent;
}

.btn-loader.loading::after {
  content: " ";
  -webkit-transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 3px solid;
  border-radius: 50%;
  position: absolute;
  margin-left: 10px;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;

  animation: lds-ring 1.2s infinite;
}

$check-width: 8px;
$check-height: 16px;

.btn-secondary.btn-loader::after {
  border-color: $primary;
}

.btn-secondary.btn-loader::after {
  border-color: $primary transparent transparent transparent;
}

.btn-loader.done::after {
  content: "";
  border-right: 2px solid;
  border-top: 2px solid;
  display: inline-block;

  transform-origin: left top;
  width: $check-width;
  height: $check-height;
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  position: absolute;
  margin-top: 9px;
  margin-left: 11px;
}

.btn-loader.loading,
.btn-loader.done {
  padding-right: 35px;

  transition: 0.5s padding-right ease-out;
}
.btn-loader {
  padding-right: 15px;

  transition: 0.5s padding-right ease-out;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  50% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
