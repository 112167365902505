.msb-status-text {
  font-size: 0.75rem;
  text-transform: uppercase;

  &--activated {
    color: #7ed321;
  }
  &--created {
    color: #0ea5e9;
  }
}
