@import "../../assets/styles/_colors.scss";
.registration-stepper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  & .table-row {
    height: auto;
    &:last-child {
      border-bottom: 0;
    }
    & form {
      width: 100%;
    }
  }
  & .list-wrapper {
    margin-top: 0;
  }

  & .registration-stepper-header {
    display: flex;
    flex-direction: column;
    height: 8.5rem;
    min-height: 8.5rem;
    background-color: $menu-color;
    & .header-content {
      display: flex;
      flex-grow: 1;
      padding: 0 2rem;
      & .arrow-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 4.5rem;
        & i {
          transform: rotate(-135deg) scale(0.8);
        }
      }
      & .customer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        & .customer-name, .customer-surname {
          font-weight: bold;
          font-family: Quicksand, serif;
          font-size: 1.7rem;
        }
        & .customer-surname {
          text-transform: uppercase;
          padding-right: 0.5rem;
        }

        & .customer-address {
          a {
            color: $font;
            font-size: 0.85rem;
            font-family: Quicksand, sans-serif;
            text-decoration: none;
            &:hover {
              color: $hover-color;
            }
          }
        }
      }
    }

    & .header-title {
      display: flex;
      align-items: center;
      height: 2.75rem;
      padding: 0 2rem;
      background-color: $main-menu-background;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.76rem;
      font-family: Lato, sans-serif;
    }
  }

  & .registration-stepper-container {
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow: auto;
    & .registration-stepper-menu {
      display: flex;
      flex-direction: column;
      width: 16rem;
      min-width: 16rem;
      background-color: $menu-color;
      & .menu-item {
        display: flex;
        align-items: center;
        height: 4rem;
        color: $font;
        border-bottom: 2px solid $secondary-btn;
        text-decoration: none;
        padding-left: 2rem;
        &.active {
          border-left: 0.3rem solid #009dbf;
          padding-left: 1.7rem;
          font-weight: bold;
        }
        &.disabled {
          background-color: $main-menu-background;
          color: $secondary-font;
          font-weight: bold;
        }
        & .item-step {
          height: 1.6rem;
          width: 1.6rem;
        }
        & .item-name {
          display: flex;
          padding-left: 1rem;
          span {
            font-size: 0.95rem;
            padding-right: 0.3rem;
            font-family: Quicksand, sans-serif;
          }
        }
      }
    }
    & .registration-stepper-content {
      flex-grow: 1;
      overflow: auto;
      & .spinner {
        height: 100%;
        width: 100%;
      }
    }
  }
}
