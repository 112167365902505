@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.full-page-list {
  height: 100%;
}
.customer-lastName {
  text-transform: uppercase;
}
.registration-status {
  padding-top: 0.2rem;
  min-width: 15rem;
  & .registration-status-title {
    padding-right: 0.5rem;
    color: $secondary-font;
    font-family: Quicksand, sans-serif;
  }
}

@media (max-width: $breakpoint) {
  .full-page-list {
    height: calc(100vh - #{$main-menu-content-header-height-mobile} - #{$customers-header-height});
  }
}
