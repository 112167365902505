@import '../../../../assets/styles/colors';

.new-device-list {
 & .device-type {
   padding: 1rem;
   & .device-type-name {
     color: #2e2e2e;
     padding-bottom: 0.25rem;
     font-weight: 600;
     font-family: Quicksand, serif;
     font-size: 0.86rem;
   }
   & .arrow-icon,
   & .device-type-helper {
     flex-grow: 0;
   }

   & .device-type-helper {
     color: $primary;
   }
 }
  & .cancel-button {
    float: right;
    margin: 1rem 0;
  }
}

.add-device {
  & .form-wrapper {
    padding: 2rem 0;
    & .input-field {
      width: 100%;
      padding: 0.5rem 0;
    }
    & .scan-button {
      justify-content: flex-end;
    }
    & .add-device-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      & button {
        margin-left: 1rem;
      }
    }
  }
}

.device-type-image-container {
  text-align: center;

  .device-type-image {
    max-width: 100%;
    max-height: 200px;
  }

  > p {
    margin: 0;
  }
}

.hint-button {
  margin: 0 10px;
}

.upm-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 100%;
    max-height: 300px;
  }
}

.wmodbus-device {
  &-list {
    margin: 0 -15px;
    padding: 0;
    list-style: none;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }
}
