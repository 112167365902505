@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
@import "../../../assets/styles/fonts";

.main-menu {
  width: 100%;
  height: 100%;
  display: grid;

  .header-notification-button.icon-notification {
    display: none;
  }

  .main-menu-content-header {
    display: flex;
    align-items: center;

    i {
      padding: 3px;
      cursor: pointer;
      position: absolute;
      left: 0;
      display: flex;
      color: #008fa7;
      font-size: 2.5rem;
    }

    i:hover {
      color: #008fa7;
      text-decoration: none;
      border-bottom: none;
    }

    label {
      font-family: Quicksand, serif;
      font-weight: 600;
      padding: 11px;
      margin: 0;
    }
  }

  .menu-overlay {
    .menu-items {
      display: grid;
      grid-template-columns: 1fr;
      height: 100%;
      width: $menu-width;
      background-color: $main-menu-background;
    }

    .main-menu-header {
      grid-column-start: 1;
      grid-row-start: 1;

      i {
        color: $primary;
        margin-left: 12px;
        font-size: 2.5rem;
        cursor: pointer;
        align-self: flex-end;
      }
    }

    .nav {
      overflow-y: auto;
      flex-wrap: nowrap;
    }

    .main-menu-footer {
      grid-column-start: 1;
      grid-row-start: 3;

      .main-menu-items-footer,
      .main-menu-items-version {
        width: 100%;
        letter-spacing: 2.4px;
        font-size: 12px;
        text-align: center;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #afafb7;
      }

      .main-menu-items-version {
        font-size: 9px;
        font-weight: normal;
      }
    }

    .main-menu-logo {
      width: 125px;
      margin-left: $margin;
      object-fit: cover;
    }

    .main-menu-item {
      cursor: pointer;
      font-family: "Lato", sans-serif;
      font-size: 12px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.82;
      letter-spacing: 0.6px;
    }

    .main-menu-item,
    .main-menu-header {
      height: $main-menu-item-height;
      display: flex;
      align-items: center;
    }

    .main-menu-notifier {
      height: $main-menu-item-height;
      width: 5px;

      &.active {
        background-color: $primary;
      }
    }

    .main-menu-link {
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      text-transform: uppercase;
      color: #2e2e2e;
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-left: $margin;

      & span {
        margin-left: $margin;
      }

      &.active {
        color: $primary;
      }
    }

    .main-menu-icon {
      font-size: 2.2rem;
      display: flex;

      align-items: center;
      justify-content: center;
      width: 45px;
      height: $main-menu-item-height;

      i {
        color: #afafb7;

        &.active {
          color: $primary;
        }
      }
    }

    .prosizer-icon {
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      padding: 6px;
      height: $main-menu-item-height;
      fill: #afafb7;
    }
  }
}

@media (max-width: $breakpoint) {
  .main-menu {
    grid-template-columns: $menu-width 1fr;
    grid-template-rows: $main-menu-content-header-height-mobile 1fr;

    .main-menu-content-header {
      grid-column-start: 1;
      grid-column-end: 2 span;
      grid-row-start: 1;
      justify-content: center;
      background: $main-menu-background;
      z-index: 3;
    }

    .main-menu-content::before {
      top: 10px;
      z-index: 2;
      position: fixed;
      left: -3px;
      width: 300px;
      background: #97424200;
      box-shadow: 0 15px 10px #7777777d;
      content: " ";
      display: block;
      float: left;
      height: 29px;
      padding-left: 20px;
      transform: rotate(-4deg);
    }

    .main-menu-content {
      grid-column-start: 1;
      grid-column-end: 2 span;
      grid-row-start: 2;
      grid-row-end: 3 span;
      overflow-y: auto;
    }

    .menu-overlay {
      grid-row-start: 1;
      grid-row-end: 2 span;
      grid-column-start: 1;
      grid-column-end: 2 span;
      z-index: 5;


      .menu-items {
        grid-template-rows: 60px 1fr 65px;
        box-shadow: 0 2px 13px 1px rgba(3, 0, 69, 0.1);
        -webkit-transition: 0.5s ease-out;
        -moz-transition: 0.5s ease-out;
        -o-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
        transform: translate(-100%, 0);
      }

      background-color: transparent;
      visibility: hidden;
    }

    .menu-overlay.visible {
      .menu-items {
        -webkit-transition: 0.5s ease-out;
        -moz-transition: 0.5s ease-out;
        -o-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
        transform: translate(0, 0);
      }

      background-color: rgba(0, 0, 0, 0.3);
      visibility: visible;
    }

  }
}

@media (min-width: $breakpoint_safe_min) {
  .main-menu {
    grid-template-columns: $menu-width 1fr;
    grid-template-rows: $main-menu-content-header-height 1fr;

    .main-menu-content-header {
      grid-column-start: 2;
      grid-row-start: 1;
      justify-content: flex-start;
      border-bottom: solid 1px white;
      background: $menu-color;
      z-index: 3;

      .page-name {
        padding-left: $padding-rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 19px;
      }

      .icon-menu {
        display: none;
      }
    }

    .menu-overlay,
    .menu-overlay.visible {
      .main-menu-logo {
        width: 165px;
        margin: 0;
      }

      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: span 2;

      .menu-items {
        grid-template-rows: 124px 1fr 65px;
        .main-menu-header {
          .header-notification-button {
            color: $secondary-font;
            margin-right: 21px;
            font-size: 2rem;
          }

          display: flex;
          height: auto;
          justify-content: center;
          align-items: center;

          i {
            display: none;
          }
        }
      }
    }

    .menu-overlay::before {
      z-index: 2;
      position: fixed;
      left: 136px;
      width: 300px;
      bottom: 136px;
      background: #97424200;
      -webkit-box-shadow: -5px 0px 15px 10px #7777777d;
      box-shadow: -5px 0px 15px 10px #77777766;
      content: " ";
      display: block;
      float: left;
      height: 30px;
      padding-left: 20px;
      -webkit-transform: rotate(-4deg);
      -ms-transform: rotate(-4deg);
      transform: rotate(94deg);
    }

    .main-menu-content {
      grid-column: 2;
      grid-row-start: 2;
      grid-row-end: 3 span;
      overflow-y: auto;
      overflow-x:hidden;
      background: white;
      z-index: 4;
    }
  }
}
