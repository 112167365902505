@import "../../../assets/styles/_colors.scss";

.ucg-preconfiguration-wizard {
  margin: 15px 0;
  font-size: 0.85rem;
  text-align: justify;
  & .ucg-preconfiguration-wizard-title {
    color: $primary;
    font-weight: bold;
  }
  & .ucg-preconfiguration-wizard-info {
    margin-top: 5px;
    color: $secondary-font;
  }
}