@import "../../../../assets/styles/_colors.scss";
@import "../../../../assets/styles/_measures.scss";

.system-devices-and-functions {
  border: 2px solid $btn-disabled-background;
  border-radius: 10px;
  margin: 0.5rem;

  & .table-row {
    height: auto;
  }

  & .system-device-item {
    flex-wrap: nowrap !important;
    align-items: unset;
    border-bottom: 0 !important;
    & .device-container {
      flex-grow: 1;
      display: flex;
      & .device-info {
        flex-grow: 1;
        padding: 1rem;
        &.modbus {
          padding: 1.594rem 1rem;
        }
        & .device-title {
          align-items: center;
          flex-wrap: nowrap;
          & .tenant-logo {
            height: 3rem;
            width: 3rem;
            & img {
              width: 100%;
              height: 100%;
              max-height: unset;
            }
          }
          & .device-name {
            color: $font;
            padding-bottom: 0.25rem;
            font-weight: 600;
            font-family: Quicksand, serif;
            font-size: 0.86rem;
          }
          & .room-name {
            font-family: Quicksand, serif;
          }
        }
        & .device-details {
          display: flex;
          flex-direction: column;
          padding-top: 0.5rem;
          font-family: Quicksand, serif;
          span {
            font-size: 0.75rem;
          }
          & .activation-code,
          & .mac {
            padding-right: 0.4rem;
          }
        }
      }
      & .alert {
        display: flex;
        align-items: center;
        padding-right: 1.75rem;
        & .fa-exclamation-circle {
          color: $danger;
          font-size: 1.225rem;
          margin-bottom: 5px;
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
  }

  & .device-functions {
    border-top: 2px solid $btn-disabled-background;

    & .system-device-function-item {
      border-bottom: 1px solid $btn-disabled-background;

      &.for-composite {
        flex-direction: column;
        align-items: unset;
        & .composite-device {
          padding-bottom: 0.5rem;
          & .composite-device-children {
            padding: 0.25rem 6rem;
            @media screen and (max-width: $breakpoint_mobile){
              padding: 0.25rem 5rem;
            }
            & .children-power {
              flex-grow: 0;
              & .badge {
                padding: 0.3rem 0.75rem;
              }
            }
          }
        }
      }
      & .function-container {
        flex-grow: 1;
        align-items: center;
        padding: 1.157rem 15px;
        color: $font;
        & .device-info {
          flex-grow: 1;
          & .device-function {
            padding-bottom: 0.25rem;
            font-size: 1rem;
          }
          & .device-icon {
            flex-grow: 0;
            padding-right: 0;
          }
          & .device-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: Quicksand, serif;
            & .device-type {
              padding-bottom: 0.25rem;
              font-weight: 600;
              font-size: 0.86rem;
            }
            & .device-room-name, & .device-mac {
              font-size: 0.75rem;
            }
          }
        }
        & .device-power {
          display: flex;
          align-items: center;
          flex-grow: 0;
          padding: 0 0.5rem;
          & .badge {
            padding: 0.3rem 0.75rem;
          }
        }
        & .green-play {
          font-family: Quicksand, serif;
          font-size: 0.7rem;
          width: max-content;
          color: $correct;
          font-weight: bold;
        }
      }

      &:last-child {
        border-bottom: 0 !important;

        & .function-container {
          border-radius: 0 0 10px 10px;
        }
      }

      & .function-container {
        padding: 0.5rem 1.5rem;

        & .device-icon {
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .system-device-function-item {
    &.for-composite {
      & .composite-device {
        & .composite-device-children {
          padding: 0.25rem 3rem;
        }
      }
    }
  }
  .system-device-item {
    & .device-container {
      & .alert {
        padding-right: 1.5rem !important;
      }
    }
  }
}