@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_measures.scss";

.table-row {
  display: flex;
  align-items: center;
  height: 63px;
  border-bottom: 2px solid $border-color;

  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  color: $font;
  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: aliceblue;
    }
  }
  word-wrap: break-word;
}

.list-placeholder {
  color: $secondary-font;
  font-size: 1.1rem;
  margin-top: 0.75rem;
  text-align: center;
  display: block;
}

.table-header {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: $table-head-font-color;
  font-weight: 600;
  font-size: 11px;
}

.list-wrapper {
  margin: 1rem 0 2rem 0;
  .headers {
    display: flex;
    text-transform: uppercase;
    color: $table-head-font-color;
    font-size: 11px;
    font-weight: 600;
    border-bottom: 2px solid $border-color;
    padding: 1rem 0;
  }
  .table-header {
    border-top: none;
  }
  .list-title {
    height: 4rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $font;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 900;
    border-bottom: 2px solid #f4f4fa;
  }
}
@media (max-width: $breakpoint) {

  .list-wrapper {
    padding: 0;
    .headers {
      display: none;
    }
    .table-row {
      flex-wrap: wrap;
      padding: 5px 0;
      min-height: 3.5rem;
      height: inherit;
      & > div[class*='col-'] {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
}
@media (min-width: $breakpoint_safe_min) {
  .list-wrapper {
    .table-header {
      border-top: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
      margin: 0 15px;
      padding: 10px 0 10px 0;
    }
  }
}
