@import "../../assets/styles/_measures.scss";

.container__tools-pairing {
  .registration-device-item {
    height: 100%;

    .device-container {
      @media screen and (max-width: $breakpoint_mobile) {
        display: block;
      }

      .device-info {
        width: 600px;
        max-width: 600px;
      }

      .device-power {
        flex-grow: 1;
        padding: 1rem;
        @media screen and (max-width: $breakpoint_mobile) {
          padding-top: 0;
        }

        .ct-container {
          margin-bottom: 5px;
        }
      }
    }
  }
}


