@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.create-customer-dropdown {
  width: 150px;
  .btn.dropdown-toggle {
    background-color: $primary;
    color: white;
    width: 100%;
    box-shadow: none;
    &:hover:not(disabled) {
      color: white;
    }
    &:active {
      background-color: $primary;
    }
  }
  .dropdown-menu {
    min-width: 0;
    width: 100%;
    .create-customer-item {
      cursor: pointer;
      padding: 3px 5px;
      .tenant-logo {
        margin-right: 5px;
      }

      i {
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px;
      }

      &:hover {
        color: $primary;
      }
    }
  }
}

.show > .btn-secondary.dropdown-toggle {
  background-color: $primary;
  color: white;
}

.create-customer-button {
  width: 150px;
}
