@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.is-system-offline-indicator {
  font-family: Lato, sans-serif;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 $padding-rem;
  background-color: $danger;
  color: white;
  font-size: 14px;
}
