@import "../../../assets/styles/_colors.scss";

$size: 26px;

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  &.centered {
    justify-content: center;
  }
  label {
    margin: 0 10px;
  }
}
.tgl-btn.checked:after {
  left: 50%;
}
.tgl-btn.checked {
  background: $primary;
}
.un-selectable {
  user-select: none;
  cursor: pointer;
}
.tgl-btn {
  display: inline-block;
  vertical-align: -18px;
  margin-left: 10px;
  margin-right: 10px;
  outline: 0;
  width: $size * 2;
  height: $size;
  position: relative;
  cursor: pointer;
  &:after,
  &:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  background: #f0f0f0;
  border-radius: 5px;
  padding: 2px;
  transition: all 0.4s ease;
  &:after {
    border-radius: 5px;
    background: #fff;
    transition: all 0.2s ease;
  }

  &:after {
    left: 0;
  }

  &:before {
    display: none;
  }
}

