@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_measures.scss";

.registration-function-item {
  border-bottom: 2px solid #f4f4fa !important;
  &:hover {
    & .function-container {
      & .device-button {
        & i {
          color: $hover-color;
        }
      }
    }
  }
  &.for-composite {
    flex-direction: column;
    align-items: unset;
    & .composite-device {
      padding-bottom: 0.5rem;
      & .composite-device-children {
        padding: 0.25rem 6rem;
        @media screen and (max-width: $breakpoint_mobile){
          padding: 0.25rem 5rem;
        }
        & .children-power {
          flex-grow: 0;
          & .badge {
            padding: 0.3rem 0.75rem;
          }
        }
      }
    }
  }
  & .function-container {
    flex-grow: 1;
    align-items: center;
    padding: 1.157rem 15px;
    color: $font;
    & .device-info {
      flex-grow: 1;
      & .device-function {
        padding-bottom: 0.25rem;
        font-size: 1rem;
      }
      & .device-icon {
        flex-grow: 0;
        padding-right: 0;
      }
      & .device-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: Quicksand, serif;
        & .device-type {
          padding-bottom: 0.25rem;
          font-weight: 600;
          font-size: 0.86rem;
        }
        & .device-room-name, & .device-mac {
          font-size: 0.75rem;
        }
      }
    }
    & .device-power {
      display: flex;
      align-items: center;
      flex-grow: 0;
      padding: 0 0.5rem;
      & .badge {
        padding: 0.3rem 0.75rem;
      }
      & .notification-icon {
        margin-right: 0.65rem !important;
        & .fa-exclamation-circle {
          color: $warning;
          font-size: 1.225rem;
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
    & .device-button {
      flex-grow: 0;
      padding: 0 0.5rem;
      & a {
        text-decoration: none !important;
      }
      & i {
        display: flex;
        line-height: unset;
        color: $primary;
        font-size: 2.5rem;
      }
    }
    & .green-play {
      font-family: Quicksand, serif;
      font-size: 0.7rem;
      width: max-content;
      color: $correct;
      font-weight: bold;
    }
  }
}
 .device-function-edit {
   padding-top: 1rem;
   padding-bottom: 2rem;
   & .splitable-device-functions {
     padding: 1rem 0;
     & .device-phase-function {
       padding: 1rem 0;
       &:first-child {
         padding-top: 0;
       }
       & .device-phase-name {
         display: flex;
         align-items: center;
         padding: 0.5rem 0;
         & .badge {
           flex-grow: 0;
           padding: 0.3rem 0.75rem;
           font-family: "Lato", sans-serif;
         }
       }
     }
   }
   & .splitable-device-divider {
     display: flex;
     justify-content: center;
     align-items: center;
     button {
       margin: 1rem 0.25rem;
     }
   }
   & .greenplay {
     display: flex;
     flex-direction: column;
     padding: 0;
     padding-top: 2rem;
   }
   & .cancel-button {
     margin-right: 1rem;
   }
 }

@media (max-width: $breakpoint) {
  .registration-function-item {
    &.for-composite {
      & .composite-device {
        & .composite-device-children {
          padding: 0.25rem 3rem;
        }
      }
    }
  }
}

