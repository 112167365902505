@import "../../assets/styles/_measures.scss";

#customersMap {
  position: absolute;
  width: 100%;
  height: calc(100vh - #{$customers-header-height} - #{$main-menu-content-header-height-mobile});
  z-index: 3;
}

@media (min-width: $breakpoint_safe_min) {
  #customersMap {
    width: 70%;
    height: calc(100vh - #{$customers-header-height} - #{$main-menu-content-header-height});
    display: block;
  }
}

path.leaflet-interactive {
  opacity: 0;
}
