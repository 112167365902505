@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

$padding: 16.5px;
$header-margin-left: 20px;
$header-nav-height: 45px;
$header-content-height: 100px;
$header-total-height: 88px;
.menu {
  .menu-navigation {
    background-color: $menu-color;
    .menu-item {
      border-top: solid 2px #e7e7f2;
      color: $secondary-font;
      display: block;
      padding: $padding;
      text-decoration: none;
    }
  }

  .menu-header {
    .pageName {
      font-weight: bold;
      font-size: 15.5px;
    }
    background-color: $main-menu-background;
    box-shadow: 0 2px 5px 0 rgba(221, 221, 225, 1);
    > div {
      margin-left: $header-margin-left;
    }
    .nav {
      margin-bottom: $header-nav-height;
      text-align: center;
      a {
        left: 0;
        position: absolute;

        display: inline-block;
        padding: $padding;
        color: $primary;
        font-weight: bold;
        &:hover {
          color: black;
          text-decoration: none;
          border-bottom: none;
        }
      }
      label {
        margin: 0;
        position: absolute;
        top: $padding;
      }
    }
    position: fixed;

    top: 60px;
    width: 100%;
    z-index: 3;
  }

  .content-wrapper {
    background: white;
  }
  display: flex;
  flex-flow: column;
  .header-content-scrollable {
    margin-top: $header-total-height;
    background-color: $menu-color;
    box-shadow: 0 1px 5px 0 rgba(244, 244, 250, 1);
    //line-height: 30px;
    padding: 10px 22px;
  }

  .sub-menu-content {
    background-color: white;
    position: relative;
    &.hidden {
      display: none;
    }
  }

  // for mobile
  @media (max-width: $breakpoint) {
    .menu-navigation {
      top: 70px;
      position: relative;
    }
  }
  @media (min-width: $breakpoint_safe_min) {
    .menu-navigation a:hover {
      text-decoration: none;
      color: $hover-color;
    }
    .sub-menu-content {
      background-color: white;
      position: relative;
      &.hidden {
        display: block;
      }
    }
    .menu-navigation a:hover {
      text-decoration: none;
      color: $hover-color;
    }

    // we always display the menu
    .menu-navigation.subMenu {
      display: block;
    }

    // no more header since is a Tab style navigation
    .menu-header {
      display: none;
    }
    // the menu is on top
    .menu-content {
      order: 2;
      z-index: 3;
      height: 100%;
      & .content-wrapper {
        & .spinner:first-child {
          &.loading {
            height: calc(100vh - #{$headerHeight} - #{$main-menu-content-header-height} - 57px);
          }
        }
      }
    }

    .menu-navigation {
      order: 1;
      padding-left: calc(#{$padding-rem} - 1rem);

      // tab style menu items
      .menu-item {
        border-top: none;
        display: inline-block;
        padding: 14px;

        &:hover {
          text-decoration: none;
          color: $hover-color;
          border-bottom: 5px solid $hover-color;
        }
        .right-arrow {
          display: none;
        }
      }

      // the current page tab
      a.active {
        color: black;
        font-weight: bold;
        border-bottom: 5px solid $primary;
        display: inline-block;
      }
      a:hover {
        text-decoration: none;
        color: $hover-color;
        border-bottom: 5px solid $hover-color;
      }
    }
    .header-content-scrollable {
      display: none;
    }
  }

  // icons before we have font icons ...

  .right-arrow {
    border: solid $primary;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    transition-duration: 0.5s;
    transform: rotate(-45deg);
  }
  .down-arrow {
    border: solid $primary;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    transition-duration: 0.5s;
    transform: rotate(45deg);
  }

  .left-arrow {
    border: solid $primary;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-left: 16px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
