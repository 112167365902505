@import "../../../assets/styles/_measures.scss";

@media (min-width: $breakpoint_safe_min) {
  .indicators-container {
    width: 30%;
    float: left;
  }
  .map-container {
    margin-top: -2px;
    width: 70%;
    float: right;
  }
}

@media (max-width: $breakpoint) {
  .indicators-container {
    display: none;
  }
  .map-container {
    width: 100%;
  }
}
