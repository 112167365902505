@import "../../assets/styles/_measures.scss";

.container__tools-pairing {
  width: 500px;
  max-width: 500px;
}

@media screen and (max-width: $breakpoint_large) {
  .container__tools-pairing {
    width: 100%;
  }
}