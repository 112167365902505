@import "../../assets/styles/_colors.scss";

.tooltip {
  .tooltip-inner {
    background-color: $input-background;
    color: $font;
    max-width: 350px;
    padding: 0.5rem 1rem;
    box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, .1)
  }
  .arrow::before {
    border-bottom-color: #f4f4fa !important;
    border-top-color: #f4f4fa !important;
  }
}
.btn.btn-tooltip {
  border: solid 1px $font;
  border-radius: 50px;
  background-color: transparent;
  padding: 5px;
  line-height: 4px;
  vertical-align: 1px;
  color: $font;
  margin-left: 10px;
  &:hover {
    background-color: $hover-color;
    border-color: $hover-color;
    color: $background;
  }
}
