@import "../../assets/styles/_colors.scss";

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.background::before {
  content: "";
  width: 100%;
  position: fixed;
  background-color: $primary;
  height: 100%;
  z-index: -1;
}
