@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

.snackBar {
  position: fixed;
  bottom: 3px;
  right: 10%;
  left: 10%;
  color: white;
  background-color: #2e2e2e;
  padding: 15px 15px 15px;
  z-index: 2000;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  transform: translate(0, 200px);
  @media (min-width: $breakpoint_safe_min) {
    margin: auto;
    right: 20%;
    left: 20%;
  }
}

.closeButton {
  right: 15px;
  top: 6px;
  font-size: 26px;
  float: right;
  cursor: pointer;
}

.visible {
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  transform: translate(0, 0);
}

.error {
   background-color: $danger;
}

.success {
  background-color: $correct;
}
