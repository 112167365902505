@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_measures.scss";

.partnerships-list {
  & .custom-list-item {
    padding: 0.6rem;
    & .arrow-icon {
      justify-content: flex-end;
      & i:hover {
        color: $hover-color;
        cursor: pointer;
      }
      & .icon-trash-active {
        font-size: 1.75rem;
        padding-right: 0.25rem;
        color: $primary;
        align-self: center;
        cursor: pointer;
        &:hover {
          color: $danger
        }
      }
    }
    & .partnership-name {
      color: #2e2e2e;
      padding-bottom: 0.25rem;
      font-weight: 600;
      font-family: Quicksand, serif;
      font-size: 0.86rem;
    }
    & .badge {
      font-family: Quicksand, serif;
      font-size: 0.6rem;
      padding: 0.35rem 0.75rem;
    }
  }
  & .validate-button {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
  }
}

.partnership {
  & .partnership-header {
    display: flex;
    height: 5.75rem;
    background-color: $menu-color;
    & .arrow-back {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      & i {
        transform: rotate(-135deg) scale(0.8);
        cursor: pointer;
      }
    }
    & .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        & .header-title {
          font-weight: bold;
          font-family: Quicksand, serif;
          font-size: 1.7rem;
        }
    }
    & .header-badge {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      & .badge {
        font-family: Quicksand, serif;
        font-size: 0.6rem;
        padding: 0.35rem 0.75rem;
      }
    }
  }
  & .partnership-content {
    justify-content: center;
    height: calc(100vh - 5.75rem);
    overflow: auto;
    & .spinner {
      flex-grow: 1;
      height: 100%;
      &::after {
        background-color: transparent;
      }
    }
    & .list-wrapper {
      margin: 0;
      height: 100%;
    }
    & .table-row {
      height: auto;
      border-bottom: 1px solid #f4f4fa;
      border-top: 1px solid #f4f4fa;
    }
    & .tado-container {
      height: 100%;
      border-bottom: 0;
      & .tado-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        & .tado-title {
          font-family: Quicksand, serif;
          font-size: 1.15rem;
          padding-bottom: 1rem;
          font-weight: normal;
          text-align: center;
          width: 15rem;
        }
        & .tado-auth-loading {
          & .spinner {
            margin-bottom: 1rem;
          }
        }
        & .tado-button {
          padding: 0.35rem;
          & .outline {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
            &:hover {
              background-color: transparent;
              color: $hover-color;
              border-color: $hover-color;
            }
          }
        }
        & .tado-home-select{
          padding-bottom: 0.5rem;
          & .input-field {
            max-width: 18rem;
            min-width: 14rem;
            & .custom-select-input {
              bottom: auto
            }
          }
        }
        & .device-container {
          &.saved {
            display: flex;
            padding: 1.2rem 0;          }
          & .device-info, & .room-info {
            padding: 0.25rem 0;
          }
          & .device-info {
            flex-grow: 1;
            &.ignored {
              background-color: #9c9ca00f;
              align-items: center;
              & .ignored-device-label {
                font-family: Quicksand, serif;
                font-size: 0.8rem;
                text-transform: uppercase;
                color: $secondary-font
              }
            }
            .icon-cross, .icon-add {
              align-self: center;
              height: 100%;
              display: flex;
              font-size: 1.5rem;
              color: $primary;
              cursor: pointer;
              padding: 0 1rem;
              &:hover {
                color: $hover-color;
              }
            }
            & .device-title {
              display: flex;
              flex-direction: column;
              justify-content: center;
              & .device-name-container {
                display: flex;
                align-items: center;
                & .device-name {
                  font-size: 0.85rem;
                }
                & .device-edit {
                  margin: 0 0.5rem;
                  background-color: transparent;
                  border: 0;
                  transform: rotateY(180deg);
                  padding: 0 0.1rem;
                  font-size: 0.5rem;
                  &:hover{
                    background-color: $hover-color;
                    color: $background;
                    & svg {
                      fill: $background;
                    }
                  }
                  & svg {
                    height: 22px;
                    width: 20px;
                    padding: 3px;
                    fill: $primary;
                  }
                }
                & .input-field {
                  margin: 0 !important;
                }
                label {
                  display: none;
                }
              }
              & .device-type {
                font-size: 0.75rem;
              }
            }
          }

          & .room-info {
            & .room-control{
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              width: 100%;
              & .input-field {
                width: 48%;
              }
            }
            & .room-info-title {
              color: #afafb7;
              font-weight: normal;
              margin: 0.1rem 0.5rem;
            }
            & .room-info-content {
              margin: 0.1rem 0;
              font-weight: normal;
            }
          }

          & .device-buttons {
            display: flex;
            align-items: center;
            flex-grow: 0;
            & .device-delete {
              font-size: 2rem;
              color: $primary;
              align-self: center;
              cursor: pointer;
              &:hover {
                color: $danger
              }
            }
          }
        }

        & .devices-title {
          justify-content: center;
          border: 0;
          & .tado-title {
            padding: 2rem 0;
          }
        }
        & .validate-devices {
          display: flex;
          justify-content: center;
          border: 0;
          flex-wrap: wrap;
          button {
            padding: 0.4rem 2.25rem;
            margin-top: 1rem;
            margin-right: 0.75rem;
          }
        }
        & button.disconnect {
          font-weight: bold;
          font-size: 0.75rem;
          padding: 0.375rem 0.75rem;
        }
      }
    }
  }
  & .enphase-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    & .form-wrapper {
      width: 100%;
      & .form-buttons {
        padding: 1rem 0.5rem 0rem 0.5rem;
      } & .form-buttons button {
          font-size: 0.75rem;
          width: 8.5rem;
        }
        & .form-buttons .update-email {
          width: auto;
        }
      & .input-top-interval {
        margin-top: 0.75rem;
      }
    }

    & .envoy-container, .email-container {
      display: flex;
      flex-direction: column;
      border: 0;
      padding: 1.5rem 0;
    }

    & .email-title, .envoy-title {
      font-family: Quicksand, serif;
      font-size: 1.15rem;
      padding-bottom: 1rem;
      padding-top: 0.3rem;
      border-bottom: 2px solid $border-color;
      font-weight: normal;
    }

    & .email, .email-warning {
      color: $neutral;
      font-size: 0.9rem;
      padding-left: 15px;
      margin: 0.5rem;
      font-weight: normal;
    }

    & .email-warning {
      font-size: 0.8rem;
      padding-left: 0;
      margin-bottom: 0;
    }

    & .email-buttons, .envoy-buttons {
      display: flex;
      justify-content: center;
      & button {
        padding: 1rem 3.5rem;
        margin: 0.5rem;
        border-radius: 10px;
        color: $primary !important;
        &.active {
          background-color: $primary !important;
          color: $secondary-btn !important;
        }
      }
    }

    & .envoy-buttons {
      margin-top: 0.75rem;
    }
  }
  & .header-error-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-end;
    padding-right: 15px;
    & .header-error {
      color: $danger;
      font-size: 0.85rem;
      font-family: Quicksand, serif;
      text-align: end;
      font-weight: bold;
      &.warning {
        color: $warning;
      }
    }
    &.bottom {
      justify-content: center;
      align-items: flex-start;
      margin: 0.75rem 0.5rem 0rem 0.5rem;
      height: 1rem;
    }
  }
  & .enphase-disconnect-container {
    padding: 1rem 0;
    & .enphase-disconnect-button {
      display: flex;
      justify-content: center;
      & button {
        font-weight: bold;
        font-size: 0.75rem;
        padding: 0.375rem 0.75rem;
        margin-top: 1.5rem;
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .partnership {
    & .partnership-header {
      height: 4rem;
      & .arrow-back {
        padding: 0 1rem;
      }
      & .header-content {
        & .header-title {
          font-size: 1.2rem;
        }
        & .header-subtitle {
          font-size: 0.75rem;
          .badge {
            font-size: 0.52rem;
          }
        }
      }
    }
    & .partnership-content {
        height: calc(100vh - 4rem);
      & .tado-container {
        & .tado-content {
          & .tado-home-select{
            justify-content: center;
            & .input-field {
              width: 75% !important;
            }
          }
        }
      }
    }
  }
}
