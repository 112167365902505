@import "../../assets/styles/_colors.scss";

.icon-spinner {
  fill: $primary;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
