@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Quicksand:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Quicksand:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Quicksand:400,700&display=swap);
@font-face {
  font-family: "icons";
  src: url(/static/media/icons.60e1382c.eot?#iefix) format("embedded-opentype"), url(/static/media/icons.58909f63.woff2) format("woff2"), url(/static/media/icons.58fed542.woff) format("woff"), url(/static/media/icons.8d32b538.ttf) format("truetype"), url(/static/media/icons.2fa10a82.svg#icons) format("svg"); }

i {
  line-height: 1; }

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "\F101"; }

.icon-arrow:before {
  content: "\F102"; }

.icon-calendar-active:before {
  content: "\F103"; }

.icon-calendar:before {
  content: "\F104"; }

.icon-call-active:before {
  content: "\F105"; }

.icon-call:before {
  content: "\F106"; }

.icon-client-active:before {
  content: "\F107"; }

.icon-client:before {
  content: "\F108"; }

.icon-company-active:before {
  content: "\F109"; }

.icon-company:before {
  content: "\F10A"; }

.icon-cross:before {
  content: "\F10B"; }

.icon-dashboard-active:before {
  content: "\F10C"; }

.icon-dashboard:before {
  content: "\F10D"; }

.icon-list:before {
  content: "\F10E"; }

.icon-map-active:before {
  content: "\F10F"; }

.icon-map:before {
  content: "\F110"; }

.icon-menu:before {
  content: "\F111"; }

.icon-not-active:before {
  content: "\F112"; }

.icon-not:before {
  content: "\F113"; }

.icon-notification-active:before {
  content: "\F114"; }

.icon-notification:before {
  content: "\F115"; }

.icon-ok-active:before {
  content: "\F116"; }

.icon-ok:before {
  content: "\F117"; }

.icon-profile-active:before {
  content: "\F118"; }

.icon-profile:before {
  content: "\F119"; }

.icon-search-active:before {
  content: "\F11A"; }

.icon-search:before {
  content: "\F11B"; }

.icon-support-active:before {
  content: "\F11C"; }

.icon-support:before {
  content: "\F11D"; }

.icon-tool-active:before {
  content: "\F11E"; }

.icon-tool:before {
  content: "\F11F"; }

.icon-trash-active:before {
  content: "\F120"; }

.icon-trash:before {
  content: "\F121"; }

.icon-zoom:before {
  content: "\F122"; }

body {
  background: transparent !important; }

.alert {
  font-family: "Lato", sans-serif; }

.table-responsive thead tr th {
  vertical-align: middle; }

.form-control {
  border-radius: 5px;
  border: solid 1px #f4f4fa;
  background-color: #f4f4fa; }
  .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #f4f4fa inset; }
  .form-control:hover:not([disabled]), .form-control:focus:not([disabled]) {
    cursor: pointer;
    border: solid 1px #f5b717;
    box-shadow: none; }
  .form-control.is-invalid:focus {
    box-shadow: none; }

span.input-group-text {
  background: #f4f4fa; }

.form-control[disabled] {
  background-color: #ffffff;
  border: solid 2px #f4f4fa; }

.btn {
  white-space: normal;
  text-transform: uppercase; }
  .btn i {
    vertical-align: -1px; }
  .btn.btn-primary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: #ffffff;
    background-color: #009dbf;
    border: solid 2px transparent; }
    .btn.btn-primary:hover:not(:focus):not([disabled]) {
      background-color: #f5b717;
      border-color: transparent;
      cursor: pointer; }
    .btn.btn-primary:disabled {
      background-color: white;
      color: #d3d3dd;
      border: solid 2px #e7e7f2; }
    .btn.btn-primary:active:not([disabled]) {
      color: #ffffff !important;
      background-color: #f5b717 !important;
      border-color: #f5b717 !important;
      box-shadow: none !important; }
  .btn.btn-secondary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: #009dbf;
    background-color: #efeff5;
    border: none; }
    .btn.btn-secondary:hover:not([disabled]):not(:focus) {
      cursor: pointer;
      color: #ffffff;
      background-color: #f5b717; }
    .btn.btn-secondary:disabled {
      background-color: white;
      color: #d3d3dd;
      border: solid 2px #e7e7f2; }
    .btn.btn-secondary:active:not([disabled]) {
      color: #ffffff !important;
      background-color: #f5b717 !important;
      border-color: #f5b717 !important;
      box-shadow: none !important; }
  .btn:focus:not([disabled]) {
    box-shadow: none; }

.badge {
  padding: 5px 8px;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  font-size: 10px; }
  .badge label {
    vertical-align: -1px;
    padding: 0;
    margin: 0; }

.badge-success {
  background-color: #7ed321; }

.badge-error {
  background-color: #ff525a; }

.badge-secondary {
  background-color: #9b9b9b; }

.badge-error {
  background-color: #ff525a; }

.right {
  text-align: right; }

.btn.add, .btn.remove {
  line-height: 16px;
  padding: 6px;
  min-width: 10rem;
  display: flex;
  justify-content: space-around; }
  .btn.add:before, .btn.remove:before {
    content: "\F101";
    font-family: icons !important;
    font-size: 21px;
    vertical-align: -1px;
    font-weight: normal; }

.btn.remove:before {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg); }

.btn-ico {
  padding: 3px 5px; }
  .btn-ico i {
    font-size: 20px; }

.text-primary {
  color: #009dbf !important; }

.text-danger {
  color: #ff525a !important; }

.text-correct {
  color: #7ed321 !important; }

.text-warning {
  color: #ffc107 !important; }

.invalid-feedback {
  font-size: 100%; }

html[lang=fr] .custom-file-label::after {
  content: 'Parcourir'; }

html[lang=fr-FR] .custom-file-label::after {
  content: 'Parcourir'; }

html[lang=en] .custom-file-label::after {
  content: 'Browse'; }

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%; }

html, body, #root {
  height: 100%;
  overflow-x: hidden; }

html {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

a.btn.btn-primary {
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  font-weight: 900;
  text-align: center;
  color: #ffffff;
  background-color: #009dbf;
  border: none; }
  a.btn.btn-primary:hover:not([disabled]) {
    background-color: #f5b717;
    cursor: pointer; }
  a.btn.btn-primary:disabled {
    background-color: white;
    color: #d3d3dd;
    border: solid 2px #e7e7f2; }

a.btn.btn-secondary {
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  font-weight: 900;
  text-align: center;
  color: #009dbf;
  background-color: #efeff5;
  border: none; }
  a.btn.btn-secondary:hover:not([disabled]) {
    cursor: pointer;
    color: #f5b717; }
  a.btn.btn-secondary:disabled {
    background-color: white;
    color: #d3d3dd;
    border: solid 2px #e7e7f2; }

input::-webkit-input-placeholder {
  opacity: 0.5 !important; }

input::-moz-placeholder {
  opacity: 0.5 !important; }

input::-ms-input-placeholder {
  opacity: 0.5 !important; }

input::placeholder {
  opacity: 0.5 !important; }

.icon-sort {
  width: 13px; }
  .icon-sort:before {
    border: 4px solid transparent;
    border-bottom-color: #009dbf;
    margin-top: -9px;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0; }
  .icon-sort:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    border-top-color: #009dbf;
    margin-top: 1px; }

.icon-sort.up:before {
  opacity: 0.3; }

.icon-sort.down:after {
  opacity: 0.3; }

@media (min-width: 769px) {
  body {
    overflow-y: hidden; } }

.form-wrapper {
  padding: 15px 10px 15px 10px; }

.form-container {
  background: white;
  padding-top: 30px;
  padding-bottom: 30px; }

.icon-sort {
  width: 15px; }
  .icon-sort:before {
    border: 4px solid transparent;
    border-bottom-color: #009dbf;
    margin-top: -9px;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    opacity: 0.3; }
  .icon-sort:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    border-top-color: #009dbf;
    margin-top: 1px;
    opacity: 0.3; }

.icon-sort.up.before, .icon-sort.up.after {
  border-top-color: #009dbf; }

.icon-sort.up:before {
  opacity: 1; }

.icon-sort.down.before, .icon-sort.down.after {
  border-top-color: #009dbf; }

.icon-sort.down:after {
  opacity: 1; }

.icon-full-arrow-right {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #009dbf;
  box-sizing: border-box;
  width: 18px;
  height: 20px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  margin: 10px;
  -webkit-transform: rotate(45deg) scale(0.6);
          transform: rotate(45deg) scale(0.6);
  border-radius: 0 2px 0 0; }
  .icon-full-arrow-right:before, .icon-full-arrow-right:after {
    border-radius: 2px;
    position: absolute;
    content: "";
    box-sizing: border-box; }
  .icon-full-arrow-right:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    box-shadow: inset 0 0 0 32px; }
  .icon-full-arrow-right:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px; }

@media (max-width: 768px) {
  .mobile-helper {
    padding: 0; } }

.text-warning {
  color: #ffc107; }

.text-success {
  color: #7ed321; }

.text-primary {
  color: #009dbf !important; }

.login-box {
  background-color: #ffffff;
  width: 100%;
  max-width: 500px;
  max-height: 600px;
  box-shadow: 2px 0 52.4px 15.6px rgba(3, 0, 69, 0.1); }
  .login-box .container {
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column; }
    .login-box .container .logo-container {
      display: flex;
      justify-content: center;
      align-items: center; }
      .login-box .container .logo-container.logo-mylight {
        height: 10rem; }
      .login-box .container .logo-container .logo-wrapper {
        display: block;
        max-width: 230px; }
    .login-box .container .login-box-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1; }
      .login-box .container .login-box-content .password-field {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .login-box .container .login-box-content .password-field .form-group {
          margin: 0;
          flex-grow: 1; }
        .login-box .container .login-box-content .password-field button {
          height: 36px;
          border-radius: 5px;
          border: solid 1px transparent;
          margin-left: 1rem;
          margin-top: 13px; }

@media screen and (max-width: 768px) {
  .login-box {
    width: calc(100% - 40px); } }

.input-field {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px; }
  .input-field.with-error.with-unit .input-group {
    border: 1px solid #dc3545;
    border-radius: 5px; }
  .input-field.with-error .input-number.with-error {
    border: 1px solid #dc3545;
    border-radius: 5px; }
  .input-field.with-error .input-number.with-unit {
    border: unset; }
  .input-field.with-error .custom-error {
    margin: 0.25rem 0 0 0.5rem;
    color: #dc3545; }
  .input-field .helper-text {
    margin: 0.25rem 0 0 0.5rem;
    color: #ffc107; }
  .input-field .input-group input.is-invalid.without-error-icon {
    background-image: none; }
  .input-field .input-group > label.error-message {
    color: #ff525a;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: bold;
    padding-top: 5px; }
  .input-field > label {
    margin-left: 10px; }
  .input-field > label.input-title {
    color: #afafb7;
    cursor: pointer;
    width: 100%; }
  .input-field > label.error-message {
    color: #ff525a;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: bold;
    padding-top: 5px; }
  .input-field .clearable:after {
    content: " x ";
    position: absolute;
    right: 20px;
    cursor: pointer; }
  .input-field:not(:first-child) {
    padding-top: 10px; }

.btn-loader.loading:disabled::after {
  border-color: #d3d3dd transparent transparent transparent; }

.btn-loader.loading::after {
  content: " ";
  -webkit-transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 3px solid;
  border-radius: 50%;
  position: absolute;
  margin-left: 10px;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  -webkit-animation: lds-ring 1.2s infinite;
          animation: lds-ring 1.2s infinite; }

.btn-secondary.btn-loader::after {
  border-color: #009dbf; }

.btn-secondary.btn-loader::after {
  border-color: #009dbf transparent transparent transparent; }

.btn-loader.done::after {
  content: "";
  border-right: 2px solid;
  border-top: 2px solid;
  display: inline-block;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  width: 8px;
  height: 16px;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-name: checkmark;
          animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
          transform: scaleX(-1) rotate(135deg);
  position: absolute;
  margin-top: 9px;
  margin-left: 11px; }

.btn-loader.loading,
.btn-loader.done {
  padding-right: 35px;
  -webkit-transition: 0.5s padding-right ease-out;
  transition: 0.5s padding-right ease-out; }

.btn-loader {
  padding-right: 15px;
  -webkit-transition: 0.5s padding-right ease-out;
  transition: 0.5s padding-right ease-out; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  50% {
    height: 0;
    width: 8px;
    opacity: 1; }
  100% {
    height: 16px;
    width: 8px;
    opacity: 1; } }

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }
  50% {
    height: 0;
    width: 8px;
    opacity: 1; }
  100% {
    height: 16px;
    width: 8px;
    opacity: 1; } }

.login-action-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 4rem; }

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1.5rem; }
  .links-container a {
    display: block;
    color: #009dbf;
    margin-bottom: 0.5rem; }
    .links-container a:hover {
      color: #f5b717; }

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.background::before {
  content: "";
  width: 100%;
  position: fixed;
  background-color: #009dbf;
  height: 100%;
  z-index: -1; }

.customers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #f4f4fa;
  width: 100%;
  padding: 0 1rem; }
  .customers-header .customers-header-buttons {
    display: flex;
    align-items: center; }
    .customers-header .customers-header-buttons .add-customer {
      margin-left: 1rem; }
  .customers-header .btn {
    line-height: 1rem; }
  .customers-header .toggle.btn {
    border: none;
    background-color: white;
    color: #009dbf;
    width: 38px;
    padding: 2px 5px 0;
    font-size: 24px;
    margin: 0; }
  .customers-header .btn.right {
    float: right; }
  .customers-header .btn.search {
    background-color: white;
    color: #009dbf;
    margin-right: 7.5px; }
  .customers-header .toggle.selected.btn {
    border: none;
    color: white;
    background-color: #009dbf; }

@media (max-width: 768px) {
  .add-customer {
    display: none; }
  .title {
    display: none; } }

@media (min-width: 769px) {
  .customers-header {
    padding: 0 3rem; }
  .title {
    display: inline;
    padding-right: 10px;
    vertical-align: -3px;
    font-family: Quicksand, sans-serif;
    font-size: 27px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal; }
  .btn.add span {
    display: inline;
    width: 90px;
    height: 11px;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    line-height: -1px;
    vertical-align: 3px;
    padding-left: 5px;
    padding-right: 5px; } }

.search-input {
  background-color: white;
  color: #009dbf;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0; }
  .search-input input {
    background-color: transparent;
    border: none;
    color: #009dbf;
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    width: 0;
    margin-left: 0;
    margin-top: 1px; }
    .search-input input:focus {
      border: none;
      outline: none !important; }
  .search-input i {
    cursor: pointer;
    height: 100%;
    padding: 0.3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px; }
  .search-input:hover {
    color: #ffffff;
    background-color: #f5b717; }

.search-input.expended input {
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  width: 100px;
  margin-left: 10px; }

.search-input.expended:hover {
  color: #009dbf;
  background-color: #ffffff; }

.search-input.expended i:hover {
  color: #f5b717; }

.create-customer-dropdown {
  width: 150px; }
  .create-customer-dropdown .btn.dropdown-toggle {
    background-color: #009dbf;
    color: white;
    width: 100%;
    box-shadow: none; }
    .create-customer-dropdown .btn.dropdown-toggle:hover:not(disabled) {
      color: white; }
    .create-customer-dropdown .btn.dropdown-toggle:active {
      background-color: #009dbf; }
  .create-customer-dropdown .dropdown-menu {
    min-width: 0;
    width: 100%; }
    .create-customer-dropdown .dropdown-menu .create-customer-item {
      cursor: pointer;
      padding: 3px 5px; }
      .create-customer-dropdown .dropdown-menu .create-customer-item .tenant-logo {
        margin-right: 5px; }
      .create-customer-dropdown .dropdown-menu .create-customer-item i {
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px; }
      .create-customer-dropdown .dropdown-menu .create-customer-item:hover {
        color: #009dbf; }

.show > .btn-secondary.dropdown-toggle {
  background-color: #009dbf;
  color: white; }

.create-customer-button {
  width: 150px; }

.menu {
  display: flex;
  flex-flow: column; }
  .menu .menu-navigation {
    background-color: #f4f4fa; }
    .menu .menu-navigation .menu-item {
      border-top: solid 2px #e7e7f2;
      color: #afafb7;
      display: block;
      padding: 16.5px;
      text-decoration: none; }
  .menu .menu-header {
    background-color: #e7e7f2;
    box-shadow: 0 2px 5px 0 #dddde1;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 3; }
    .menu .menu-header .pageName {
      font-weight: bold;
      font-size: 15.5px; }
    .menu .menu-header > div {
      margin-left: 20px; }
    .menu .menu-header .nav {
      margin-bottom: 45px;
      text-align: center; }
      .menu .menu-header .nav a {
        left: 0;
        position: absolute;
        display: inline-block;
        padding: 16.5px;
        color: #009dbf;
        font-weight: bold; }
        .menu .menu-header .nav a:hover {
          color: black;
          text-decoration: none;
          border-bottom: none; }
      .menu .menu-header .nav label {
        margin: 0;
        position: absolute;
        top: 16.5px; }
  .menu .content-wrapper {
    background: white; }
  .menu .header-content-scrollable {
    margin-top: 88px;
    background-color: #f4f4fa;
    box-shadow: 0 1px 5px 0 #f4f4fa;
    padding: 10px 22px; }
  .menu .sub-menu-content {
    background-color: white;
    position: relative; }
    .menu .sub-menu-content.hidden {
      display: none; }
  @media (max-width: 768px) {
    .menu .menu-navigation {
      top: 70px;
      position: relative; } }
  @media (min-width: 769px) {
    .menu .menu-navigation a:hover {
      text-decoration: none;
      color: #f5b717; }
    .menu .sub-menu-content {
      background-color: white;
      position: relative; }
      .menu .sub-menu-content.hidden {
        display: block; }
    .menu .menu-navigation a:hover {
      text-decoration: none;
      color: #f5b717; }
    .menu .menu-navigation.subMenu {
      display: block; }
    .menu .menu-header {
      display: none; }
    .menu .menu-content {
      order: 2;
      z-index: 3;
      height: 100%; }
      .menu .menu-content .content-wrapper .spinner:first-child.loading {
        height: calc(100vh - 90px - 75px - 57px); }
    .menu .menu-navigation {
      order: 1;
      padding-left: calc(3rem - 1rem); }
      .menu .menu-navigation .menu-item {
        border-top: none;
        display: inline-block;
        padding: 14px; }
        .menu .menu-navigation .menu-item:hover {
          text-decoration: none;
          color: #f5b717;
          border-bottom: 5px solid #f5b717; }
        .menu .menu-navigation .menu-item .right-arrow {
          display: none; }
      .menu .menu-navigation a.active {
        color: black;
        font-weight: bold;
        border-bottom: 5px solid #009dbf;
        display: inline-block; }
      .menu .menu-navigation a:hover {
        text-decoration: none;
        color: #f5b717;
        border-bottom: 5px solid #f5b717; }
    .menu .header-content-scrollable {
      display: none; } }
  .menu .right-arrow {
    border: solid #009dbf;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .menu .down-arrow {
    border: solid #009dbf;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .menu .left-arrow {
    border: solid #009dbf;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-left: 16px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

.customer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 3rem;
  background-color: #f4f4fa;
  border-bottom: 1px solid white;
  grid-gap: 15px;
  gap: 15px; }
  .customer-header-logo {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1; }
    .customer-header-logo img {
      height: 40px;
      margin: 0; }

.tooltip .tooltip-inner {
  background-color: #f4f4fa;
  color: #2e2e2e;
  max-width: 350px;
  padding: 0.5rem 1rem;
  box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, 0.1); }

.tooltip .arrow::before {
  border-bottom-color: #f4f4fa !important;
  border-top-color: #f4f4fa !important; }

.btn.btn-tooltip {
  border: solid 1px #2e2e2e;
  border-radius: 50px;
  background-color: transparent;
  padding: 5px;
  line-height: 4px;
  vertical-align: 1px;
  color: #2e2e2e;
  margin-left: 10px; }
  .btn.btn-tooltip:hover {
    background-color: #f5b717;
    border-color: #f5b717;
    color: #ffffff; }

.spinner {
  height: 100%; }

.spinner.loading::after,
.spinner.error::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0; }

.spinner.loading.transparent::after,
.spinner.error.transparent::after {
  background-color: transparent; }

.spinner.loading::before {
  content: " ";
  -webkit-transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  margin: -25px auto auto -25px;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: #009dbf transparent transparent transparent;
  -webkit-animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; }

.spinner.loading,
.spinner.error {
  min-height: 50px;
  position: relative; }
  .spinner.loading :first-child,
  .spinner.error :first-child {
    opacity: 0; }

.spinner.error label {
  position: absolute;
  top: 15px;
  text-align: center;
  width: 100%;
  font-size: 20px; }

@-webkit-keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@media screen and (max-width: 576px) {
  .loginAs-link {
    display: none; } }

.badge-status {
  font-family: Lato, sans-serif;
  font-weight: bold; }
  .badge-status label {
    margin-bottom: 0;
    color: #9b9b9b; }
  .badge-status.success label {
    color: #7ed321; }
  .badge-status.warning label {
    color: #ffc107; }

.is-system-offline-indicator {
  font-family: Lato, sans-serif;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 3rem;
  background-color: #ff525a;
  color: white;
  font-size: 14px; }

.fixed-header {
  height: 70px; }
  .fixed-header .status {
    line-height: 70px;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .fixed-header .name {
    font-weight: 600;
    font-size: 20px; }
  .fixed-header .tenant-info {
    position: absolute;
    right: 20px;
    border-radius: 6px; }
  .fixed-header .logo {
    padding: 6px;
    height: 32px;
    display: block;
    margin: auto; }
  .fixed-header .loginAs-link:before {
    content: " ";
    background-color: #e7e7f2;
    width: 2px;
    position: relative;
    top: 6px;
    height: 20px;
    display: inline-block;
    margin-right: 6px; }
  .fixed-header .loginAs-link {
    font-size: 14px;
    padding-right: 6px;
    color: #009dbf; }

.custom-responsive-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  color: #2e2e2e;
  font-family: Lato, sans-serif; }
  .custom-responsive-list .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    min-height: 3.5rem;
    font-size: 1rem;
    font-weight: 900;
    border-bottom: #f4f4fa solid 2px;
    margin-bottom: 0.75rem; }
  .custom-responsive-list .big-text {
    font-family: Lato, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal; }
  .custom-responsive-list .field {
    margin: 0.75rem 0; }
    .custom-responsive-list .field label {
      margin: 0; }
    .custom-responsive-list .field .name {
      line-height: 13px;
      font-size: 13px;
      color: #afafb7; }
    .custom-responsive-list .field .text,
    .custom-responsive-list .field .email,
    .custom-responsive-list .field .phone,
    .custom-responsive-list .field .address {
      font-weight: 600;
      line-height: 12px;
      font-size: 12px;
      font-family: Lato, sans-serif; }
    .custom-responsive-list .field .text > .badge {
      position: relative;
      bottom: 3px; }
    .custom-responsive-list .field .text > .text-warning {
      line-height: 1rem; }
    .custom-responsive-list .field .email,
    .custom-responsive-list .field .phone,
    .custom-responsive-list .field .address {
      text-decoration: none; }
      .custom-responsive-list .field .email a,
      .custom-responsive-list .field .phone a,
      .custom-responsive-list .field .address a {
        color: #2e2e2e; }
      .custom-responsive-list .field .email :hover:not(:focus),
      .custom-responsive-list .field .phone :hover:not(:focus),
      .custom-responsive-list .field .address :hover:not(:focus) {
        text-decoration: none;
        color: #f5b717; }
    .custom-responsive-list .field .address {
      line-height: 1rem; }
    .custom-responsive-list .field.field-with-error {
      margin: 1.5rem 0 !important; }
      .custom-responsive-list .field.field-with-error div {
        color: #2e2e2e;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 900;
        line-height: normal; }
      .custom-responsive-list .field.field-with-error .name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 3rem;
        margin: 0; }
      .custom-responsive-list .field.field-with-error .value {
        font-size: 22px; }
      .custom-responsive-list .field.field-with-error .field-error-icon {
        margin-left: 0.5rem; }

@media (max-width: 768px) {
  .custom-responsive-list .field .name {
    margin-bottom: 0.5rem; }
  .custom-responsive-list .field .address {
    line-height: 12px; } }

.custom-tooltip-container {
  opacity: 1; }
  .custom-tooltip-container.is-warning .tooltip .tooltip-inner {
    background-color: #ffc107;
    color: #2e2e2e; }
  .custom-tooltip-container .tooltip .tooltip-inner {
    padding: 21px 19px;
    background-color: #009dbf;
    color: white;
    max-width: 300px;
    box-shadow: 2px 0 52.4px 15.6px rgba(3, 0, 69, 0.1); }
  .custom-tooltip-container .tooltip.bs-tooltip-left .arrow::before {
    border-color: transparent;
    border-left-color: #009dbf; }
  .custom-tooltip-container .tooltip.bs-tooltip-right .arrow::before {
    border-color: transparent;
    border-right-color: #009dbf; }
  .custom-tooltip-container .tooltip.bs-tooltip-bottom .arrow::before {
    border-color: transparent;
    border-bottom-color: #009dbf; }
  .custom-tooltip-container .tooltip.bs-tooltip-top .arrow::before {
    border-color: transparent;
    border-top-color: #009dbf; }
  .custom-tooltip-container .tooltip .custom-tooltip-container-body {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .custom-tooltip-container .tooltip .custom-tooltip-container-body .custom-tooltip-complement-switch {
      font-weight: bold;
      margin-top: 10px;
      cursor: pointer; }
      .custom-tooltip-container .tooltip .custom-tooltip-container-body .custom-tooltip-complement-switch:hover {
        text-decoration: underline; }
    .custom-tooltip-container .tooltip .custom-tooltip-container-body .custom-tooltip-complement-msg {
      margin-top: 10px; }

#mapInput {
  z-index: 0;
  width: 100%;
  height: 300px; }

#customersMap {
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px - 60px);
  z-index: 3; }

@media (min-width: 769px) {
  #customersMap {
    width: 70%;
    height: calc(100vh - 60px - 75px);
    display: block; } }

path.leaflet-interactive {
  opacity: 0; }

.input-field.select-input.with-error.with-unit .input-group {
  border: 1px solid #dc3545;
  border-radius: 5px; }

.input-field.select-input.with-error .custom-select-input__control {
  border: 1px solid #dc3545 !important; }

.input-field.select-input.with-error .custom-select-input__dropdown-indicator svg {
  color: #dc3545 !important; }

.input-field.select-input.with-error .custom-error {
  margin: 0.25rem 0 0 0.5rem;
  color: #dc3545; }

.input-field.select-input.with-error .input-group-text {
  border: none;
  border-left: 1px solid #ced4da; }

.input-field.select-input .input-group-append {
  z-index: 1; }
  .input-field.select-input .input-group-append .input-group-text {
    border-radius: 0px 5px 5px 0px; }

.input-field.select-input .custom-select-input {
  flex-grow: 1;
  top: auto;
  bottom: 100%;
  outline: none; }
  .input-field.select-input .custom-select-input .custom-select-input__control {
    border-radius: 5px;
    border: solid 1px transparent;
    background-color: #f4f4fa;
    box-shadow: none !important; }
    .input-field.select-input .custom-select-input .custom-select-input__control:hover {
      cursor: pointer;
      border-color: transparent; }
      .input-field.select-input .custom-select-input .custom-select-input__control:hover .custom-select-input__dropdown-indicator svg {
        color: #f5b717; }
    .input-field.select-input .custom-select-input .custom-select-input__control.custom-select-input__control--is-disabled {
      background-color: #ffffff;
      border: solid 2px #f4f4fa; }
    .input-field.select-input .custom-select-input .custom-select-input__control.custom-select-input__control--is-focused {
      border-color: transparent; }
  .input-field.select-input .custom-select-input .custom-select-input__value-container--has-value .custom-select-input__single-value {
    color: #2e2e2e; }
  .input-field.select-input .custom-select-input .custom-select-input__indicator-separator {
    visibility: hidden; }
  .input-field.select-input .custom-select-input .custom-select-input__dropdown-indicator svg {
    color: #009dbf; }
  .input-field.select-input .custom-select-input .custom-select-input__menu {
    margin-top: 5px;
    z-index: 1000; }
    .input-field.select-input .custom-select-input .custom-select-input__menu .custom-select-input__menu-list .custom-select-input__option {
      cursor: pointer;
      font-size: 13px;
      line-height: 25px;
      color: #2e2e2e; }
    .input-field.select-input .custom-select-input .custom-select-input__menu .custom-select-input__menu-list .custom-select-input__option--is-focused {
      background-color: #f4f4fa; }
    .input-field.select-input .custom-select-input .custom-select-input__menu .custom-select-input__menu-list .custom-select-input__option--is-selected {
      background-color: #ddd; }
    .input-field.select-input .custom-select-input .custom-select-input__menu .custom-select-input__menu-list .custom-select-input__menu-notice {
      color: #2e2e2e; }

input[disabled] {
  cursor: not-allowed; }

.table-row {
  display: flex;
  align-items: center;
  height: 63px;
  border-bottom: 2px solid #f4f4fa;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  color: #2e2e2e;
  word-wrap: break-word; }
  .table-row.clickable {
    cursor: pointer; }
    .table-row.clickable:hover {
      background-color: aliceblue; }

.list-placeholder {
  color: #afafb7;
  font-size: 1.1rem;
  margin-top: 0.75rem;
  text-align: center;
  display: block; }

.table-header {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #9c9ca0;
  font-weight: 600;
  font-size: 11px; }

.list-wrapper {
  margin: 1rem 0 2rem 0; }
  .list-wrapper .headers {
    display: flex;
    text-transform: uppercase;
    color: #9c9ca0;
    font-size: 11px;
    font-weight: 600;
    border-bottom: 2px solid #f4f4fa;
    padding: 1rem 0; }
  .list-wrapper .table-header {
    border-top: none; }
  .list-wrapper .list-title {
    height: 4rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2e2e2e;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 900;
    border-bottom: 2px solid #f4f4fa; }

@media (max-width: 768px) {
  .list-wrapper {
    padding: 0; }
    .list-wrapper .headers {
      display: none; }
    .list-wrapper .table-row {
      flex-wrap: wrap;
      padding: 5px 0;
      min-height: 3.5rem;
      height: inherit; }
      .list-wrapper .table-row > div[class*='col-'] {
        padding-top: 2px;
        padding-bottom: 2px; } }

@media (min-width: 769px) {
  .list-wrapper .table-header {
    border-top: 2px solid #f4f4fa;
    border-bottom: 2px solid #f4f4fa;
    margin: 0 15px;
    padding: 10px 0 10px 0; } }

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; }
  .toggle-container.centered {
    justify-content: center; }
  .toggle-container label {
    margin: 0 10px; }

.tgl-btn.checked:after {
  left: 50%; }

.tgl-btn.checked {
  background: #009dbf; }

.un-selectable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }

.tgl-btn {
  display: inline-block;
  vertical-align: -18px;
  margin-left: 10px;
  margin-right: 10px;
  outline: 0;
  width: 52px;
  height: 26px;
  position: relative;
  cursor: pointer;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .tgl-btn:after, .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%; }
  .tgl-btn:after {
    border-radius: 5px;
    background: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .tgl-btn:after {
    left: 0; }
  .tgl-btn:before {
    display: none; }

.isolated .isolated-header {
  display: flex;
  height: 5.75rem;
  background-color: #f4f4fa; }
  .isolated .isolated-header .go-back {
    display: flex;
    align-items: center;
    padding: 0 2rem; }
    .isolated .isolated-header .go-back i {
      -webkit-transform: rotate(-135deg) scale(0.8);
              transform: rotate(-135deg) scale(0.8);
      cursor: pointer; }
  .isolated .isolated-header .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1; }
    .isolated .isolated-header .header-content .header-title {
      font-weight: bold;
      font-family: Quicksand, serif;
      font-size: 1.7rem; }
    .isolated .isolated-header .header-content .header-subtitle {
      color: #2e2e2e;
      font-size: 0.85rem;
      font-family: Quicksand, sans-serif; }

.isolated .isolated-content {
  justify-content: center;
  height: calc(100vh - 5.75rem);
  overflow: auto; }
  .isolated .isolated-content .isolated-content-container {
    height: 100%; }
  .isolated .isolated-content .spinner {
    flex-grow: 1;
    height: 100%; }
    .isolated .isolated-content .spinner::after {
      background-color: transparent; }
  .isolated .isolated-content .list-wrapper {
    margin: 0;
    height: 100%; }
  .isolated .isolated-content .table-row {
    height: auto;
    overflow: unset; }

@media (max-width: 768px) {
  .isolated .isolated-header {
    height: 4rem; }
    .isolated .isolated-header .go-back {
      padding: 0 1rem; }
    .isolated .isolated-header .header-content .header-title {
      font-size: 1.2rem; }
    .isolated .isolated-header .header-content .header-subtitle {
      font-size: 0.75rem; }
  .isolated .isolated-content {
    height: calc(100vh - 4rem);
    min-height: unset; } }

.main-menu {
  width: 100%;
  height: 100%;
  display: grid; }
  .main-menu .header-notification-button.icon-notification {
    display: none; }
  .main-menu .main-menu-content-header {
    display: flex;
    align-items: center; }
    .main-menu .main-menu-content-header i {
      padding: 3px;
      cursor: pointer;
      position: absolute;
      left: 0;
      display: flex;
      color: #008fa7;
      font-size: 2.5rem; }
    .main-menu .main-menu-content-header i:hover {
      color: #008fa7;
      text-decoration: none;
      border-bottom: none; }
    .main-menu .main-menu-content-header label {
      font-family: Quicksand, serif;
      font-weight: 600;
      padding: 11px;
      margin: 0; }
  .main-menu .menu-overlay .menu-items {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    width: 250px;
    background-color: #e7e7f2; }
  .main-menu .menu-overlay .main-menu-header {
    grid-column-start: 1;
    grid-row-start: 1; }
    .main-menu .menu-overlay .main-menu-header i {
      color: #009dbf;
      margin-left: 12px;
      font-size: 2.5rem;
      cursor: pointer;
      align-self: flex-end; }
  .main-menu .menu-overlay .nav {
    overflow-y: auto;
    flex-wrap: nowrap; }
  .main-menu .menu-overlay .main-menu-footer {
    grid-column-start: 1;
    grid-row-start: 3; }
    .main-menu .menu-overlay .main-menu-footer .main-menu-items-footer,
    .main-menu .menu-overlay .main-menu-footer .main-menu-items-version {
      width: 100%;
      letter-spacing: 2.4px;
      font-size: 12px;
      text-align: center;
      font-family: "Lato", sans-serif;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: #afafb7; }
    .main-menu .menu-overlay .main-menu-footer .main-menu-items-version {
      font-size: 9px;
      font-weight: normal; }
  .main-menu .menu-overlay .main-menu-logo {
    width: 125px;
    margin-left: 16px;
    object-fit: cover; }
  .main-menu .menu-overlay .main-menu-item {
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.82;
    letter-spacing: 0.6px; }
  .main-menu .menu-overlay .main-menu-item,
  .main-menu .menu-overlay .main-menu-header {
    height: 60px;
    display: flex;
    align-items: center; }
  .main-menu .menu-overlay .main-menu-notifier {
    height: 60px;
    width: 5px; }
    .main-menu .menu-overlay .main-menu-notifier.active {
      background-color: #009dbf; }
  .main-menu .menu-overlay .main-menu-link {
    cursor: pointer;
    text-transform: uppercase;
    color: #2e2e2e;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 16px; }
    .main-menu .menu-overlay .main-menu-link:hover {
      text-decoration: none; }
    .main-menu .menu-overlay .main-menu-link span {
      margin-left: 16px; }
    .main-menu .menu-overlay .main-menu-link.active {
      color: #009dbf; }
  .main-menu .menu-overlay .main-menu-icon {
    font-size: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 60px; }
    .main-menu .menu-overlay .main-menu-icon i {
      color: #afafb7; }
      .main-menu .menu-overlay .main-menu-icon i.active {
        color: #009dbf; }
  .main-menu .menu-overlay .prosizer-icon {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    padding: 6px;
    height: 60px;
    fill: #afafb7; }

@media (max-width: 768px) {
  .main-menu {
    grid-template-columns: 250px 1fr;
    grid-template-rows: 60px 1fr; }
    .main-menu .main-menu-content-header {
      grid-column-start: 1;
      grid-column-end: 2 span;
      grid-row-start: 1;
      justify-content: center;
      background: #e7e7f2;
      z-index: 3; }
    .main-menu .main-menu-content::before {
      top: 10px;
      z-index: 2;
      position: fixed;
      left: -3px;
      width: 300px;
      background: #97424200;
      box-shadow: 0 15px 10px #7777777d;
      content: " ";
      display: block;
      float: left;
      height: 29px;
      padding-left: 20px;
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg); }
    .main-menu .main-menu-content {
      grid-column-start: 1;
      grid-column-end: 2 span;
      grid-row-start: 2;
      grid-row-end: 3 span;
      overflow-y: auto; }
    .main-menu .menu-overlay {
      grid-row-start: 1;
      grid-row-end: 2 span;
      grid-column-start: 1;
      grid-column-end: 2 span;
      z-index: 5;
      background-color: transparent;
      visibility: hidden; }
      .main-menu .menu-overlay .menu-items {
        grid-template-rows: 60px 1fr 65px;
        box-shadow: 0 2px 13px 1px rgba(3, 0, 69, 0.1);
        -webkit-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
        -webkit-transform: translate(-100%, 0);
                transform: translate(-100%, 0); }
    .main-menu .menu-overlay.visible {
      background-color: rgba(0, 0, 0, 0.3);
      visibility: visible; }
      .main-menu .menu-overlay.visible .menu-items {
        -webkit-transition: 0.5s ease-out;
        transition: 0.5s ease-out;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0); } }

@media (min-width: 769px) {
  .main-menu {
    grid-template-columns: 250px 1fr;
    grid-template-rows: 75px 1fr; }
    .main-menu .main-menu-content-header {
      grid-column-start: 2;
      grid-row-start: 1;
      justify-content: flex-start;
      border-bottom: solid 1px white;
      background: #f4f4fa;
      z-index: 3; }
      .main-menu .main-menu-content-header .page-name {
        padding-left: 3rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 19px; }
      .main-menu .main-menu-content-header .icon-menu {
        display: none; }
    .main-menu .menu-overlay,
    .main-menu .menu-overlay.visible {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: span 2; }
      .main-menu .menu-overlay .main-menu-logo,
      .main-menu .menu-overlay.visible .main-menu-logo {
        width: 165px;
        margin: 0; }
      .main-menu .menu-overlay .menu-items,
      .main-menu .menu-overlay.visible .menu-items {
        grid-template-rows: 124px 1fr 65px; }
        .main-menu .menu-overlay .menu-items .main-menu-header,
        .main-menu .menu-overlay.visible .menu-items .main-menu-header {
          display: flex;
          height: auto;
          justify-content: center;
          align-items: center; }
          .main-menu .menu-overlay .menu-items .main-menu-header .header-notification-button,
          .main-menu .menu-overlay.visible .menu-items .main-menu-header .header-notification-button {
            color: #afafb7;
            margin-right: 21px;
            font-size: 2rem; }
          .main-menu .menu-overlay .menu-items .main-menu-header i,
          .main-menu .menu-overlay.visible .menu-items .main-menu-header i {
            display: none; }
    .main-menu .menu-overlay::before {
      z-index: 2;
      position: fixed;
      left: 136px;
      width: 300px;
      bottom: 136px;
      background: #97424200;
      box-shadow: -5px 0px 15px 10px #77777766;
      content: " ";
      display: block;
      float: left;
      height: 30px;
      padding-left: 20px;
      -webkit-transform: rotate(-4deg);
      transform: rotate(94deg); }
    .main-menu .main-menu-content {
      grid-column: 2;
      grid-row-start: 2;
      grid-row-end: 3 span;
      overflow-y: auto;
      overflow-x: hidden;
      background: white;
      z-index: 4; } }

.partnerships-list .custom-list-item {
  padding: 0.6rem; }
  .partnerships-list .custom-list-item .arrow-icon {
    justify-content: flex-end; }
    .partnerships-list .custom-list-item .arrow-icon i:hover {
      color: #f5b717;
      cursor: pointer; }
    .partnerships-list .custom-list-item .arrow-icon .icon-trash-active {
      font-size: 1.75rem;
      padding-right: 0.25rem;
      color: #009dbf;
      align-self: center;
      cursor: pointer; }
      .partnerships-list .custom-list-item .arrow-icon .icon-trash-active:hover {
        color: #ff525a; }
  .partnerships-list .custom-list-item .partnership-name {
    color: #2e2e2e;
    padding-bottom: 0.25rem;
    font-weight: 600;
    font-family: Quicksand, serif;
    font-size: 0.86rem; }
  .partnerships-list .custom-list-item .badge {
    font-family: Quicksand, serif;
    font-size: 0.6rem;
    padding: 0.35rem 0.75rem; }

.partnerships-list .validate-button {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0; }

.partnership .partnership-header {
  display: flex;
  height: 5.75rem;
  background-color: #f4f4fa; }
  .partnership .partnership-header .arrow-back {
    display: flex;
    align-items: center;
    padding: 0 2rem; }
    .partnership .partnership-header .arrow-back i {
      -webkit-transform: rotate(-135deg) scale(0.8);
              transform: rotate(-135deg) scale(0.8);
      cursor: pointer; }
  .partnership .partnership-header .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1; }
    .partnership .partnership-header .header-content .header-title {
      font-weight: bold;
      font-family: Quicksand, serif;
      font-size: 1.7rem; }
  .partnership .partnership-header .header-badge {
    display: flex;
    align-items: center;
    padding: 0 2rem; }
    .partnership .partnership-header .header-badge .badge {
      font-family: Quicksand, serif;
      font-size: 0.6rem;
      padding: 0.35rem 0.75rem; }

.partnership .partnership-content {
  justify-content: center;
  height: calc(100vh - 5.75rem);
  overflow: auto; }
  .partnership .partnership-content .spinner {
    flex-grow: 1;
    height: 100%; }
    .partnership .partnership-content .spinner::after {
      background-color: transparent; }
  .partnership .partnership-content .list-wrapper {
    margin: 0;
    height: 100%; }
  .partnership .partnership-content .table-row {
    height: auto;
    border-bottom: 1px solid #f4f4fa;
    border-top: 1px solid #f4f4fa; }
  .partnership .partnership-content .tado-container {
    height: 100%;
    border-bottom: 0; }
    .partnership .partnership-content .tado-container .tado-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem; }
      .partnership .partnership-content .tado-container .tado-content .tado-title {
        font-family: Quicksand, serif;
        font-size: 1.15rem;
        padding-bottom: 1rem;
        font-weight: normal;
        text-align: center;
        width: 15rem; }
      .partnership .partnership-content .tado-container .tado-content .tado-auth-loading .spinner {
        margin-bottom: 1rem; }
      .partnership .partnership-content .tado-container .tado-content .tado-button {
        padding: 0.35rem; }
        .partnership .partnership-content .tado-container .tado-content .tado-button .outline {
          background-color: transparent;
          color: #009dbf;
          border: 1px solid #009dbf; }
          .partnership .partnership-content .tado-container .tado-content .tado-button .outline:hover {
            background-color: transparent;
            color: #f5b717;
            border-color: #f5b717; }
      .partnership .partnership-content .tado-container .tado-content .tado-home-select {
        padding-bottom: 0.5rem; }
        .partnership .partnership-content .tado-container .tado-content .tado-home-select .input-field {
          max-width: 18rem;
          min-width: 14rem; }
          .partnership .partnership-content .tado-container .tado-content .tado-home-select .input-field .custom-select-input {
            bottom: auto; }
      .partnership .partnership-content .tado-container .tado-content .device-container.saved {
        display: flex;
        padding: 1.2rem 0; }
      .partnership .partnership-content .tado-container .tado-content .device-container .device-info, .partnership .partnership-content .tado-container .tado-content .device-container .room-info {
        padding: 0.25rem 0; }
      .partnership .partnership-content .tado-container .tado-content .device-container .device-info {
        flex-grow: 1; }
        .partnership .partnership-content .tado-container .tado-content .device-container .device-info.ignored {
          background-color: #9c9ca00f;
          align-items: center; }
          .partnership .partnership-content .tado-container .tado-content .device-container .device-info.ignored .ignored-device-label {
            font-family: Quicksand, serif;
            font-size: 0.8rem;
            text-transform: uppercase;
            color: #afafb7; }
        .partnership .partnership-content .tado-container .tado-content .device-container .device-info .icon-cross, .partnership .partnership-content .tado-container .tado-content .device-container .device-info .icon-add {
          align-self: center;
          height: 100%;
          display: flex;
          font-size: 1.5rem;
          color: #009dbf;
          cursor: pointer;
          padding: 0 1rem; }
          .partnership .partnership-content .tado-container .tado-content .device-container .device-info .icon-cross:hover, .partnership .partnership-content .tado-container .tado-content .device-container .device-info .icon-add:hover {
            color: #f5b717; }
        .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container {
            display: flex;
            align-items: center; }
            .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .device-name {
              font-size: 0.85rem; }
            .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .device-edit {
              margin: 0 0.5rem;
              background-color: transparent;
              border: 0;
              -webkit-transform: rotateY(180deg);
                      transform: rotateY(180deg);
              padding: 0 0.1rem;
              font-size: 0.5rem; }
              .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .device-edit:hover {
                background-color: #f5b717;
                color: #ffffff; }
                .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .device-edit:hover svg {
                  fill: #ffffff; }
              .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .device-edit svg {
                height: 22px;
                width: 20px;
                padding: 3px;
                fill: #009dbf; }
            .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container .input-field {
              margin: 0 !important; }
            .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-name-container label {
              display: none; }
          .partnership .partnership-content .tado-container .tado-content .device-container .device-info .device-title .device-type {
            font-size: 0.75rem; }
      .partnership .partnership-content .tado-container .tado-content .device-container .room-info .room-control {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%; }
        .partnership .partnership-content .tado-container .tado-content .device-container .room-info .room-control .input-field {
          width: 48%; }
      .partnership .partnership-content .tado-container .tado-content .device-container .room-info .room-info-title {
        color: #afafb7;
        font-weight: normal;
        margin: 0.1rem 0.5rem; }
      .partnership .partnership-content .tado-container .tado-content .device-container .room-info .room-info-content {
        margin: 0.1rem 0;
        font-weight: normal; }
      .partnership .partnership-content .tado-container .tado-content .device-container .device-buttons {
        display: flex;
        align-items: center;
        flex-grow: 0; }
        .partnership .partnership-content .tado-container .tado-content .device-container .device-buttons .device-delete {
          font-size: 2rem;
          color: #009dbf;
          align-self: center;
          cursor: pointer; }
          .partnership .partnership-content .tado-container .tado-content .device-container .device-buttons .device-delete:hover {
            color: #ff525a; }
      .partnership .partnership-content .tado-container .tado-content .devices-title {
        justify-content: center;
        border: 0; }
        .partnership .partnership-content .tado-container .tado-content .devices-title .tado-title {
          padding: 2rem 0; }
      .partnership .partnership-content .tado-container .tado-content .validate-devices {
        display: flex;
        justify-content: center;
        border: 0;
        flex-wrap: wrap; }
        .partnership .partnership-content .tado-container .tado-content .validate-devices button {
          padding: 0.4rem 2.25rem;
          margin-top: 1rem;
          margin-right: 0.75rem; }
      .partnership .partnership-content .tado-container .tado-content button.disconnect {
        font-weight: bold;
        font-size: 0.75rem;
        padding: 0.375rem 0.75rem; }

.partnership .enphase-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .partnership .enphase-container .form-wrapper {
    width: 100%; }
    .partnership .enphase-container .form-wrapper .form-buttons {
      padding: 1rem 0.5rem 0rem 0.5rem; }
    .partnership .enphase-container .form-wrapper .form-buttons button {
      font-size: 0.75rem;
      width: 8.5rem; }
    .partnership .enphase-container .form-wrapper .form-buttons .update-email {
      width: auto; }
    .partnership .enphase-container .form-wrapper .input-top-interval {
      margin-top: 0.75rem; }
  .partnership .enphase-container .envoy-container, .partnership .enphase-container .email-container {
    display: flex;
    flex-direction: column;
    border: 0;
    padding: 1.5rem 0; }
  .partnership .enphase-container .email-title, .partnership .enphase-container .envoy-title {
    font-family: Quicksand, serif;
    font-size: 1.15rem;
    padding-bottom: 1rem;
    padding-top: 0.3rem;
    border-bottom: 2px solid #f4f4fa;
    font-weight: normal; }
  .partnership .enphase-container .email, .partnership .enphase-container .email-warning {
    color: #9b9b9b;
    font-size: 0.9rem;
    padding-left: 15px;
    margin: 0.5rem;
    font-weight: normal; }
  .partnership .enphase-container .email-warning {
    font-size: 0.8rem;
    padding-left: 0;
    margin-bottom: 0; }
  .partnership .enphase-container .email-buttons, .partnership .enphase-container .envoy-buttons {
    display: flex;
    justify-content: center; }
    .partnership .enphase-container .email-buttons button, .partnership .enphase-container .envoy-buttons button {
      padding: 1rem 3.5rem;
      margin: 0.5rem;
      border-radius: 10px;
      color: #009dbf !important; }
      .partnership .enphase-container .email-buttons button.active, .partnership .enphase-container .envoy-buttons button.active {
        background-color: #009dbf !important;
        color: #efeff5 !important; }
  .partnership .enphase-container .envoy-buttons {
    margin-top: 0.75rem; }

.partnership .header-error-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  padding-right: 15px; }
  .partnership .header-error-container .header-error {
    color: #ff525a;
    font-size: 0.85rem;
    font-family: Quicksand, serif;
    text-align: end;
    font-weight: bold; }
    .partnership .header-error-container .header-error.warning {
      color: #ffc107; }
  .partnership .header-error-container.bottom {
    justify-content: center;
    align-items: flex-start;
    margin: 0.75rem 0.5rem 0rem 0.5rem;
    height: 1rem; }

.partnership .enphase-disconnect-container {
  padding: 1rem 0; }
  .partnership .enphase-disconnect-container .enphase-disconnect-button {
    display: flex;
    justify-content: center; }
    .partnership .enphase-disconnect-container .enphase-disconnect-button button {
      font-weight: bold;
      font-size: 0.75rem;
      padding: 0.375rem 0.75rem;
      margin-top: 1.5rem; }

@media (max-width: 768px) {
  .partnership .partnership-header {
    height: 4rem; }
    .partnership .partnership-header .arrow-back {
      padding: 0 1rem; }
    .partnership .partnership-header .header-content .header-title {
      font-size: 1.2rem; }
    .partnership .partnership-header .header-content .header-subtitle {
      font-size: 0.75rem; }
      .partnership .partnership-header .header-content .header-subtitle .badge {
        font-size: 0.52rem; }
  .partnership .partnership-content {
    height: calc(100vh - 4rem); }
    .partnership .partnership-content .tado-container .tado-content .tado-home-select {
      justify-content: center; }
      .partnership .partnership-content .tado-container .tado-content .tado-home-select .input-field {
        width: 75% !important; } }

.icon-spinner {
  fill: #009dbf;
  -webkit-animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; }

@-webkit-keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.container-qualitySignal {
  margin-bottom: 20px; }

.text-align-right {
  text-align: right; }

.text-qualitySignal {
  margin-left: 10px;
  margin-right: 5px;
  color: #afafb7; }

.badge-signalComOne {
  position: relative;
  top: -1px; }

.my-smart-battery {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .my-smart-battery .spinner {
    height: 100% !important; }
  .my-smart-battery .table-row:last-child {
    border-bottom: 0; }
  .my-smart-battery .battery-documents .document-label {
    color: #afafb7;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    font-size: 0.9rem; }
    .my-smart-battery .battery-documents .document-label.without-buttons {
      padding-bottom: 0; }
    .my-smart-battery .battery-documents .document-label .additional-info {
      padding-top: 0.75rem; }
  .my-smart-battery .battery-documents .document-buttons-container {
    display: flex;
    justify-content: flex-end; }
    .my-smart-battery .battery-documents .document-buttons-container .document-button {
      display: flex;
      justify-content: center; }
      .my-smart-battery .battery-documents .document-buttons-container .document-button .download,
      .my-smart-battery .battery-documents .document-buttons-container .document-button .upload {
        padding: 0.75rem;
        width: 100%;
        font-size: 0.65rem;
        color: white;
        max-width: 15rem; }

.btn.btn-primary.remove-msb-button {
  background-color: #ff525a;
  color: white;
  border: solid 2px transparent; }
  .btn.btn-primary.remove-msb-button.disabled {
    cursor: not-allowed; }

.battery-form {
  border: 0; }
  .battery-form i {
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .battery-form .form-wrapper {
    width: 100%;
    padding: 2rem 0; }
    .battery-form .form-wrapper .input-field.custom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0.5rem; }
  .battery-form .edit-consuel-number-button,
  .battery-form .edit-serial-number-button {
    padding: 0.1rem 0.25rem;
    background-color: transparent;
    border-color: #009dbf;
    cursor: pointer; }
    .battery-form .edit-consuel-number-button:hover:not(:focus):not([disabled]),
    .battery-form .edit-serial-number-button:hover:not(:focus):not([disabled]) {
      background-color: transparent;
      border-color: #f5b717; }
      .battery-form .edit-consuel-number-button:hover:not(:focus):not([disabled]) i,
      .battery-form .edit-serial-number-button:hover:not(:focus):not([disabled]) i {
        color: #f5b717; }
    .battery-form .edit-consuel-number-button:active:not([disabled]),
    .battery-form .edit-serial-number-button:active:not([disabled]) {
      background-color: transparent !important;
      border-color: #f5b717 !important;
      box-shadow: none !important; }
      .battery-form .edit-consuel-number-button:active:not([disabled]) i,
      .battery-form .edit-serial-number-button:active:not([disabled]) i {
        color: #f5b717; }
    .battery-form .edit-consuel-number-button:disabled,
    .battery-form .edit-serial-number-button:disabled {
      background-color: white;
      color: #d3d3dd;
      border: solid 2px #e7e7f2; }
    .battery-form .edit-consuel-number-button .icon-profile,
    .battery-form .edit-serial-number-button .icon-profile {
      display: flex;
      font-size: 2rem;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
      color: #009dbf; }
  .battery-form .new-serial-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #afafb7; }
    .battery-form .new-serial-number-container .serial-number-container.is-full-width .input-field {
      width: 100%; }
    .battery-form .new-serial-number-container .serial-number-container .input-field {
      width: calc(100% - 2.25rem);
      margin-bottom: 0 !important; }
    .battery-form .new-serial-number-container .serial-number-container .alert-container {
      width: 100%; }
    .battery-form .new-serial-number-container .serial-number-container .icon-add,
    .battery-form .new-serial-number-container .serial-number-container .icon-trash-active {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 2rem;
      color: #009dbf;
      cursor: pointer; }
      .battery-form .new-serial-number-container .serial-number-container .icon-add:hover,
      .battery-form .new-serial-number-container .serial-number-container .icon-trash-active:hover {
        color: #f5b717; }
    .battery-form .new-serial-number-container .serial-number-container .icon-add {
      position: absolute;
      margin-left: 0.35rem; }
      .battery-form .new-serial-number-container .serial-number-container .icon-add.disabled {
        color: #d3d3dd !important;
        cursor: not-allowed !important; }
    .battery-form .new-serial-number-container .serial-number-container .icon-trash-active {
      position: absolute;
      margin-left: -2.15rem;
      color: #ff525a;
      z-index: 3; }
    .battery-form .new-serial-number-container .serial-number-container .edit-serial-number-button {
      position: absolute;
      margin-left: -2.55rem;
      z-index: 3; }
    .battery-form .new-serial-number-container .serial-number-container .new-serial-number-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      width: 100%; }
      .battery-form .new-serial-number-container .serial-number-container .new-serial-number-buttons button {
        min-width: 10rem;
        margin: 0.5rem; }
    .battery-form .new-serial-number-container .alert {
      margin: 0; }
  .battery-form .save-battery {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem; }
    .battery-form .save-battery .cancel-button,
    .battery-form .save-battery .remove-button {
      position: absolute;
      left: 15px; }
    .battery-form .save-battery button {
      min-width: 7rem; }
  .battery-form .skip-msb-step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .battery-form .skip-msb-step button {
      margin: 0 !important;
      min-width: 10rem; }

.edit-serial-number-modal .input-field {
  width: 100%; }

.edit-serial-number-modal .edit-serial-number-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .edit-serial-number-modal .edit-serial-number-buttons button {
    min-width: 10rem;
    margin: 0.5rem; }

.edit-consuel-number-modal .input-field {
  width: 100%; }

.edit-consuel-number-modal .info-container {
  padding-left: 1.5rem;
  margin: 0.75rem 0;
  font-size: 0.9rem; }
  .edit-consuel-number-modal .info-container ul {
    padding: 0;
    margin: 0; }
    .edit-consuel-number-modal .info-container ul li {
      padding-bottom: 1rem; }

.edit-consuel-number-modal .checkbox-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 0.9rem;
  padding: 0 0.5rem 1rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.edit-consuel-number-modal .save-button-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .edit-consuel-number-modal .save-button-container button {
    margin: 0.5rem;
    padding: 0.375rem 2.25rem; }

.battery-document-modal .custom-file {
  white-space: nowrap; }
  .battery-document-modal .custom-file label {
    overflow: auto;
    text-overflow: ellipsis;
    padding-right: 5rem; }

.battery-document-modal ul {
  margin: 0; }
  .battery-document-modal ul li:first-child, .battery-document-modal ul li:last-child {
    list-style: none;
    margin-left: -2rem; }
  .battery-document-modal ul li:first-child {
    padding-bottom: 0.2rem; }
  .battery-document-modal ul li:last-child {
    padding-top: 0.2rem; }

.battery-document-modal .pdf-container {
  height: 100%;
  width: 100%; }
  .battery-document-modal .pdf-container .pdf-inner-container {
    height: 100%;
    width: 100%; }
    .battery-document-modal .pdf-container .pdf-inner-container .pdf-page {
      height: 100%;
      width: 100%; }
      .battery-document-modal .pdf-container .pdf-inner-container .pdf-page canvas {
        height: 100% !important;
        width: 100% !important; }

.msb-status-text {
  font-size: 0.75rem;
  text-transform: uppercase; }
  .msb-status-text--activated {
    color: #7ed321; }
  .msb-status-text--created {
    color: #0ea5e9; }

.msb-activation-form-group {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 0 0 0 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  width: 100%; }
  .msb-activation-form-group__label {
    color: #afafb7;
    cursor: pointer;
    padding-left: 12px; }
  .msb-activation-form-group__input-and-icon-container {
    display: flex; }
  .msb-activation-form-group__input-and-addon-right-container {
    flex-grow: 1;
    display: flex;
    outline: 1px solid transparent;
    border-radius: 0.25rem; }
  .msb-activation-form-group__input-container {
    flex-grow: 1;
    display: flex;
    position: relative; }
  .msb-activation-form-group--with-error .msb-activation-form-group__input-and-addon-right-container {
    outline: 1px solid #ff525a; }
  .msb-activation-form-group--with-success .msb-activation-form-group__input-and-addon-right-container {
    outline: 1px solid #10b981; }
  .msb-activation-form-group__input-and-addon-right-container:focus-within {
    outline: 1px solid #f5b717; }
  .msb-activation-form-group--disabled .msb-activation-form-group__input-and-addon-right-container {
    outline: 1px solid #fafafa; }
  .msb-activation-form-group__upload-button {
    color: #9ca3af;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    padding: 9px 12px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #9ca3af;
    background: #fff;
    fill: #9ca3af; }
    .msb-activation-form-group__upload-button:hover {
      border: 1px solid #018fa7;
      color: #018fa7;
      fill: #018fa7; }
    .msb-activation-form-group__upload-button:focus {
      outline: none; }
  .msb-activation-form-group__input {
    flex-grow: 1;
    height: 32px;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.813rem;
    font-weight: 600;
    color: #9c9ca3;
    font-family: 'Lato', sans-serif;
    background-color: #f4f4fa;
    border: 1px solid #f4f4fa;
    outline: none; }
    .msb-activation-form-group__input[type="number"]:disabled {
      -moz-appearance: textfield; }
    .msb-activation-form-group__input:focus {
      font-weight: 400;
      color: #495057;
      background-color: #fff; }
    .msb-activation-form-group__input:disabled {
      color: #9c9ca3;
      background-color: #fff; }
  .msb-activation-form-group__edit-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem; }
  .msb-activation-form-group--with-addon-right .msb-activation-form-group__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .msb-activation-form-group__addon-right {
    display: inline-flex;
    align-items: center;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 0.5rem;
    background-color: #f9fafb;
    color: #6b7280;
    height: 32px; }
  .msb-activation-form-group__icon {
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-basis: 1.5rem; }
  .msb-activation-form-group__helper-text {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem; }
    .msb-activation-form-group__helper-text--info {
      color: #9c9ca3; }
  .msb-activation-form-group--with-error .msb-activation-form-group__helper-text--error {
    color: #ff525a; }

.enedis-grid-injection-text {
  font-size: 0.75rem;
  text-transform: uppercase; }
  .enedis-grid-injection-text--fill {
    color: #ff8b03; }
  .enedis-grid-injection-text--inProgress {
    color: #ff8b03; }
  .enedis-grid-injection-text--toValidate {
    color: #0ea5e9; }
  .enedis-grid-injection-text--requested {
    color: #7ed321; }
  .enedis-grid-injection-text--enabled {
    color: #7ed321; }

.enedis-grid-injection-description {
  color: #afafb7;
  font-size: 0.813rem;
  padding: 0.5rem 1rem;
  line-height: 1.125rem; }

.row-consuel-file {
  align-items: center;
  padding: 0 0 0 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  width: 100%; }
  .row-consuel-file__label {
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #afafb7;
    margin: 0; }
  .row-consuel-file__content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .row-consuel-file__content__file {
      flex-grow: 1; }
      .row-consuel-file__content__file__icon {
        flex-shrink: 0;
        margin-right: 10px; }
      .row-consuel-file__content__file__text {
        flex-basis: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        with-space: nowrap;
        margin-right: 10px;
        font-size: 12px;
        font-weight: bolder; }
  .row-consuel-file__check-icon {
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-basis: 1.5rem; }

.info-card {
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #f4f4f4;
  padding: 16px; }
  .info-card + .info-card {
    margin-top: 16px; }
  .info-card__step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #ffffff;
    border-radius: 50%;
    background: #ccebf2;
    height: 42px;
    width: 42px;
    color: #009dbf;
    font-size: 1.4rem;
    font-family: Quicksand, serif;
    font-weight: bold;
    text-align: center; }
    .info-card__step-number > span {
      display: block; }
  .info-card__body {
    font-size: 13px; }
    .info-card__body__title {
      margin-bottom: 8px;
      color: #009dbf;
      font-size: 1rem;
      font-family: Quicksand, serif;
      font-weight: bold; }

.strasbourg-electricity-administrative-procedure-text {
  font-size: 0.75rem;
  text-transform: uppercase; }
  .strasbourg-electricity-administrative-procedure-text--fill {
    color: #ff8b03; }
  .strasbourg-electricity-administrative-procedure-text--inProgress {
    color: #ff8b03; }
  .strasbourg-electricity-administrative-procedure-text--toValidate {
    color: #0ea5e9; }
  .strasbourg-electricity-administrative-procedure-text--finished {
    color: #7ed321; }

.administrativeProcedureDescription {
  color: #afafb7;
  font-size: 0.813rem;
  padding: 0.5rem 1rem;
  line-height: 1.125rem; }

.downloadArpeCard {
  border-radius: 8px;
  background: #f4f4fa;
  padding: 1rem;
  margin-left: 0.75rem;
  margin-bottom: 1rem; }
  .downloadArpeCard p {
    color: #4a4a4a;
    font-size: 0.813rem; }
  .downloadArpeCard > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 0.25rem;
    gap: 0.25rem; }
    .downloadArpeCard > div a {
      color: #018fa7;
      font-size: 1rem;
      font-weight: bold;
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline; }

.sidebar-guide {
  position: absolute;
  top: 71px;
  right: -205px;
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-filter: drop-shadow(rgba(46, 46, 46, 0.15) 0 0 3px);
          filter: drop-shadow(rgba(46, 46, 46, 0.15) 0 0 3px); }
  @media (min-width: 769px) {
    .sidebar-guide {
      top: 15px; } }
  .sidebar-guide:hover {
    right: 0; }
  .sidebar-guide__icon-container {
    width: 50px;
    height: 48px;
    padding: 10px 15px 10px 10px;
    background-color: #ffffff;
    border-radius: 50% 0 0 50%;
    cursor: pointer; }
  .sidebar-guide__icon {
    font-size: 28px;
    line-height: 28px; }
  .sidebar-guide__items {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0;
    padding: 12px;
    list-style: none;
    grid-gap: 15px;
    gap: 15px;
    border-radius: 0 0 0 2px;
    background-color: #ffffff; }
  .sidebar-guide__item {
    width: 100%; }
  .sidebar-guide__item-link {
    text-decoration: none;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    color: #2e2e2e; }
    .sidebar-guide__item-link:hover {
      text-decoration: none;
      color: #f5b717; }

.filters-popover .popover {
  margin-top: 1rem;
  margin-left: 1rem;
  border: 0; }
  .filters-popover .popover .popover-body {
    padding: 0;
    border: 0; }
    .filters-popover .popover .popover-body ul {
      width: 8rem; }
      .filters-popover .popover .popover-body ul button {
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
        outline: unset !important; }
        .filters-popover .popover .popover-body ul button.active {
          background-color: #009dbf;
          border-color: #009dbf;
          font-weight: bold;
          font-size: 0.84rem; }
        .filters-popover .popover .popover-body ul button .filter-option {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .filters-popover .popover .popover-body ul button .filter-option i {
            font-size: 1.09rem;
            line-height: 0.7rem; }

.filters-popover .arrow {
  display: none; }

.smooth-list {
  height: 100%;
  color: #2e2e2e;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem; }
  .smooth-list .titles-row {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: #9c9ca0;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    padding-left: 0; }
    .smooth-list .titles-row > div {
      padding: 15px 0;
      border-top: 1px solid #f4f4fa;
      border-bottom: 2px solid #f4f4fa;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 54px; }
      .smooth-list .titles-row > div:hover {
        color: #f5b717; }
        .smooth-list .titles-row > div:hover i:before {
          border-bottom-color: #f5b717; }
        .smooth-list .titles-row > div:hover i:after {
          border-top-color: #f5b717; }
        .smooth-list .titles-row > div:hover .icon-filter {
          color: #9c9ca0; }
    .smooth-list .titles-row .titles-row-title-container {
      display: flex;
      align-items: center;
      height: 21px;
      padding: 0 15px; }
      .smooth-list .titles-row .titles-row-title-container :first-child {
        justify-content: center; }
      .smooth-list .titles-row .titles-row-title-container .titles-row-title {
        overflow: hidden;
        text-overflow: ellipsis; }
      .smooth-list .titles-row .titles-row-title-container .icon-filter {
        margin-right: 0.35rem; }
        .smooth-list .titles-row .titles-row-title-container .icon-filter.filled svg {
          fill: #009dbf; }
        .smooth-list .titles-row .titles-row-title-container .icon-filter svg {
          height: 21px;
          width: 13px;
          fill: #9c9ca0; }
          .smooth-list .titles-row .titles-row-title-container .icon-filter svg:hover {
            fill: #009dbf; }
      .smooth-list .titles-row .titles-row-title-container .sort-notificator-container {
        margin-left: 5px; }
  .smooth-list .item-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 63px;
    border-bottom: solid 1px #f4f4fa; }
    .smooth-list .item-row:hover {
      background-color: aliceblue; }
    .smooth-list .item-row > :first-child {
      font-weight: bold; }
    .smooth-list .item-row .continue-subscription {
      font-size: 0.65rem; }
  .smooth-list .list {
    left: -15px; }
  .smooth-list .list:focus {
    outline: 0; }

@media screen and (max-width: 768px) {
  .smooth-list .list {
    left: 0; }
  .smooth-list .item-row {
    height: 107px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 0; }
    .smooth-list .item-row:hover {
      background-color: transparent; } }

.tenant-logo {
  border-radius: 25px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center; }
  .tenant-logo img {
    max-height: 30px; }
  .tenant-logo label {
    margin: 0;
    padding: 0;
    vertical-align: -2px; }

.full-page-list {
  height: 100%; }

.customer-lastName {
  text-transform: uppercase; }

.registration-status {
  padding-top: 0.2rem;
  min-width: 15rem; }
  .registration-status .registration-status-title {
    padding-right: 0.5rem;
    color: #afafb7;
    font-family: Quicksand, sans-serif; }

@media (max-width: 768px) {
  .full-page-list {
    height: calc(100vh - 60px - 60px); } }

.email-activation-button {
  margin-bottom: 15px; }

.system-devices-and-functions {
  border: 2px solid #e7e7f2;
  border-radius: 10px;
  margin: 0.5rem; }
  .system-devices-and-functions .table-row {
    height: auto; }
  .system-devices-and-functions .system-device-item {
    flex-wrap: nowrap !important;
    align-items: unset;
    border-bottom: 0 !important; }
    .system-devices-and-functions .system-device-item .device-container {
      flex-grow: 1;
      display: flex; }
      .system-devices-and-functions .system-device-item .device-container .device-info {
        flex-grow: 1;
        padding: 1rem; }
        .system-devices-and-functions .system-device-item .device-container .device-info.modbus {
          padding: 1.594rem 1rem; }
        .system-devices-and-functions .system-device-item .device-container .device-info .device-title {
          align-items: center;
          flex-wrap: nowrap; }
          .system-devices-and-functions .system-device-item .device-container .device-info .device-title .tenant-logo {
            height: 3rem;
            width: 3rem; }
            .system-devices-and-functions .system-device-item .device-container .device-info .device-title .tenant-logo img {
              width: 100%;
              height: 100%;
              max-height: unset; }
          .system-devices-and-functions .system-device-item .device-container .device-info .device-title .device-name {
            color: #2e2e2e;
            padding-bottom: 0.25rem;
            font-weight: 600;
            font-family: Quicksand, serif;
            font-size: 0.86rem; }
          .system-devices-and-functions .system-device-item .device-container .device-info .device-title .room-name {
            font-family: Quicksand, serif; }
        .system-devices-and-functions .system-device-item .device-container .device-info .device-details {
          display: flex;
          flex-direction: column;
          padding-top: 0.5rem;
          font-family: Quicksand, serif; }
          .system-devices-and-functions .system-device-item .device-container .device-info .device-details span {
            font-size: 0.75rem; }
          .system-devices-and-functions .system-device-item .device-container .device-info .device-details .activation-code,
          .system-devices-and-functions .system-device-item .device-container .device-info .device-details .mac {
            padding-right: 0.4rem; }
      .system-devices-and-functions .system-device-item .device-container .alert {
        display: flex;
        align-items: center;
        padding-right: 1.75rem; }
        .system-devices-and-functions .system-device-item .device-container .alert .fa-exclamation-circle {
          color: #ff525a;
          font-size: 1.225rem;
          margin-bottom: 5px;
          cursor: pointer;
          vertical-align: middle; }
  .system-devices-and-functions .device-functions {
    border-top: 2px solid #e7e7f2; }
    .system-devices-and-functions .device-functions .system-device-function-item {
      border-bottom: 1px solid #e7e7f2; }
      .system-devices-and-functions .device-functions .system-device-function-item.for-composite {
        flex-direction: column;
        align-items: unset; }
        .system-devices-and-functions .device-functions .system-device-function-item.for-composite .composite-device {
          padding-bottom: 0.5rem; }
          .system-devices-and-functions .device-functions .system-device-function-item.for-composite .composite-device .composite-device-children {
            padding: 0.25rem 6rem; }
            @media screen and (max-width: 576px) {
              .system-devices-and-functions .device-functions .system-device-function-item.for-composite .composite-device .composite-device-children {
                padding: 0.25rem 5rem; } }
            .system-devices-and-functions .device-functions .system-device-function-item.for-composite .composite-device .composite-device-children .children-power {
              flex-grow: 0; }
              .system-devices-and-functions .device-functions .system-device-function-item.for-composite .composite-device .composite-device-children .children-power .badge {
                padding: 0.3rem 0.75rem; }
      .system-devices-and-functions .device-functions .system-device-function-item .function-container {
        flex-grow: 1;
        align-items: center;
        padding: 1.157rem 15px;
        color: #2e2e2e; }
        .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info {
          flex-grow: 1; }
          .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-function {
            padding-bottom: 0.25rem;
            font-size: 1rem; }
          .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-icon {
            flex-grow: 0;
            padding-right: 0; }
          .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: Quicksand, serif; }
            .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-details .device-type {
              padding-bottom: 0.25rem;
              font-weight: 600;
              font-size: 0.86rem; }
            .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-details .device-room-name, .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-info .device-details .device-mac {
              font-size: 0.75rem; }
        .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-power {
          display: flex;
          align-items: center;
          flex-grow: 0;
          padding: 0 0.5rem; }
          .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-power .badge {
            padding: 0.3rem 0.75rem; }
        .system-devices-and-functions .device-functions .system-device-function-item .function-container .green-play {
          font-family: Quicksand, serif;
          font-size: 0.7rem;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          color: #7ed321;
          font-weight: bold; }
      .system-devices-and-functions .device-functions .system-device-function-item:last-child {
        border-bottom: 0 !important; }
        .system-devices-and-functions .device-functions .system-device-function-item:last-child .function-container {
          border-radius: 0 0 10px 10px; }
      .system-devices-and-functions .device-functions .system-device-function-item .function-container {
        padding: 0.5rem 1.5rem; }
        .system-devices-and-functions .device-functions .system-device-function-item .function-container .device-icon {
          padding-left: 0; }

@media (max-width: 768px) {
  .system-device-function-item.for-composite .composite-device .composite-device-children {
    padding: 0.25rem 3rem; }
  .system-device-item .device-container .alert {
    padding-right: 1.5rem !important; } }

.alert-translated-content {
  display: flex;
  flex-direction: column; }
  .alert-translated-content .alert-translated-tip {
    font-weight: bold;
    margin: 10px; }
  .alert-translated-content a {
    color: white;
    font-weight: bold; }
    .alert-translated-content a:hover {
      text-decoration: underline;
      color: white; }

.modbus-icon {
  padding: 4.8px; }
  .modbus-icon .st0 {
    fill: #0090aa; }
  .modbus-icon .st1 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st2 {
    fill: none;
    stroke: #0090aa;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st3 {
    fill: #ffffff;
    stroke: #0090aa;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st4 {
    fill: #ffffff;
    stroke: #0090aa;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st5 {
    fill: #ffffff; }
  .modbus-icon .st6 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 0.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st7 {
    fill: #0090aa;
    stroke: #0090aa;
    stroke-width: 0.2341;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st8 {
    fill: #0090aa;
    stroke: #0090aa;
    stroke-width: 0.2038;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st9 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-width: 1.75;
    stroke-miterlimit: 10; }
  .modbus-icon .st10 {
    fill: #ffffff;
    stroke: #ffffff;
    stroke-width: 1.1969;
    stroke-miterlimit: 10; }
  .modbus-icon .st11 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-miterlimit: 10; }
  .modbus-icon .st12 {
    fill: #0090aa;
    stroke: #ffffff;
    stroke-width: 0.25;
    stroke-miterlimit: 10; }
  .modbus-icon .st13 {
    fill: none;
    stroke: #ddd;
    stroke-width: 1.25;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st14 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 1.25;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st15 {
    fill: none;
    stroke: #ddd;
    stroke-miterlimit: 10; }
  .modbus-icon .st16 {
    fill: none;
    stroke: #0090aa;
    stroke-miterlimit: 10; }
  .modbus-icon .st17 {
    -webkit-clip-path: url(#SVGID_2_);
            clip-path: url(#SVGID_2_); }
  .modbus-icon .st18 {
    fill: none;
    stroke: #ddd;
    stroke-width: 4.4846;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st19 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4.4846;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .modbus-icon .st20 {
    fill: none;
    stroke: #e7e6e6;
    stroke-width: 2.4779;
    stroke-miterlimit: 10; }
  .modbus-icon .st21 {
    fill: #afdbe5; }
  .modbus-icon .st22 {
    fill: #067480; }
  .modbus-icon .st23 {
    fill: #0991aa; }
  .modbus-icon .st24 {
    fill: #5cb7c9; }
  .modbus-icon .st25 {
    fill: none;
    stroke: #0090aa;
    stroke-width: 0.25;
    stroke-linecap: round;
    stroke-miterlimit: 10; }

@media (min-width: 769px) {
  .indicators-container {
    width: 30%;
    float: left; }
  .map-container {
    margin-top: -2px;
    width: 70%;
    float: right; } }

@media (max-width: 768px) {
  .indicators-container {
    display: none; }
  .map-container {
    width: 100%; } }

.ProsizerAdvert_wrapper__2Mxqk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px;
  margin: 15px;
  border-bottom: 2px solid #f4f4fa; }
  @media (max-width: 768px) {
    .ProsizerAdvert_wrapper__2Mxqk {
      flex-direction: column; } }

.ProsizerAdvert_text__326gZ {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px;
  text-align: center; }

.ProsizerAdvert_link__39Gjc {
  text-transform: uppercase; }

.ProsizerAdvert_image-box__1cYan img {
  height: 40px; }

.dashboard {
  background-color: white; }
  .dashboard .spinner {
    height: auto; }

.technical-support__buttons {
  grid-gap: 15px;
  gap: 15px; }

.forgot-password .forgot-password-introduction {
  text-align: justify; }

.not-found .not-found-code {
  font-size: 120pt;
  color: #009dbf; }

.not-found .btn,
.not-found .not-found-title,
.not-found .not-found-code,
.not-found .not-found-lost {
  margin: 0 auto; }

.not-found .not-found-lost {
  margin-top: 50px; }

.not-found .btn {
  margin-top: 30px; }

.ucg-hint-button {
  margin: 0 10px; }

.ucg-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.ucg-preconfiguration-wizard {
  margin: 15px 0;
  font-size: 0.85rem;
  text-align: justify; }
  .ucg-preconfiguration-wizard .ucg-preconfiguration-wizard-title {
    color: #009dbf;
    font-weight: bold; }
  .ucg-preconfiguration-wizard .ucg-preconfiguration-wizard-info {
    margin-top: 5px;
    color: #afafb7; }

.start-configuration-assistance {
  display: flex;
  flex-direction: column; }
  .start-configuration-assistance.table-row {
    border: 0; }
  .start-configuration-assistance .start-configuration-assistance-container {
    flex-grow: 1;
    height: 3rem;
    width: 100%;
    border: 1px solid #009dbf;
    border-radius: 10px;
    margin: 0.75rem 0 0 0;
    background-color: #009dbf; }
    .start-configuration-assistance .start-configuration-assistance-container:hover {
      border-color: #f5b717;
      background-color: #f5b717; }
    .start-configuration-assistance .start-configuration-assistance-container span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
      color: #ffffff !important; }

.add-new-device .add-device-button-container {
  flex-grow: 1;
  height: 3rem;
  border: 1px solid #009dbf;
  border-radius: 10px;
  margin: 0.75rem 0; }
  .add-new-device .add-device-button-container:hover {
    border-color: #f5b717; }
    .add-new-device .add-device-button-container:hover .add-device {
      color: #f5b717; }
  .add-new-device .add-device-button-container span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem; }
  .add-new-device .add-device-button-container .add-device {
    font-size: 1.75rem;
    color: #009dbf;
    cursor: pointer; }

.refresh-button-container,
.add-device-button-container,
.start-configuration-assistance-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center; }
  .refresh-button-container:hover span,
  .add-device-button-container:hover span,
  .start-configuration-assistance-container:hover span {
    color: #f5b717; }
  .refresh-button-container span,
  .add-device-button-container span,
  .start-configuration-assistance-container span {
    font-size: 0.75rem;
    color: #009dbf;
    padding-left: 0.5rem; }

.save-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem; }

.registration-device-item {
  flex-wrap: nowrap !important;
  align-items: unset;
  border-bottom: 2px solid #f4f4fa !important; }
  .registration-device-item:hover .delete-button.deleted {
    opacity: 0.85; }
  .registration-device-item .device-container {
    flex-grow: 1;
    display: flex; }
    .registration-device-item .device-container:hover .edit-button i {
      color: #f5b717; }
    .registration-device-item .device-container .device-info {
      flex-grow: 1;
      padding: 1rem; }
      .registration-device-item .device-container .device-info.modbus {
        padding: 1.594rem 1rem; }
      .registration-device-item .device-container .device-info .device-title {
        align-items: center;
        flex-wrap: nowrap; }
        .registration-device-item .device-container .device-info .device-title .tenant-logo {
          height: 3rem;
          width: 3rem; }
          .registration-device-item .device-container .device-info .device-title .tenant-logo .customer-tenant-logo {
            max-height: 3rem; }
        .registration-device-item .device-container .device-info .device-title .device-name {
          color: #2e2e2e;
          padding-bottom: 0.25rem;
          font-weight: 600;
          font-family: Quicksand, serif;
          font-size: 0.86rem; }
        .registration-device-item .device-container .device-info .device-title .room-name {
          font-family: Quicksand, serif; }
      .registration-device-item .device-container .device-info .device-details {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
        font-family: Quicksand, serif; }
        .registration-device-item .device-container .device-info .device-details span {
          font-size: 0.75rem; }
        .registration-device-item .device-container .device-info .device-details .activation-code,
        .registration-device-item .device-container .device-info .device-details .mac {
          padding-right: 0.4rem; }
    .registration-device-item .device-container .edit-button {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer; }
      .registration-device-item .device-container .edit-button .icon-full-arrow-right {
        margin: 0px 10px 5px 0; }
    .registration-device-item .device-container .modbus-disconnected {
      display: flex;
      align-items: center;
      padding-right: 1rem; }
      .registration-device-item .device-container .modbus-disconnected .fa-exclamation-circle {
        color: #ff525a;
        font-size: 1.225rem;
        margin-bottom: 5px;
        cursor: pointer;
        vertical-align: middle; }
  .registration-device-item .delete-button {
    padding: 0 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #ff525a; }
    .registration-device-item .delete-button:hover {
      opacity: 0.85; }
    .registration-device-item .delete-button.deleted {
      background-color: #009dbf; }
      .registration-device-item .delete-button.deleted .trash {
        color: white; }
    .registration-device-item .delete-button .trash {
      color: #ffffff;
      font-size: 30px; }

.svg-icons-refresh {
  width: 18px;
  min-width: 18px; }
  .svg-icons-refresh path {
    fill: #009dbf; }
  .svg-icons-refresh.active path {
    fill: #009dbf; }
  .svg-icons-refresh g.loading {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: lds 1.2s infinite;
            animation: lds 1.2s infinite; }
  .svg-icons-refresh g {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

@-webkit-keyframes lds {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes lds {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.ucg-preconfiguration-modal .table-row {
  height: auto; }

.ucg-preconfiguration-modal .ucg-preconfiguration-summary .alert:last-child {
  margin-bottom: 0; }

.ucg-preconfiguration-modal .ucg-preconfiguration-view .spinner.loading {
  margin: 1rem 0; }

.ucg-preconfiguration-modal .devices-and-functions {
  border: 2px solid #e7e7f2;
  border-radius: 10px;
  margin: 0.5rem; }
  .ucg-preconfiguration-modal .devices-and-functions .registration-device-item {
    border-bottom: 0 !important; }
  .ucg-preconfiguration-modal .devices-and-functions .functions {
    border-top: 2px solid #e7e7f2; }
    .ucg-preconfiguration-modal .devices-and-functions .functions .registration-function-item {
      border-bottom: 1px solid #e7e7f2; }
      .ucg-preconfiguration-modal .devices-and-functions .functions .registration-function-item:last-child {
        border-bottom: 0 !important; }
        .ucg-preconfiguration-modal .devices-and-functions .functions .registration-function-item:last-child .function-container {
          border-radius: 0 0 10px 10px; }
      .ucg-preconfiguration-modal .devices-and-functions .functions .registration-function-item .function-container {
        padding: 0.5rem 1.5rem; }
        .ucg-preconfiguration-modal .devices-and-functions .functions .registration-function-item .function-container .device-icon {
          padding-left: 0; }
  .ucg-preconfiguration-modal .devices-and-functions .no-function {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-top: 2px solid #e7e7f2; }
    .ucg-preconfiguration-modal .devices-and-functions .no-function span {
      font-family: Quicksand, serif;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #afafb7; }

.ucg-preconfiguration-modal .btn-cancel-preconfiguration, .ucg-preconfiguration-modal .btn-apply-preconfiguration {
  margin-left: 10px; }

.ucg-preconfiguration-modal .list-wrapper {
  margin: 1rem 0; }

@media screen and (min-width: 576px) {
  .SelectDevicesConfigurationModeModal_modalContainer__3KVqh {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; } }

.SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_modalInnerContainer__1Dh75 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8rem;
  padding: 0.75rem 0; }
  @media screen and (min-width: 576px) {
    .SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_modalInnerContainer__1Dh75 {
      min-width: 23rem;
      padding: 0; } }

.SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_buttonContainer__64dYm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem; }
  .SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_buttonContainer__64dYm button:first-child {
    padding: 0.375rem 1.25rem; }
  .SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_buttonContainer__64dYm button:last-child {
    margin: 0.75rem; }
  @media screen and (max-width: 576px) {
    .SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_buttonContainer__64dYm button:first-child {
      padding: 0.75rem 1.75rem; }
    .SelectDevicesConfigurationModeModal_modalContainer__3KVqh .SelectDevicesConfigurationModeModal_buttonContainer__64dYm button:last-child {
      padding: 0.75rem 1.25rem; } }

.registration-function-item {
  border-bottom: 2px solid #f4f4fa !important; }
  .registration-function-item:hover .function-container .device-button i {
    color: #f5b717; }
  .registration-function-item.for-composite {
    flex-direction: column;
    align-items: unset; }
    .registration-function-item.for-composite .composite-device {
      padding-bottom: 0.5rem; }
      .registration-function-item.for-composite .composite-device .composite-device-children {
        padding: 0.25rem 6rem; }
        @media screen and (max-width: 576px) {
          .registration-function-item.for-composite .composite-device .composite-device-children {
            padding: 0.25rem 5rem; } }
        .registration-function-item.for-composite .composite-device .composite-device-children .children-power {
          flex-grow: 0; }
          .registration-function-item.for-composite .composite-device .composite-device-children .children-power .badge {
            padding: 0.3rem 0.75rem; }
  .registration-function-item .function-container {
    flex-grow: 1;
    align-items: center;
    padding: 1.157rem 15px;
    color: #2e2e2e; }
    .registration-function-item .function-container .device-info {
      flex-grow: 1; }
      .registration-function-item .function-container .device-info .device-function {
        padding-bottom: 0.25rem;
        font-size: 1rem; }
      .registration-function-item .function-container .device-info .device-icon {
        flex-grow: 0;
        padding-right: 0; }
      .registration-function-item .function-container .device-info .device-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: Quicksand, serif; }
        .registration-function-item .function-container .device-info .device-details .device-type {
          padding-bottom: 0.25rem;
          font-weight: 600;
          font-size: 0.86rem; }
        .registration-function-item .function-container .device-info .device-details .device-room-name, .registration-function-item .function-container .device-info .device-details .device-mac {
          font-size: 0.75rem; }
    .registration-function-item .function-container .device-power {
      display: flex;
      align-items: center;
      flex-grow: 0;
      padding: 0 0.5rem; }
      .registration-function-item .function-container .device-power .badge {
        padding: 0.3rem 0.75rem; }
      .registration-function-item .function-container .device-power .notification-icon {
        margin-right: 0.65rem !important; }
        .registration-function-item .function-container .device-power .notification-icon .fa-exclamation-circle {
          color: #ffc107;
          font-size: 1.225rem;
          cursor: pointer;
          vertical-align: middle; }
    .registration-function-item .function-container .device-button {
      flex-grow: 0;
      padding: 0 0.5rem; }
      .registration-function-item .function-container .device-button a {
        text-decoration: none !important; }
      .registration-function-item .function-container .device-button i {
        display: flex;
        line-height: unset;
        color: #009dbf;
        font-size: 2.5rem; }
    .registration-function-item .function-container .green-play {
      font-family: Quicksand, serif;
      font-size: 0.7rem;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      color: #7ed321;
      font-weight: bold; }

.device-function-edit {
  padding-top: 1rem;
  padding-bottom: 2rem; }
  .device-function-edit .splitable-device-functions {
    padding: 1rem 0; }
    .device-function-edit .splitable-device-functions .device-phase-function {
      padding: 1rem 0; }
      .device-function-edit .splitable-device-functions .device-phase-function:first-child {
        padding-top: 0; }
      .device-function-edit .splitable-device-functions .device-phase-function .device-phase-name {
        display: flex;
        align-items: center;
        padding: 0.5rem 0; }
        .device-function-edit .splitable-device-functions .device-phase-function .device-phase-name .badge {
          flex-grow: 0;
          padding: 0.3rem 0.75rem;
          font-family: "Lato", sans-serif; }
  .device-function-edit .splitable-device-divider {
    display: flex;
    justify-content: center;
    align-items: center; }
    .device-function-edit .splitable-device-divider button {
      margin: 1rem 0.25rem; }
  .device-function-edit .greenplay {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 2rem; }
  .device-function-edit .cancel-button {
    margin-right: 1rem; }

@media (max-width: 768px) {
  .registration-function-item.for-composite .composite-device .composite-device-children {
    padding: 0.25rem 3rem; } }

.finish-installation .grouped-list {
  align-items: baseline;
  border-bottom: 0;
  flex-wrap: wrap; }

.finish-installation .list-wrapper, .finish-installation .custom-responsive-list {
  padding-bottom: 2rem; }

.finish-installation .list-wrapper {
  margin: 0; }

.finish-installation .custom-responsive-list {
  padding-top: 0; }

.finish-installation .finish-installation-button {
  border-top: 2px solid #f4f4fa;
  flex-direction: column;
  align-items: unset; }
  .finish-installation .finish-installation-button .alert {
    margin: 0; }
  .finish-installation .finish-installation-button .save-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0; }

.finish-installation .summary-ucg .no-ucg-placeholder .list-placeholder {
  margin: 0; }

.finish-installation .partnerships .list-title {
  margin-bottom: 0; }

.finish-installation .partnerships .field.row div {
  font-size: 1rem;
  font-weight: normal; }

.finish-installation .partnerships .partnerships-item {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .finish-installation .partnerships .partnerships-item .icon-ok {
    color: #7ed321;
    font-size: 2rem; }

.finish-installation .devices-and-functions {
  border: 2px solid #e7e7f2;
  border-radius: 10px;
  margin: 0.5rem; }
  .finish-installation .devices-and-functions .registration-device-item {
    border-bottom: 0 !important; }
  .finish-installation .devices-and-functions .functions {
    border-top: 2px solid #e7e7f2; }
    .finish-installation .devices-and-functions .functions .registration-function-item {
      border-bottom: 1px solid #e7e7f2; }
      .finish-installation .devices-and-functions .functions .registration-function-item:last-child {
        border-bottom: 0 !important; }
        .finish-installation .devices-and-functions .functions .registration-function-item:last-child .function-container {
          border-radius: 0 0 10px 10px; }
      .finish-installation .devices-and-functions .functions .registration-function-item .function-container {
        padding: 0.5rem 1.5rem; }
        .finish-installation .devices-and-functions .functions .registration-function-item .function-container .device-icon {
          padding-left: 0; }
  .finish-installation .devices-and-functions .no-function {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-top: 2px solid #e7e7f2; }
    .finish-installation .devices-and-functions .no-function span {
      font-family: Quicksand, serif;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #afafb7; }

.finish-installation .container--waiting-finalisation-inscription {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(200, 200, 200, 0.5); }
  .finish-installation .container--waiting-finalisation-inscription .modal--waiting-finalisation-inscription {
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 200px);
    width: 400px;
    height: 200px;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    font-size: 1rem; }
    .finish-installation .container--waiting-finalisation-inscription .modal--waiting-finalisation-inscription .text--waiting-finalisation-inscription {
      padding-bottom: 10px; }
    .finish-installation .container--waiting-finalisation-inscription .modal--waiting-finalisation-inscription .spinner {
      height: 50px !important; }
      .finish-installation .container--waiting-finalisation-inscription .modal--waiting-finalisation-inscription .spinner:after {
        background-color: transparent; }

.finish-installation .badge-signalComOne {
  position: relative;
  top: -2px; }

.send-inscription-summary {
  font-size: 0.85rem;
  text-align: justify; }
  .send-inscription-summary .send-inscription-summary-title {
    color: #009dbf;
    font-weight: bold; }
  .send-inscription-summary .send-inscription-summary-info {
    margin-top: 5px;
    color: #afafb7; }
  .send-inscription-summary .send-inscription-summary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 15px 0; }

.collapsible {
  margin-right: -15px;
  margin-left: -15px; }
  .collapsible .error {
    color: #ff525a; }
  .collapsible .category {
    color: #a3a3a3; }
  .collapsible .slot {
    background-color: #fbfbfd;
    padding: 5px; }
    .collapsible .slot .frag {
      background-color: white;
      margin: 5px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .collapsible .slot .slotTitle {
      color: #a0a0a0;
      font-size: 13px;
      padding-top: 7px;
      padding-bottom: 7px; }

.collapse-header {
  border-bottom: solid 1px #e7e7f2;
  border-top: solid 1px #e7e7f2;
  color: #2e2e2e;
  padding: 16.5px;
  text-decoration: none; }
  .collapse-header .bold {
    font-weight: bold; }

.collapsible-header {
  cursor: pointer; }

.snackbar_snackBar__1idqz {
  position: fixed;
  bottom: 3px;
  right: 10%;
  left: 10%;
  color: white;
  background-color: #2e2e2e;
  padding: 15px 15px 15px;
  z-index: 2000;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  -webkit-transform: translate(0, 200px);
          transform: translate(0, 200px); }
  @media (min-width: 769px) {
    .snackbar_snackBar__1idqz {
      margin: auto;
      right: 20%;
      left: 20%; } }

.snackbar_closeButton__MebS_ {
  right: 15px;
  top: 6px;
  font-size: 26px;
  float: right;
  cursor: pointer; }

.snackbar_visible__G3AL8 {
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.snackbar_error__2fH2R {
  background-color: #ff525a; }

.snackbar_success__3JDf6 {
  background-color: #7ed321; }

.menu-stepper {
  height: calc(100% - 3px);
  background-color: #e7e7f2;
  display: flex;
  flex-flow: column; }
  .menu-stepper .table-row {
    height: auto; }
    .menu-stepper .table-row:last-child {
      border-bottom: 0; }
  .menu-stepper form {
    width: 100%; }
  .menu-stepper .list-wrapper {
    margin: 0;
    padding: 1rem; }
  .menu-stepper .menu-navigation .menu-item {
    border-bottom: solid 2px #e7e7f2;
    color: #afafb7;
    display: block;
    padding: 16.5px;
    text-decoration: none;
    background-color: #f4f4fa;
    height: 65px; }
    .menu-stepper .menu-navigation .menu-item.active {
      position: -webkit-sticky;
      position: sticky;
      top: 3px;
      z-index: 4; }
    .menu-stepper .menu-navigation .menu-item .item-step {
      height: 1.6rem;
      width: 1.6rem; }
    .menu-stepper .menu-navigation .menu-item .item-name {
      padding-left: 1rem;
      vertical-align: middle;
      font-family: Quicksand, sans-serif;
      color: #2e2e2e;
      font-size: 1.05rem; }
      .menu-stepper .menu-navigation .menu-item .item-name .step-number {
        padding-right: 0.4rem; }
    .menu-stepper .menu-navigation .menu-item.disabled {
      background-color: #e7e7f2;
      color: #afafb7;
      border-bottom: 2px solid #ffffff; }
  .menu-stepper .menu-header {
    background-color: #e7e7f2;
    box-shadow: 0 2px 5px 0 #dddde1;
    position: fixed;
    width: 100%;
    z-index: 3; }
    .menu-stepper .menu-header .pageName {
      font-weight: bold;
      font-size: 15.5px; }
    .menu-stepper .menu-header > div {
      margin-left: 20px; }
    .menu-stepper .menu-header .nav {
      margin-bottom: 45px;
      text-align: center; }
      .menu-stepper .menu-header .nav a {
        left: 0;
        position: absolute;
        display: inline-block;
        padding: 16.5px;
        color: #009dbf;
        font-weight: bold; }
        .menu-stepper .menu-header .nav a:hover {
          color: black;
          text-decoration: none;
          border-bottom: none; }
      .menu-stepper .menu-header .nav label {
        margin: 0;
        position: absolute;
        top: 16.5px; }
  .menu-stepper .content-wrapper {
    background: white;
    padding: 0 19px; }
  .menu-stepper .header-content-scrollable {
    margin-top: 88px;
    background-color: #f4f4fa;
    box-shadow: 0 1px 5px 0 #f4f4fa;
    padding: 10px 22px; }
  .menu-stepper .sub-menu-content {
    background-color: white;
    position: relative; }
    .menu-stepper .sub-menu-content.hidden {
      display: none; }
  @media (max-width: 768px) {
    .menu-stepper .menu-navigation {
      position: relative; }
      .menu-stepper .menu-navigation div#scroll-finish .spinner.loading {
        height: calc(100vh - 60px);
        overflow: hidden; } }
  @media (min-width: 769px) {
    .menu-stepper .menu-navigation a:hover {
      text-decoration: none;
      color: #f5b717; }
    .menu-stepper .sub-menu-content {
      background-color: white;
      position: relative; }
      .menu-stepper .sub-menu-content.hidden {
        display: block; }
    .menu-stepper .menu-navigation a:hover {
      text-decoration: none;
      color: #f5b717; }
    .menu-stepper .menu-navigation.subMenu {
      display: block; }
    .menu-stepper .menu-header {
      display: none; }
    .menu-stepper .menu-content {
      order: 2;
      z-index: 3;
      height: 100%; }
    .menu-stepper .menu-navigation {
      order: 1;
      padding-left: 60px; }
      .menu-stepper .menu-navigation .menu-item {
        border-top: none;
        display: inline-block;
        padding: 12px; }
        .menu-stepper .menu-navigation .menu-item:hover {
          text-decoration: none;
          color: #f5b717;
          border-bottom: 5px solid #f5b717; }
        .menu-stepper .menu-navigation .menu-item .right-arrow {
          display: none; }
      .menu-stepper .menu-navigation a.active {
        color: black;
        font-weight: bold;
        border-bottom: 5px solid #009dbf;
        display: inline-block; }
      .menu-stepper .menu-navigation a:hover {
        text-decoration: none;
        color: #f5b717;
        border-bottom: 5px solid #f5b717; }
    .menu-stepper .header-content-scrollable {
      display: none; } }
  .menu-stepper .right-arrow {
    border: solid #009dbf;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .menu-stepper .down-arrow {
    border: solid #009dbf;
    float: right;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-right: 16px;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .menu-stepper .left-arrow {
    border: solid #009dbf;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
    margin-left: 16px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }

.stepper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 3px;
  background-color: #f4f4fa;
  text-align: center; }
  .stepper .stepper-progress {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4fa; }
    .stepper .stepper-progress .progress {
      width: 100%;
      height: 100%;
      border-radius: 0; }
      .stepper .stepper-progress .progress .progress-bar {
        background-color: #009dbf; }
  .stepper .stepper-title {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 600; }

.registration-stepper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw; }
  .registration-stepper .table-row {
    height: auto; }
    .registration-stepper .table-row:last-child {
      border-bottom: 0; }
    .registration-stepper .table-row form {
      width: 100%; }
  .registration-stepper .list-wrapper {
    margin-top: 0; }
  .registration-stepper .registration-stepper-header {
    display: flex;
    flex-direction: column;
    height: 8.5rem;
    min-height: 8.5rem;
    background-color: #f4f4fa; }
    .registration-stepper .registration-stepper-header .header-content {
      display: flex;
      flex-grow: 1;
      padding: 0 2rem; }
      .registration-stepper .registration-stepper-header .header-content .arrow-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 4.5rem; }
        .registration-stepper .registration-stepper-header .header-content .arrow-back i {
          -webkit-transform: rotate(-135deg) scale(0.8);
                  transform: rotate(-135deg) scale(0.8); }
      .registration-stepper .registration-stepper-header .header-content .customer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1; }
        .registration-stepper .registration-stepper-header .header-content .customer-info .customer-name, .registration-stepper .registration-stepper-header .header-content .customer-info .customer-surname {
          font-weight: bold;
          font-family: Quicksand, serif;
          font-size: 1.7rem; }
        .registration-stepper .registration-stepper-header .header-content .customer-info .customer-surname {
          text-transform: uppercase;
          padding-right: 0.5rem; }
        .registration-stepper .registration-stepper-header .header-content .customer-info .customer-address a {
          color: #2e2e2e;
          font-size: 0.85rem;
          font-family: Quicksand, sans-serif;
          text-decoration: none; }
          .registration-stepper .registration-stepper-header .header-content .customer-info .customer-address a:hover {
            color: #f5b717; }
    .registration-stepper .registration-stepper-header .header-title {
      display: flex;
      align-items: center;
      height: 2.75rem;
      padding: 0 2rem;
      background-color: #e7e7f2;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.76rem;
      font-family: Lato, sans-serif; }
  .registration-stepper .registration-stepper-container {
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow: auto; }
    .registration-stepper .registration-stepper-container .registration-stepper-menu {
      display: flex;
      flex-direction: column;
      width: 16rem;
      min-width: 16rem;
      background-color: #f4f4fa; }
      .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item {
        display: flex;
        align-items: center;
        height: 4rem;
        color: #2e2e2e;
        border-bottom: 2px solid #efeff5;
        text-decoration: none;
        padding-left: 2rem; }
        .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item.active {
          border-left: 0.3rem solid #009dbf;
          padding-left: 1.7rem;
          font-weight: bold; }
        .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item.disabled {
          background-color: #e7e7f2;
          color: #afafb7;
          font-weight: bold; }
        .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item .item-step {
          height: 1.6rem;
          width: 1.6rem; }
        .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item .item-name {
          display: flex;
          padding-left: 1rem; }
          .registration-stepper .registration-stepper-container .registration-stepper-menu .menu-item .item-name span {
            font-size: 0.95rem;
            padding-right: 0.3rem;
            font-family: Quicksand, sans-serif; }
    .registration-stepper .registration-stepper-container .registration-stepper-content {
      flex-grow: 1;
      overflow: auto; }
      .registration-stepper .registration-stepper-container .registration-stepper-content .spinner {
        height: 100%;
        width: 100%; }

.barcode-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0; }
  .barcode-container .barcode-title {
    font-family: Quicksand, serif;
    padding: 1rem;
    text-align: center; }
  .barcode-container .barcode-result {
    display: flex;
    align-items: center;
    padding: 0.5rem; }
    .barcode-container .barcode-result .barcode-code {
      flex-grow: 1; }
      .barcode-container .barcode-result .barcode-code .code-correct {
        color: limegreen; }
      .barcode-container .barcode-result .barcode-code .code-wrong {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffc107;
        font-weight: bold; }
        .barcode-container .barcode-result .barcode-code .code-wrong span:first-child {
          font-size: 0.9rem;
          font-family: Quicksand, serif;
          color: #afafb7;
          text-transform: uppercase;
          font-weight: normal; }
  .barcode-container .barcode-rescan, .barcode-container .barcode-stop {
    padding: 1rem; }
  .barcode-container .barcode-stop {
    padding-top: 2rem; }
  .barcode-container #cont {
    width: 100%;
    overflow: hidden;
    padding: 1rem 0;
    max-height: 60vh; }
    .barcode-container #cont video, .barcode-container #cont canvas {
      width: 100%; }
    .barcode-container #cont canvas {
      position: absolute;
      max-height: 60vh; }

.new-device-list .device-type {
  padding: 1rem; }
  .new-device-list .device-type .device-type-name {
    color: #2e2e2e;
    padding-bottom: 0.25rem;
    font-weight: 600;
    font-family: Quicksand, serif;
    font-size: 0.86rem; }
  .new-device-list .device-type .arrow-icon,
  .new-device-list .device-type .device-type-helper {
    flex-grow: 0; }
  .new-device-list .device-type .device-type-helper {
    color: #009dbf; }

.new-device-list .cancel-button {
  float: right;
  margin: 1rem 0; }

.add-device .form-wrapper {
  padding: 2rem 0; }
  .add-device .form-wrapper .input-field {
    width: 100%;
    padding: 0.5rem 0; }
  .add-device .form-wrapper .scan-button {
    justify-content: flex-end; }
  .add-device .form-wrapper .add-device-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem; }
    .add-device .form-wrapper .add-device-button button {
      margin-left: 1rem; }

.device-type-image-container {
  text-align: center; }
  .device-type-image-container .device-type-image {
    max-width: 100%;
    max-height: 200px; }
  .device-type-image-container > p {
    margin: 0; }

.hint-button {
  margin: 0 10px; }

.upm-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .upm-image-modal img {
    max-width: 100%;
    max-height: 300px; }

.wmodbus-device-list {
  margin: 0 -15px;
  padding: 0;
  list-style: none; }

.wmodbus-device-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0; }

.device-details-container {
  height: 100%;
  align-items: center;
  padding: 4rem 0;
  min-height: 40rem; }
  .device-details-container .device-details {
    display: flex;
    flex-direction: column; }
    .device-details-container .device-details .input-field.custom button {
      margin: 0.5rem; }
      .device-details-container .device-details .input-field.custom button:first-child {
        margin-left: 0; }
  .device-details-container .device-help {
    max-height: 100%;
    overflow: auto; }
  .device-details-container .device-image {
    height: 20rem;
    width: 20rem;
    margin: 0 auto 1rem auto; }
    .device-details-container .device-image.device-description {
      margin: 1rem auto 2rem auto; }
    .device-details-container .device-image.gmd {
      width: 14rem; }
    .device-details-container .device-image.sw, .device-details-container .device-image.eth, .device-details-container .device-image.tph, .device-details-container .device-image.mst, .device-details-container .device-image.sw1, .device-details-container .device-image.mst1 {
      height: 15rem;
      width: 21rem; }
    .device-details-container .device-image.gmdtri1, .device-details-container .device-image.gmdmono1, .device-details-container .device-image.mst_g3mono3, .device-details-container .device-image.mst_g3tri3 {
      height: 15rem;
      width: 26rem; }
    .device-details-container .device-image.gmdtri2, .device-details-container .device-image.gmdmono2 {
      height: 15rem;
      width: 15rem; }
    .device-details-container .device-image.eth1 {
      height: 14rem;
      width: 15rem; }
    .device-details-container .device-image.eth2, .device-details-container .device-image.sw2, .device-details-container .device-image.mst2 {
      height: 12rem;
      width: 28rem; }
    .device-details-container .device-image.rs485_mc1, .device-details-container .device-image.rs485_mc3 {
      height: 19rem;
      width: 12rem; }
    .device-details-container .device-image.rs485_mc11, .device-details-container .device-image.rs485_mc12, .device-details-container .device-image.rs485_mc31, .device-details-container .device-image.rs485_mc32 {
      display: none; }
    .device-details-container .device-image.mst_g3mono1, .device-details-container .device-image.mst_g3tri1, .device-details-container .device-image.mst_g3mono5, .device-details-container .device-image.mst_g3tri5 {
      display: none; }
    .device-details-container .device-image.mst_g3mono2, .device-details-container .device-image.mst_g3tri2 {
      height: 8rem;
      width: 16rem; }
    .device-details-container .device-image.mst_g3mono4, .device-details-container .device-image.mst_g3tri4 {
      height: 4rem; }

@media (max-width: 992px) {
  .device-details-container {
    padding: 2rem 0; }
    .device-details-container .device-help {
      height: auto;
      padding: 4rem 1rem;
      overflow: unset; }
      .device-details-container .device-help span:last-child {
        padding-bottom: 2rem; }
    .device-details-container .device-image {
      height: 15rem;
      width: 15rem; }
      .device-details-container .device-image.gmd {
        width: 11rem; }
      .device-details-container .device-image.eth {
        width: 15rem;
        height: 11rem; }
      .device-details-container .device-image.mst_g3tri3, .device-details-container .device-image.mst_g3mono3, .device-details-container .device-image.gmdtri1, .device-details-container .device-image.gmdmono1, .device-details-container .device-image.eth2 {
        width: 75%;
        height: 12rem; }
      .device-details-container .device-image.eth2 {
        height: 9rem; }
      .device-details-container .device-image.rs485_mc1, .device-details-container .device-image.rs485_mc3 {
        width: 8rem;
        height: 15rem; } }

@media (max-width: 768px) {
  .device-details-container .device-details .form-container {
    padding-top: 0; } }

.container__tools-pairing .registration-device-item {
  height: 100%; }
  @media screen and (max-width: 576px) {
    .container__tools-pairing .registration-device-item .device-container {
      display: block; } }
  .container__tools-pairing .registration-device-item .device-container .device-info {
    width: 600px;
    max-width: 600px; }
  .container__tools-pairing .registration-device-item .device-container .device-power {
    flex-grow: 1;
    padding: 1rem; }
    @media screen and (max-width: 576px) {
      .container__tools-pairing .registration-device-item .device-container .device-power {
        padding-top: 0; } }
    .container__tools-pairing .registration-device-item .device-container .device-power .ct-container {
      margin-bottom: 5px; }

.container__tools-pairing {
  width: 500px;
  max-width: 500px; }

@media screen and (max-width: 992px) {
  .container__tools-pairing {
    width: 100%; } }

.my-smart-battery-container {
  height: 100%;
  overflow: hidden; }
  .my-smart-battery-container .my-smart-battery-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e7e7f2;
    width: 100%;
    padding: 0 3rem;
    height: 100px;
    border-bottom: 1px solid white; }
    .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-tenant-logo {
      height: 46px; }
      .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-tenant-logo img {
        height: 100%; }
    .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-logout-icon:hover {
      cursor: pointer; }
      .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-logout-icon:hover svg path, .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-logout-icon:hover svg line {
        stroke: #009dbf; }
    .my-smart-battery-container .my-smart-battery-page-header .my-smart-battery-logout-icon svg {
      height: 1.5rem;
      width: 2.5rem; }
  .my-smart-battery-container .my-smart-battery-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4fa;
    height: 60px;
    width: 100%;
    padding: 0 1rem; }
    .my-smart-battery-container .my-smart-battery-header.read-only {
      justify-content: space-between;
      padding: 0 3rem; }
      .my-smart-battery-container .my-smart-battery-header.read-only .my-smart-battery-header-title {
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 600;
        font-family: Quicksand, serif; }
      .my-smart-battery-container .my-smart-battery-header.read-only .my-smart-battery-header-search {
        position: relative;
        top: unset;
        right: unset; }
    .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle {
      display: flex;
      flex: 1 1; }
      .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0.25rem;
        font-size: 0.75rem;
        background-color: white !important;
        color: #afafb7;
        border: 1px solid transparent;
        border-radius: 0; }
        .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.mylight-energy {
          margin-right: 1rem; }
          .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.mylight-energy:hover {
            border: 1px solid #009dbf !important;
            color: #afafb7 !important; }
          .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.mylight-energy.selected {
            border: 1px solid #009dbf !important;
            color: #009dbf !important; }
        .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.planete-oui:hover {
          border: 1px solid #12b24b !important;
          color: #afafb7 !important; }
        .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.planete-oui.selected {
          border: 1px solid #12b24b !important;
          color: #12b24b !important; }
        .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container .type-toggle-inner-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem 0.5rem; }
          .my-smart-battery-container .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container .type-toggle-inner-container :first-child {
            margin-right: 0.25rem; }
    .my-smart-battery-container .my-smart-battery-header .my-smart-battery-header-search {
      position: absolute;
      top: 23.5px;
      right: 1rem;
      z-index: 3; }
      .my-smart-battery-container .my-smart-battery-header .my-smart-battery-header-search .btn.search {
        background-color: white;
        color: #009dbf;
        margin-right: 7.5px; }
    .my-smart-battery-container .my-smart-battery-header .my-smart-battery-header-reset-filters {
      margin-left: 1rem;
      display: none; }
  .my-smart-battery-container .my-smart-battery-inner-container {
    height: calc(100% - 60px); }
    .my-smart-battery-container .my-smart-battery-inner-container.read-only {
      height: calc(100% - 60px - 100px); }
      .my-smart-battery-container .my-smart-battery-inner-container.read-only .full-page-list {
        height: 100%; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.desktop .icon {
      display: flex;
      justify-content: center; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.desktop .list-item {
      text-align: center; }
      .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.desktop .list-item.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis; }
        .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.desktop .list-item.ellipsis:hover {
          overflow: visible;
          text-overflow: unset; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.mobile .list-item-name {
      font-size: 0.9rem;
      padding-right: 0.5rem;
      color: #9b9b9b;
      font-family: Quicksand, sans-serif; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.mobile .list-item-value {
      display: flex;
      justify-content: flex-end;
      font-family: Lato, sans-serif;
      font-weight: bold; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list.mobile .valid div {
      color: #009dbf; }

@media (max-width: 993px) {
  .my-smart-battery-container .my-smart-battery-page-header {
    padding: 3rem 1rem; }
  .my-smart-battery-container .my-smart-battery-header.read-only {
    padding: 0 1rem; } }

@media (max-width: 769px) {
  .my-smart-battery-header {
    padding: 0 .25rem !important; }
    .my-smart-battery-header.read-only {
      padding: 0 1rem !important; }
      .my-smart-battery-header.read-only .my-smart-battery-header-search {
        position: relative !important;
        top: unset !important;
        right: unset !important; }
    .my-smart-battery-header .my-smart-battery-type-toggle .type-toggle-container.mylight-energy {
      margin-right: 0.25rem !important; }
    .my-smart-battery-header .my-smart-battery-header-search {
      top: 16px !important;
      right: 0.5rem !important; } }

@media (min-width: 769px) {
  .my-smart-battery-container .my-smart-battery-header .my-smart-battery-header-reset-filters {
    display: block; } }

@media (min-width: 768px) and (max-width: 992px) {
  .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list .smooth-list .list {
    left: 0; }
  .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list .smooth-list .item-row {
    height: 107px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 0; }
    .my-smart-battery-container .my-smart-battery-inner-container .my-smart-battery-list .smooth-list .item-row:hover {
      background-color: transparent; } }

.filters-popover .popover {
  margin-top: 1rem;
  margin-left: 1rem;
  border: 0; }
  .filters-popover .popover .popover-body {
    padding: 0;
    border: 0; }
    .filters-popover .popover .popover-body ul {
      width: 8rem; }
      .filters-popover .popover .popover-body ul button {
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
        outline: unset !important; }
        .filters-popover .popover .popover-body ul button.active {
          background-color: #009dbf;
          border-color: #009dbf;
          font-weight: bold;
          font-size: 0.84rem; }
        .filters-popover .popover .popover-body ul button .filter-option {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .filters-popover .popover .popover-body ul button .filter-option i {
            font-size: 1.09rem;
            line-height: 0.7rem; }

.filters-popover .arrow {
  display: none; }

.smooth-list {
  height: 100%;
  color: #2e2e2e;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem; }
  .smooth-list.msb-list .titles-row {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: #9c9ca0;
    font-size: 11px;
    font-weight: 600;
    cursor: default;
    padding-right: 15px; }
    .smooth-list.msb-list .titles-row > div {
      border-top: 1px solid #f4f4fa;
      border-bottom: 2px solid #f4f4fa;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      font-size: 10px;
      padding: 10px 0;
      white-space: normal;
      max-height: 74px;
      text-align: center; }
      .smooth-list.msb-list .titles-row > div:hover {
        color: #9c9ca0 !important; }
        .smooth-list.msb-list .titles-row > div:hover i:before {
          border-bottom-color: #f5b717; }
        .smooth-list.msb-list .titles-row > div:hover i:after {
          border-top-color: #f5b717; }
        .smooth-list.msb-list .titles-row > div:hover .icon-filter {
          color: #9c9ca0; }
    .smooth-list.msb-list .titles-row .titles-row-title-container {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .smooth-list.msb-list .titles-row .titles-row-title-container .addons {
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer; }
        .smooth-list.msb-list .titles-row .titles-row-title-container .addons .icon-filter {
          margin: 0;
          padding-right: 5px; }
          .smooth-list.msb-list .titles-row .titles-row-title-container .addons .icon-filter.filled svg {
            fill: #009dbf; }
          .smooth-list.msb-list .titles-row .titles-row-title-container .addons .icon-filter svg {
            height: 15px;
            width: 14px;
            fill: #9c9ca0; }
            .smooth-list.msb-list .titles-row .titles-row-title-container .addons .icon-filter svg:hover {
              fill: #009dbf; }
        .smooth-list.msb-list .titles-row .titles-row-title-container .addons .sort-notificator-container {
          height: 15px;
          width: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0; }
  .smooth-list.msb-list .item-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 63px;
    padding-left: 15px;
    border-bottom: solid 1px #f4f4fa; }
    .smooth-list.msb-list .item-row:hover {
      background-color: aliceblue; }
    .smooth-list.msb-list .item-row > :first-child {
      font-weight: bold; }
    .smooth-list.msb-list .item-row .continue-subscription {
      font-size: 0.65rem; }
  .smooth-list.msb-list .list {
    left: 0; }
  .smooth-list.msb-list .list:focus {
    outline: 0; }

