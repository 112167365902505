@import '../../../../../assets/styles/_colors.scss';

.msb-activation-form-group {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 0 0 0 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  width: 100%;

  &__label {
    color: #afafb7;
    cursor: pointer;
    padding-left: 12px;
  }

  &__input-and-icon-container {
    display: flex;
  }

  &__input-and-addon-right-container {
    flex-grow: 1;
    display: flex;
    outline: 1px solid transparent;
    border-radius: 0.25rem;
  }

  &__input-container {
    flex-grow: 1;
    display: flex;
    position: relative;
  }

  &--with-error &__input-and-addon-right-container {
    outline: 1px solid #ff525a;
  }

  &--with-success &__input-and-addon-right-container {
    outline: 1px solid #10b981;
  }

  &__input-and-addon-right-container:focus-within {
    outline: 1px solid $hover-color;
  }

  &--disabled &__input-and-addon-right-container {
    outline: 1px solid #fafafa;
  }

  &__upload-button {
    color: #9ca3af;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    padding: 9px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #9ca3af;
    background: #fff;
    fill: #9ca3af;

    &:hover {
      border: 1px solid #018fa7;
      color: #018fa7;
      fill: #018fa7;
    }

    &:focus {
      outline: none;
    }
  }

  &__input {
    flex-grow: 1;
    height: 32px;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.813rem;
    font-weight: 600;
    color: #9c9ca3;
    font-family: 'Lato', sans-serif;
    background-color: $input-background;
    border: 1px solid $border-color;
    outline: none;

    &[type="number"]:disabled {
      -moz-appearance: textfield;
    }

    &:focus {
      font-weight: 400;
      color: #495057;
      background-color: #fff;
    }

    &:disabled {
      color: #9c9ca3;
      background-color: #fff;
    }
  }

  &__edit-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  &--with-addon-right &__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__addon-right {
    display: inline-flex;
    align-items: center;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 0.5rem;
    background-color: #f9fafb;
    color: #6b7280;
    height: 32px;
  }

  &__icon {
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-basis: 1.5rem;
  }

  &__helper-text {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    &--info {
      color: #9c9ca3;
    }
  }

  &--with-error &__helper-text {
    &--error {
      color: #ff525a;
    }
  }
}
