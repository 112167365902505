@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_measures.scss";

.finish-installation {
  & .grouped-list {
    align-items: baseline;
    border-bottom: 0;
    flex-wrap: wrap;
  }

  & .list-wrapper, & .custom-responsive-list {
    padding-bottom: 2rem;
  }

  & .list-wrapper {
    margin: 0;
  }

  & .custom-responsive-list {
    padding-top: 0;
  }

  & .finish-installation-button {
    border-top: 2px solid $border-color;
    flex-direction: column;
    align-items: unset;

    & .alert {
      margin: 0;
    }

    & .save-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
    }
  }

  & .summary-ucg {
    & .no-ucg-placeholder {
      & .list-placeholder {
        margin: 0;
      }
    }
  }

  & .partnerships {
    & .list-title {
      margin-bottom: 0;
    }

    & .field.row div {
      font-size: 1rem;
      font-weight: normal;
    }

    & .partnerships-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .icon-ok {
        color: $correct;
        font-size: 2rem;
      }
    }
  }

  & .devices-and-functions {
    border: 2px solid $btn-disabled-background;
    border-radius: 10px;
    margin: 0.5rem;

    & .registration-device-item {
      border-bottom: 0 !important;
    }

    & .functions {
      border-top: 2px solid $btn-disabled-background;

      & .registration-function-item {
        border-bottom: 1px solid $btn-disabled-background;

        &:last-child {
          border-bottom: 0 !important;

          & .function-container {
            border-radius: 0 0 10px 10px;
          }
        }

        & .function-container {
          padding: 0.5rem 1.5rem;

          & .device-icon {
            padding-left: 0;
          }
        }
      }
    }

    & .no-function {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      border-top: 2px solid $btn-disabled-background;

      & span {
        font-family: Quicksand, serif;
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $secondary-font;
      }
    }
  }

  .container--waiting-finalisation-inscription {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(200, 200, 200, 0.5);

    .modal--waiting-finalisation-inscription {
      position: absolute;
      top: calc(50% - 100px);
      left: calc(50% - 200px);
      width: 400px;
      height: 200px;
      background-color: white;
      border-radius: 5px;
      padding: 20px;
      font-size:1rem;

      .text--waiting-finalisation-inscription{
        padding-bottom:10px;
      }

      .spinner {
        height:50px !important;
        &:after {
          background-color: transparent;
        }
      }
    }
  }

  & .badge-signalComOne{
    position: relative;
    top:-2px;
  }
}
