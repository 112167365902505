@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/measures';

.my-smart-battery {
  height: fit-content;
  & .spinner {
    height: 100% !important;
  }
  & .table-row {
    &:last-child {
      border-bottom: 0;
    }
  }
  & .battery-documents {
    & .document-label {
      color: #afafb7;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.9rem;
      &.without-buttons {
        padding-bottom: 0;
      }
      & .additional-info {
        padding-top: 0.75rem;
      }
    }
    & .document-buttons-container {
      display: flex;
      justify-content: flex-end;
      & .document-button {
        display: flex;
        justify-content: center;
        & .download,
        & .upload {
          padding: 0.75rem;
          width: 100%;
          font-size: 0.65rem;
          color: white;
          max-width: 15rem;
        }
      }
    }
  }

}

.btn.btn-primary.remove-msb-button {
  background-color: $danger;
  color: white;
  border: solid 2px transparent;
  &.disabled {
    cursor: not-allowed;
  }
}

.battery-form {
  border: 0;
  & i {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  & .form-wrapper {
    width: 100%;
    padding: 2rem 0;
    & .input-field {
      &.custom {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.5rem;
      }
    }
  }
  & .edit-consuel-number-button,
  .edit-serial-number-button {
    padding: 0.1rem 0.25rem;
    background-color: transparent;
    border-color: $btn-background;
    cursor: pointer;
    &:hover:not(:focus):not([disabled]) {
      background-color: transparent;
      border-color: $hover-color;
      & i {
        color: $hover-color;
      }
    }
    &:active:not([disabled]) {
      background-color: transparent !important;
      border-color: $hover-color !important;
      box-shadow: none !important;
      & i {
        color: $hover-color;
      }
    }

    &:disabled {
      background-color: white;
      color: $btn-disabled-color;
      border: solid 2px $btn-disabled-background;
    }
    & .icon-profile {
      display: flex;
      font-size: 2rem;
      transform: scaleX(-1);
      color: $primary;
    }
  }
  & .new-serial-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $secondary-font;
    & .serial-number-container {
      &.is-full-width {
        & .input-field {
          width: 100%;
        }
      }
      & .input-field {
        width: calc(100% - 2.25rem);
        margin-bottom: 0 !important;
      }
      & .alert-container {
        width: 100%;
      }
      & .icon-add,
      & .icon-trash-active {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 2rem;
        color: $primary;
        cursor: pointer;
        &:hover {
          color: $hover-color;
        }
      }
      & .icon-add {
        position: absolute;
        margin-left: 0.35rem;
        &.disabled {
          color: $btn-disabled-color !important;
          cursor: not-allowed !important;
        }
      }
      & .icon-trash-active {
        position: absolute;
        margin-left: -2.15rem;
        color: $danger;
        z-index: 3;
      }
      & .edit-serial-number-button {
        position: absolute;
        margin-left: -2.55rem;
        z-index: 3;
      }
      & .new-serial-number-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        width: 100%;
        & button {
          min-width: 10rem;
          margin: 0.5rem;
        }
      }
    }
    & .alert {
      margin: 0;
    }
  }
  & .save-battery {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    & .cancel-button,
    .remove-button {
      position: absolute;
      left: 15px;
    }
    & button {
      min-width: 7rem;
    }
  }
  & .skip-msb-step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
      margin: 0 !important;
      min-width: 10rem;
    }
  }
}
.edit-serial-number-modal {
  & .input-field {
    width: 100%;
  }
  & .edit-serial-number-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    & button {
      min-width: 10rem;
      margin: 0.5rem;
    }
  }
}
.edit-consuel-number-modal {
  & .input-field {
    width: 100%;
  }
  & .info-container {
    padding-left: 1.5rem;
    margin: 0.75rem 0;
    font-size: 0.9rem;
    & ul {
      padding: 0;
      margin: 0;
      & li {
        padding-bottom: 1rem;
      }
    }
  }
  & .checkbox-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    font-size: 0.9rem;
    padding: 0 0.5rem 1rem;
    cursor: pointer;
    user-select: none;
  }
  & .save-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      margin: 0.5rem;
      padding: 0.375rem 2.25rem;
    }
  }
}
.battery-document-modal {
  & .custom-file {
    white-space: nowrap;
    & label {
      overflow: auto;
      text-overflow: ellipsis;
      padding-right: 5rem;
    }
  }
  & ul {
    margin: 0;
    & li {
      &:first-child, &:last-child {
        list-style: none;
        margin-left: -2rem;
      }
      &:first-child{
        padding-bottom: 0.2rem;
      }
      &:last-child {
        padding-top: 0.2rem;
      }
    }
  }
  & .pdf-container {
    height: 100%;
    width: 100%;
    & .pdf-inner-container {
      height: 100%;
      width: 100%;
      & .pdf-page {
        height: 100%;
        width: 100%;
        canvas {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}
