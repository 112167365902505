@import "../../assets/styles/_colors.scss";

.not-found {
  .not-found-code {
    font-size: 120pt;
    color: $primary;
  }
  .btn,
  .not-found-title,
  .not-found-code,
  .not-found-lost {
    margin: 0 auto;
  }

  .not-found-lost {
    margin-top: 50px;
  }

  .btn {
    margin-top: 30px;
  }
}
