@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/measures.scss";

.filters-popover {
  & .popover {
    margin-top: 1rem;
    margin-left: 1rem;
    border: 0;
    & .popover-body {
      padding: 0;
      border: 0;
      & ul {
        width: 8rem;
        & button {
          padding: 0.3rem 0.5rem;
          font-size: 0.85rem;
          outline: unset !important;
          &.active {
            background-color: $primary;
            border-color: $primary;
            font-weight: bold;
            font-size: 0.84rem;
          }
          & .filter-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
              font-size: 1.09rem;
              line-height: 0.7rem;
            }
          }
        }
      }
    }
  }
  & .arrow {
    display: none;
  }
}

.smooth-list {
  height: 100%;
  color: $font;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  &.msb-list {
    .titles-row {
      display: flex;
      flex-wrap: wrap;
      text-transform: uppercase;
      color: $table-head-font-color;
      font-size: 11px;
      font-weight: 600;
      cursor: default;
      padding-right: 15px;
      > div {
        border-top: 1px solid $border-color;
        border-bottom: 2px solid $border-color;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        font-size: 10px;
        padding: 10px 0;
        white-space: normal;
        max-height: 74px;
        text-align: center;
        &:hover {
          color: $table-head-font-color !important;
          & i:before {
            border-bottom-color: $hover-color;
          }
          & i:after {
            border-top-color: $hover-color;
          }
          & .icon-filter {
            color: $table-head-font-color
          }
        }
      }
      .titles-row-title-container {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .addons {
          align-items: center;
          flex-direction: row;
          display: flex;
          justify-content: center;
          width: 100%;
          cursor: pointer;
          & .icon-filter {
            margin: 0;
            padding-right: 5px;
            &.filled {
              svg {
                fill: $primary
              }
            }
            svg {
              height: 15px;
              width: 14px;
              fill: $table-head-font-color;
              &:hover {
                fill: $primary
              }
            }
          }
           & .sort-notificator-container {
             height: 15px;
             width: 8px;
             display: flex;
             justify-content: center;
             align-items: center;
             margin: 0;
           }
        }

      }
    }

  .item-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 63px;
    padding-left: 15px;
    &:hover {
      background-color: aliceblue;
    }
    border-bottom: solid 1px $border-color;
    > :first-child {
      font-weight: bold;
    }
    & .continue-subscription {
      font-size: 0.65rem;
    }
  }
    .list {
      left: 0;
    }
  .list:focus {
    outline: 0;
  }
  }
}

