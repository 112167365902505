@import "./_colors.scss";
@import "../../assets/styles/_measures.scss";

body {
  background: transparent !important;
}

.alert {
  font-family: "Lato", sans-serif;
}

.table-responsive thead tr th {
  vertical-align: middle;
}

.form-control {
  border-radius: 5px;
  border: solid 1px $input-background;
  background-color: $input-background;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $input-background inset;
  }
  &:hover:not([disabled]), &:focus:not([disabled]) {
    cursor: pointer;
    border: solid 1px $hover-color;
    box-shadow: none;
  }
  &.is-invalid:focus {
    box-shadow: none;
  }
}
span.input-group-text {
  background: $input-background;
}
.form-control[disabled] {
  background-color: $background;
  border: solid 2px $input-background;
}

.btn {
  white-space: normal;
  i {
    vertical-align: -1px;
  }
  text-transform: uppercase;
  &.btn-primary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: $background;
    background-color: $btn-background;
    border: solid 2px transparent;
    &:hover:not(:focus):not([disabled]) {
      background-color: $hover-color;
      border-color: transparent;
      cursor: pointer;
    }
    &:disabled {
      background-color: white;
      color: $btn-disabled-color;
      border: solid 2px $btn-disabled-background;
    }
    &:active:not([disabled]) {
      color: $background !important;
      background-color: $hover-color !important;
      border-color: $hover-color !important;
      box-shadow: none !important;
    }
  }
  &.btn-secondary {
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    font-weight: 900;
    text-align: center;
    color: $btn-background;
    background-color: $secondary-btn;
    border: none;
    &:hover:not([disabled]):not(:focus) {
      cursor: pointer;
      color: $background;
      background-color: $hover-color;
    }
    &:disabled {
      background-color: white;
      color: $btn-disabled-color;
      border: solid 2px $btn-disabled-background;
    }
    &:active:not([disabled]) {
      color: $background !important;
      background-color: $hover-color !important;
      border-color: $hover-color !important;
      box-shadow: none !important;
    }
  }
  &:focus:not([disabled]) {
    box-shadow: none;
  }
}

.badge {
  padding: 5px 8px;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  label {
    vertical-align: -1px;
    padding: 0;
    margin: 0;
  }
}

.badge-success {
  background-color: #7ed321;
}

.badge-error {
  background-color: #ff525a;
}

.badge-secondary {
  background-color: #9b9b9b;
}

.badge-error {
  background-color: $danger;
}

.right {
  text-align: right;
}

.btn {
  &.add,
  &.remove {
    line-height: 16px;
    padding: 6px;
    min-width: 10rem;
    display: flex;
    justify-content: space-around;

    &:before {
      content: "\F101";
      font-family: icons !important;
      font-size: 21px;
      vertical-align: -1px;
      font-weight: normal;
    }
  }

  &.remove:before {
    transform: rotateZ(45deg);
  }
}

.btn-ico {
  padding: 3px 5px;
  i {
    font-size: 20px;
  }
}

.text {
  &-primary {
    color: $primary!important;
  }

  &-danger {
    color: $danger!important;
  }

  &-correct {
    color: $correct!important;
  }

  &-warning {
    color: $warning!important;
  }
}

.invalid-feedback{
  font-size:100%;
}

html[lang=fr]{
  .custom-file-label::after{
    content:'Parcourir'
  }
}

html[lang=fr-FR]{
  .custom-file-label::after{
    content:'Parcourir'
  }
}

html[lang=en]{
  .custom-file-label::after{
    content:'Browse'
  }
}
