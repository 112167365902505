@import '../../../assets/styles/colors';

.ucg-preconfiguration-modal{
  .table-row{
    height: auto;
  }

  & .ucg-preconfiguration-summary{
    .alert:last-child{
      margin-bottom: 0;
    }
  }

  & .ucg-preconfiguration-view{
    .spinner.loading{
      margin: 1rem 0;
    }
  }

  & .devices-and-functions {
    border: 2px solid $btn-disabled-background;
    border-radius: 10px;
    margin: 0.5rem;

    & .registration-device-item {
      border-bottom: 0 !important;
    }

    & .functions {
      border-top: 2px solid $btn-disabled-background;

      & .registration-function-item {
        border-bottom: 1px solid $btn-disabled-background;

        &:last-child {
          border-bottom: 0 !important;

          & .function-container {
            border-radius: 0 0 10px 10px;
          }
        }

        & .function-container {
          padding: 0.5rem 1.5rem;

          & .device-icon {
            padding-left: 0;
          }
        }
      }
    }

    & .no-function {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      border-top: 2px solid $btn-disabled-background;

      & span {
        font-family: Quicksand, serif;
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $secondary-font;
      }
    }
  }

  .btn-cancel-preconfiguration, .btn-apply-preconfiguration{
    margin-left:10px;
  }

  .list-wrapper{
    margin: 1rem 0;
  }
}


