@import "../../../../assets/styles/_colors.scss";

$padding: 16.5px;
.collapsible {
  margin-right: -15px;
  margin-left: -15px;
  .error {
    color: $danger;
  }
  .category {
    color: #a3a3a3;
  }
  .slot {
    background-color: #fbfbfd;
    padding: 5px;
    .frag {
      background-color: white;
      margin: 5px;

      padding-top: 5px;
      padding-bottom: 5px;
    }
    .slotTitle {
      color: #a0a0a0;
      font-size: 13px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
.collapse-header {
  border-bottom: solid 1px #e7e7f2;
  border-top: solid 1px #e7e7f2;
  color: $font;
  padding: $padding;
  text-decoration: none;
  .bold {
    font-weight: bold;
  }
}

.collapsible-header {
  cursor: pointer;
}
