@import '../../../../assets/styles/_measures.scss';

.modalContainer {
  @media screen and (min-width: $breakpoint_mobile) {
    width: fit-content;
  }

  & .modalInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 8rem;
    padding: 0.75rem 0;
    @media screen and (min-width: $breakpoint_mobile) {
      min-width: 23rem;
      padding: 0;
    }
  }
  & .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    button {
      &:first-child {
        padding: 0.375rem 1.25rem;
      }
      &:last-child {
        margin: 0.75rem;
      }
      @media screen and (max-width: $breakpoint_mobile) {
        &:first-child {
          padding: 0.75rem 1.75rem;
        }
        &:last-child {
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }
}
