@import '../../../assets/styles/_colors.scss';

.ucg-hint-button {
  margin: 0 10px;
}

.ucg-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}