@import "./../../../assets/styles/colors";
@import "./../../../assets/styles/measures";

.sidebar-guide {
    position: absolute;
    top: 71px;
    right: -205px;
    display: flex;
    flex-direction: row;
    transition: all 0.25s ease-in-out;
    filter: drop-shadow(rgba($font, 0.15) 0 0 3px);

    @media (min-width: $breakpoint_safe_min) {
        top: 15px;
    }
  
    &:hover {
      right: 0;
    }
  
    &__icon-container {
      width: 50px;
      height: 48px;
      padding: 10px 15px 10px 10px;
      background-color: $background;
      border-radius: 50% 0 0 50%;
      cursor: pointer;
    }
  
    &__icon {
      font-size: 28px;
      line-height: 28px;
    }
  
    &__items {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin: 0;
      padding: 12px;
      list-style: none;
      gap: 15px;
      border-radius: 0 0 0 2px;
      background-color: $background;
    }
  
    &__item {
      width: 100%;
    }
  
    &__item-link {
      text-decoration: none;
      transition: all 0.15s ease-in-out;
      color: $font;
  
      &:hover {
        text-decoration: none;
        color: $hover-color;
      }
    }
  }