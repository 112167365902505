@import "../../assets/styles/_colors.scss";

.svg-icons-refresh {
  path {
    fill: $primary;
  }

  width: 18px;
  min-width: 18px;

  &.active {
    path {
      fill: $primary;
    }
  }

  g.loading {
    transform-origin: center;
    animation: lds 1.2s infinite;
  }

  g {
    transition: 0.5s;
    transform-origin: center;
    transform: rotate(0deg);
  }
}


@keyframes lds {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
