@font-face {
    font-family: "icons";
    src: url("./icons.eot?74b389c58e99326d593d5c48a36e94ae?#iefix") format("embedded-opentype"),
url("./icons.woff2?74b389c58e99326d593d5c48a36e94ae") format("woff2"),
url("./icons.woff?74b389c58e99326d593d5c48a36e94ae") format("woff"),
url("./icons.ttf?74b389c58e99326d593d5c48a36e94ae") format("truetype"),
url("./icons.svg?74b389c58e99326d593d5c48a36e94ae#icons") format("svg");
}

i {
    line-height: 1;
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
    content: "\f101";
}
.icon-arrow:before {
    content: "\f102";
}
.icon-calendar-active:before {
    content: "\f103";
}
.icon-calendar:before {
    content: "\f104";
}
.icon-call-active:before {
    content: "\f105";
}
.icon-call:before {
    content: "\f106";
}
.icon-client-active:before {
    content: "\f107";
}
.icon-client:before {
    content: "\f108";
}
.icon-company-active:before {
    content: "\f109";
}
.icon-company:before {
    content: "\f10a";
}
.icon-cross:before {
    content: "\f10b";
}
.icon-dashboard-active:before {
    content: "\f10c";
}
.icon-dashboard:before {
    content: "\f10d";
}
.icon-list:before {
    content: "\f10e";
}
.icon-map-active:before {
    content: "\f10f";
}
.icon-map:before {
    content: "\f110";
}
.icon-menu:before {
    content: "\f111";
}
.icon-not-active:before {
    content: "\f112";
}
.icon-not:before {
    content: "\f113";
}
.icon-notification-active:before {
    content: "\f114";
}
.icon-notification:before {
    content: "\f115";
}
.icon-ok-active:before {
    content: "\f116";
}
.icon-ok:before {
    content: "\f117";
}
.icon-profile-active:before {
    content: "\f118";
}
.icon-profile:before {
    content: "\f119";
}
.icon-search-active:before {
    content: "\f11a";
}
.icon-search:before {
    content: "\f11b";
}
.icon-support-active:before {
    content: "\f11c";
}
.icon-support:before {
    content: "\f11d";
}
.icon-tool-active:before {
    content: "\f11e";
}
.icon-tool:before {
    content: "\f11f";
}
.icon-trash-active:before {
    content: "\f120";
}
.icon-trash:before {
    content: "\f121";
}
.icon-zoom:before {
    content: "\f122";
}


