@import "../../../assets/styles/_colors.scss";

$fixedHeaderHeight: 70px;
.fixed-header {
  height: $fixedHeaderHeight;
  .status {
    line-height: $fixedHeaderHeight;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name {
    font-weight: 600;
    font-size: 20px;
  }
  .tenant-info {
    position: absolute;
    right: 20px;
    border-radius: 6px;
  }
  .logo {
    padding: 6px;
    height: 32px;
    display: block;
    margin: auto;
  }
  .loginAs-link:before {
    content: " ";
    background-color: $main-menu-background;
    width: 2px;
    position: relative;
    top: 6px;
    height: 20px;
    display: inline-block;

    margin-right: 6px;
  }

  .loginAs-link {
    font-size: 14px;
    padding-right: 6px;
    color: $primary;
  }
}
