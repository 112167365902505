@import "../../assets/styles/_colors.scss";

.input-field.select-input {
  &.with-error {
    &.with-unit {
      & .input-group {
        border: 1px solid #dc3545;
        border-radius: 5px;
      }
    }
    & .custom-select-input__control {
      border: 1px solid #dc3545 !important;
    }
    & .custom-select-input__dropdown-indicator svg {
      color: #dc3545 !important;
    }
    & .custom-error {
      margin: 0.25rem 0 0 0.5rem;
      color: #dc3545;
    }
    & .input-group-text {
      border: none;
      border-left: 1px solid #ced4da;
    }
  }

  & .input-group-append {
    z-index: 1;
    & .input-group-text {
      border-radius: 0px 5px 5px 0px;
    }
  }
  & .custom-select-input {
    flex-grow: 1;
    top: auto;
    bottom: 100%;
    outline: none;
    .custom-select-input__control {
      border-radius: 5px;
      border: solid 1px transparent;
      background-color: $input-background;
      box-shadow: none !important;
      &:hover {
        cursor: pointer;
        border-color: transparent;
        .custom-select-input__dropdown-indicator svg {
          color: $hover-color;
        }
      }
      &.custom-select-input__control--is-disabled {
        background-color: $background;
        border: solid 2px $input-background;
      }
      &.custom-select-input__control--is-focused {
        border-color: transparent;
      }
    }

    .custom-select-input__value-container--has-value {
      .custom-select-input__single-value {
        color: $font;
      }
    }

    .custom-select-input__indicator-separator {
      visibility: hidden;
    }

    .custom-select-input__dropdown-indicator svg {
      color: $primary;
    }


    .custom-select-input__menu {
      margin-top: 5px;
      z-index: 1000;
      .custom-select-input__menu-list {
        .custom-select-input__option {
          cursor: pointer;
          font-size: 13px;
          line-height: 25px;
          color: $font;
        }
        .custom-select-input__option--is-focused{
         background-color: $input-background;
        }
        .custom-select-input__option--is-selected {
          background-color: #ddd;
        }
        .custom-select-input__menu-notice {
          color: $font;
        }
      }
    }
  }
}
