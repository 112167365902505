@import '../../../assets/styles/colors.scss';
.barcode-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  & .barcode-title {
    font-family: Quicksand, serif;
    padding: 1rem;
    text-align: center;
  }
  & .barcode-result {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    & .barcode-code {
      flex-grow: 1;
      & .code-correct {
        color: limegreen;
      }
      & .code-wrong {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $warning;
        font-weight: bold;
        & span:first-child{
          font-size: 0.9rem;
          font-family: Quicksand, serif;
          color: $secondary-font;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }
  }
  & .barcode-rescan, & .barcode-stop {
    padding: 1rem;
  }

  & .barcode-stop {
    padding-top: 2rem;
  }

  & #cont {
    width: 100%;
    overflow: hidden;
    padding: 1rem 0;
    max-height: 60vh;
    & video, & canvas {
      width: 100%;
    }
    & canvas {
      position: absolute;
      max-height: 60vh;
    }
  }
}
