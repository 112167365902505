.strasbourg-electricity-administrative-procedure-text {
  font-size: 0.75rem;
  text-transform: uppercase;

  &--fill {
    color: #ff8b03;
  }

  &--inProgress {
    color: #ff8b03;
  }

  &--toValidate {
    color: #0ea5e9;
  }

  &--finished{
    color: #7ed321;
  }
}
