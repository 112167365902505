@import '../../../../../assets/styles/_colors.scss';

.row-consuel-file {
  align-items: center;
  padding: 0 0 0 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  width: 100%;

  &__label {
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: $secondary-font;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__file{
      flex-grow: 1;
      &__icon {
        flex-shrink: 0;
        margin-right:10px
      }

      &__text {
        flex-basis: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        with-space: nowrap;
        margin-right:10px;
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }

  &__check-icon {
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-basis: 1.5rem;
  }
}
