@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";
@import "../../assets/styles/_fonts.scss";

$marginSize: 20px;

.login-box {
  background-color: $background;
  width: 100%;
  max-width: 500px;
  max-height: 600px;
  box-shadow: 2px 0 52.4px 15.6px rgba(3, 0, 69, 0.1);
  & .container {
    height: 100%;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    & .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      &.logo-mylight {
        height: 10rem;
      }
      & .logo-wrapper {
        display: block;
        max-width: 230px;
      }
    }
    & .login-box-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      & .password-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .form-group {
          margin: 0;
          flex-grow: 1;
        }
        & button {
          height: 36px;
          border-radius: 5px;
          border: solid 1px transparent;
          margin-left: 1rem;
          margin-top: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .login-box {
    width: calc(100% - #{(2 * $marginSize)});
  }
}
