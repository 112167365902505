@import "../../../assets/styles/colors";

.container-qualitySignal{
  margin-bottom: 20px;
}

.text-align-right{
  text-align: right;
}

.text-qualitySignal{
  margin-left: 10px;
  margin-right: 5px;
  color: $secondary-font;
}

.badge-signalComOne{
  position: relative;
  top:-1px;
}
