$primary: #009dbf;

$secondary-btn: #efeff5;

$danger: #ff525a;

$background: #ffffff;

$input-background: #f4f4fa;

$menu-color: #f4f4fa;

$border-color: #f4f4fa;

$secondary-font: #afafb7;

$hover-color: #f5b717;

$font: #2e2e2e;

$btn-background: #009dbf;

$btn-disabled-background: #e7e7f2;

$btn-disabled-color: #d3d3dd;

$table-head-font-color: #9c9ca0;

$main-menu-background: #e7e7f2;

$link-hover-color: #0056b3;

$correct: #7ed321;

$warning: #ffc107;

$select-text-inactive-color: #006f87;

$neutral: #9b9b9b;

$planete-oui: #12b24b;