@import '../../../assets/styles/_colors.scss';

.start-configuration-assistance {
  display: flex;
  flex-direction: column;
  &.table-row {
    border: 0;
  }

  & .start-configuration-assistance-container {
    flex-grow: 1;
    height: 3rem;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 10px;
    margin: 0.75rem 0 0 0;
    background-color: $primary;

    &:hover {
      border-color: $hover-color;
      background-color: $hover-color;
    }
    & span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
      color: #ffffff !important;
    }
  }
}

.add-new-device {
  & .add-device-button-container {
    flex-grow: 1;
    height: 3rem;
    border: 1px solid $primary;
    border-radius: 10px;
    margin: 0.75rem 0;
    &:hover {
      border-color: $hover-color;
      & .add-device {
        color: $hover-color;
      }
    }
    & span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
    }
    & .add-device {
      font-size: 1.75rem;
      color: #009dbf;
      cursor: pointer;
    }
  }
}
.refresh-button-container,
.add-device-button-container,
.start-configuration-assistance-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &:hover {
    span {
      color: $hover-color;
    }
  }
  span {
    font-size: 0.75rem;
    color: $primary;
    padding-left: 0.5rem;
  }
}

.save-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.registration-device-item {
  flex-wrap: nowrap !important;
  align-items: unset;
  border-bottom: 2px solid #f4f4fa !important;
  &:hover {
    & .delete-button {
      &.deleted {
        opacity: 0.85;
      }
    }
  }
  & .device-container {
    flex-grow: 1;
    display: flex;
    &:hover {
      & .edit-button {
        & i {
          color: $hover-color;
        }
      }
    }
    & .device-info {
      flex-grow: 1;
      padding: 1rem;
      &.modbus {
        padding: 1.594rem 1rem;
      }
      & .device-title {
        align-items: center;
        flex-wrap: nowrap;
        & .tenant-logo {
          height: 3rem;
          width: 3rem;
          & .customer-tenant-logo {
            max-height: 3rem;
          }
        }
        & .device-name {
          color: $font;
          padding-bottom: 0.25rem;
          font-weight: 600;
          font-family: Quicksand, serif;
          font-size: 0.86rem;
        }
        & .room-name {
          font-family: Quicksand, serif;
        }
      }
      & .device-details {
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
        font-family: Quicksand, serif;
        span {
          font-size: 0.75rem;
        }
        & .activation-code,
        & .mac {
          padding-right: 0.4rem;
        }
      }
    }
    & .edit-button {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      & .icon-full-arrow-right {
        margin: 0px 10px 5px 0;
      }
    }
    & .modbus-disconnected {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      & .fa-exclamation-circle {
        color: $danger;
        font-size: 1.225rem;
        margin-bottom: 5px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
  & .delete-button {
    padding: 0 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: $danger;
    &:hover {
      opacity: 0.85;
    }
    &.deleted {
      background-color: $primary;
      & .trash {
        color: white;
      }
    }
    & .trash {
      color: $background;
      font-size: 30px;
    }
  }
}
