@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/measures.scss";

.filters-popover {
  & .popover {
    margin-top: 1rem;
    margin-left: 1rem;
    border: 0;
    & .popover-body {
      padding: 0;
      border: 0;
      & ul {
        width: 8rem;
        & button {
          padding: 0.3rem 0.5rem;
          font-size: 0.85rem;
          outline: unset !important;
          &.active {
            background-color: $primary;
            border-color: $primary;
            font-weight: bold;
            font-size: 0.84rem;
          }
          & .filter-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
              font-size: 1.09rem;
              line-height: 0.7rem;
            }
          }
        }
      }
    }
  }
  & .arrow {
    display: none;
  }
}
.smooth-list {
  height: 100%;

  color: $font;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;

  .titles-row {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: $table-head-font-color;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    padding-left: 0;
    > div {
      padding: 15px 0;
      border-top: 1px solid $border-color;
      border-bottom: 2px solid $border-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 54px;
      &:hover {
        color: $hover-color;
        & i:before {
          border-bottom-color: $hover-color;
        }
        & i:after {
          border-top-color: $hover-color;
        }
        & .icon-filter {
          color: $table-head-font-color
        }
      }
    }

    .titles-row-title-container {
      display: flex;
      align-items: center;
      height: 21px;
      padding: 0 15px;
      & :first-child {
        justify-content: center;
      }
      .titles-row-title {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &  .icon-filter {
        margin-right: 0.35rem;
        &.filled {
          svg {
            fill: $primary
          }
        }
        svg {
          height: 21px;
          width: 13px;
          fill: $table-head-font-color;
          &:hover {
            fill: $primary
          }
        }
      }
      .sort-notificator-container {
        margin-left: 5px;
      }
    }
  }
  .item-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 63px;
    &:hover {
      background-color: aliceblue;
    }
    border-bottom: solid 1px $border-color;
    > :first-child {
      font-weight: bold;
    }
    & .continue-subscription {
      font-size: 0.65rem;
    }
  }
  .list {
    left: -15px;
  }
  .list:focus {
    outline: 0;
  }
}

@media screen and (max-width: $breakpoint) {
  .smooth-list {
    .list {
      left: 0;
    }
    .item-row {
      height: 107px;
      &:hover {
        background-color: transparent;
      }
      padding-top: 15px;
      padding-bottom: 15px;
      margin-left: 0;
    }
  }
}
