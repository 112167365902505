@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_measures.scss';

.my-smart-battery-container {
  height: 100%;
  overflow: hidden;
  & .my-smart-battery-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $main-menu-background;
    width: 100%;
    padding: 0 $padding-rem;
    height: 100px;
    border-bottom: 1px solid white;
    & .my-smart-battery-tenant-logo {
      height: 46px;
      & img {
        height: 100%;
      }
    }
    & .my-smart-battery-logout-icon {
      &:hover {
        cursor: pointer;
        & svg {
          & path, line {
            stroke: $primary
          }
        }
      }
      & svg {
        height: 1.5rem;
        width: 2.5rem;
      }
    }
  }
  & .my-smart-battery-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $menu-color;
    height: $customers-header-height;
    width: 100%;
    padding: 0 1rem;
    &.read-only {
      justify-content: space-between;
      padding: 0 $padding-rem;
      & .my-smart-battery-header-title {
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 600;
        font-family: Quicksand, serif;
      }
      & .my-smart-battery-header-search {
        position: relative;
        top: unset;
        right: unset;
      }
    }
    & .my-smart-battery-type-toggle {
      display: flex;
      flex: 1;
      & .type-toggle-container {
        width: 100%;
        height: fit-content;
        padding: 0.25rem;
        font-size: 0.75rem;
        background-color: white !important;
        color: $secondary-font;
        border: 1px solid transparent;
        border-radius: 0;
        &.mylight-energy {
          margin-right: 1rem;
          &:hover {
            border: 1px solid $primary !important;
            color: $secondary-font !important;
          }
          &.selected {
            border: 1px solid $primary !important;
            color: $primary !important;
          }
        }
        &.planete-oui {
          &:hover {
            border: 1px solid $planete-oui !important;
            color: $secondary-font !important;
          }
          &.selected {
            border: 1px solid $planete-oui !important;
            color: $planete-oui !important;
          }

        }
        & .type-toggle-inner-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.25rem 0.5rem;
          & :first-child {
            margin-right: 0.25rem;
          }
        }
      }
    }
    & .my-smart-battery-header-search {
      position: absolute;
      // search input height / 2
      top: $main-menu-content-header-height / 2 - 28px /2;
      right: 1rem;
      z-index: 3;
      .btn.search {
        background-color: white;
        color: $primary;
        margin-right: 7.5px;
      }
    }
    & .my-smart-battery-header-reset-filters {
      margin-left: 1rem;
      display: none;
    }
  }
  & .my-smart-battery-inner-container {
    height: calc(100% - #{$customers-header-height});
    &.read-only {
      height: calc(100% - #{$customers-header-height} - 100px);
      & .full-page-list {
        height: 100%;
      }
    }
    & .my-smart-battery-list {
      &.desktop {
        & .icon {
          display: flex;
          justify-content: center;
        }
        & .list-item {
          text-align: center;
          &.ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              overflow: visible;
              text-overflow: unset;
            }
          }
        }
      }
      &.mobile {
        & .list-item-name {
          font-size: 0.9rem;
          padding-right: 0.5rem;
          color: #9b9b9b;
          font-family: Quicksand, sans-serif;
        }
        & .list-item-value {
          display: flex;
          justify-content: flex-end;
          font-family: Lato, sans-serif;
          font-weight: bold;
        }
        & .valid div {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_large_safe_min) {
  & .my-smart-battery-container {
    & .my-smart-battery-page-header {
      padding: 3rem 1rem;
    }
    & .my-smart-battery-header {
      &.read-only {
        padding: 0 1rem;
      }
    }
  }
}

@media (max-width: $breakpoint_safe_min) {
  & .my-smart-battery-header {
    padding: 0 .25rem !important;
    &.read-only {
      padding: 0 1rem !important;
      & .my-smart-battery-header-search {
        position: relative !important;
        top: unset !important;
        right: unset !important;
      }
    }
    & .my-smart-battery-type-toggle {
      & .type-toggle-container {
        &.mylight-energy {
          margin-right: 0.25rem !important;
        }
      }
    }
    & .my-smart-battery-header-search {
      top: $main-menu-content-header-height-mobile / 2 - 28px /2 !important;
      right: 0.5rem !important;
    }
  }
}

@media (min-width: $breakpoint_safe_min) {
  & .my-smart-battery-container {
    & .my-smart-battery-header {
      & .my-smart-battery-header-reset-filters {
        display: block;
      }
    }
  }
}

@media (min-width: $breakpoint) and (max-width: $breakpoint_large) {
  & .my-smart-battery-container {
    & .my-smart-battery-inner-container {
      & .my-smart-battery-list {
        .smooth-list {
          .list {
            left: 0;
          }
          .item-row {
            height: 107px;
            &:hover {
              background-color: transparent;
            }
            padding-top: 15px;
            padding-bottom: 15px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
