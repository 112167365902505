@import "../../../../assets/styles/_colors.scss";

.send-inscription-summary {
  font-size: 0.85rem;
  text-align: justify;
  & .send-inscription-summary-title {
    color: $primary;
    font-weight: bold;
  }
  & .send-inscription-summary-info {
    margin-top: 5px;
    color: $secondary-font;
  }
  & .send-inscription-summary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 15px 0;
  }
}