.alert-translated-content {
  display: flex;
  flex-direction: column;

  .alert-translated-tip {
    font-weight: bold;
    margin: 10px;
  }
  a {
    color: white;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
}