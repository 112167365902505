@import "../../assets/styles/_colors.scss";

.spinner{
  height: 100%;
}

.spinner.loading,
.spinner.error {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);

    position: absolute;
    top: 0;
  }
  &.transparent {
    &::after {
      background-color: transparent;
    }
  }
}

.spinner.loading::before {
  content: " ";
  -webkit-transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  margin: -25px auto auto -25px;
  left: 50%;
  top: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: $primary transparent transparent transparent;
  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner.loading,
.spinner.error {
  min-height: 50px;
  position: relative;
  :first-child {
    opacity: 0;
  }
}
.spinner.error {
  label {
    position: absolute;
    top: 15px;
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
