@import "../../assets/styles/_colors.scss";

.input-field {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 5px;
  &.with-error {
    &.with-unit {
      & .input-group {
        border: 1px solid #dc3545;
        border-radius: 5px;
      }
    }
    & .input-number {
      &.with-error {
        border: 1px solid #dc3545;
        border-radius: 5px;
      }
      &.with-unit {
        border: unset;
      }
    }
    & .custom-error {
      margin: 0.25rem 0 0 0.5rem;
      color: #dc3545
    }
  }

  .helper-text {
    margin: 0.25rem 0 0 0.5rem;
    color: $warning;
  }

  & .input-group {
    input.is-invalid.without-error-icon {
      background-image: none;
    }
    > label.error-message {
      color: $danger;
      font-style: italic;
      font-size: 0.8rem;
      font-weight: bold;
      padding-top:5px;
    }
  }
  > label {
    margin-left: 10px;
  }
  > label.input-title {
    color: #afafb7;
    cursor: pointer;
    width: 100%
  }
  > label.error-message {
    color: $danger;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: bold;
    padding-top:5px;
  }
  .clearable:after {
    content: " x ";
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  &:not(:first-child){
    padding-top:10px;
  }
}
