@import "../../../assets/styles/colors";
@import "../../../assets/styles/measures";
.wrapper{
  display: flex;
  justify-content:space-between ;
  align-items: center;
  padding: 33px ;
  margin : 15px;
  border-bottom: 2px solid $border-color;

  @media (max-width: $breakpoint) {
    flex-direction: column;
  }
}
.text{
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px ;
  text-align: center;

}
.link{
  text-transform: uppercase;
}
.image-box{
  img{
    height:40px
  }

}
