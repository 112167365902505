.enedis-grid-injection-text {
  font-size: 0.75rem;
  text-transform: uppercase;

  &--fill {
    color: #ff8b03;
  }

  &--inProgress {
    color: #ff8b03;
  }

  &--toValidate {
    color: #0ea5e9;
  }

  &--requested {
    color: #7ed321;
  }

  &--enabled {
    color: #7ed321;
  }
}

.enedis-grid-injection-description {
  color: #afafb7;
  font-size: 0.813rem;
  padding: 0.5rem 1rem;
  line-height: 1.125rem;
}
