@import "../../../assets/styles/_measures.scss";
@import "../../../assets/styles/_colors.scss";

$name-text-size: 13px;
$item-text-size: 12px;
$list-item-padding: 19px;
$margin: 20px;
.custom-responsive-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  color: $font;
  font-family: Lato, sans-serif;
  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    min-height: 3.5rem;
    font-size: 1rem;
    font-weight: 900;
    border-bottom: $border-color solid 2px;
    margin-bottom: 0.75rem;
  }

  .big-text {
    font-family: Lato, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  .field {
    margin: 0.75rem 0;
    label {
      margin: 0;
    }
    .name {
      line-height: $name-text-size;
      font-size: $name-text-size;
      color: $secondary-font;
    }
    .text,
    .email,
    .phone,
    .address {
      font-weight: 600;
      line-height: $item-text-size;
      font-size: $item-text-size;
      font-family: Lato, sans-serif;
    }

    .text {
      & > .badge{
        position: relative;
        bottom:3px;
      }

      & > .text-warning {
        line-height: 1rem;
      }
    }
    .email,
    .phone,
    .address {
      a {
        color: $font;
      }

      text-decoration: none;

      :hover:not(:focus) {
        text-decoration: none;
        color: $hover-color;
      }
    }
    .address {
      line-height: 1rem;
    }
    &.field-with-error {
      margin: 1.5rem 0 !important;
      div {
        color: $font;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 900;
        line-height: normal;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 3rem;
        margin: 0;
      }
      .value {
        font-size: 22px;
      }
      .field-error-icon {
        margin-left: 0.5rem;
      }
    }
  }
}

@media(max-width: $breakpoint){
  .custom-responsive-list {
    & .field {
      & .name {
        margin-bottom: 0.5rem;
      }
      & .address {
        line-height: $item-text-size;
      }
    }
  }
}
