@import "../../assets/styles/_colors.scss";

.custom-tooltip-container {
  opacity: 1;
  &.is-warning {
    & .tooltip {
      .tooltip-inner {
        background-color: $warning;
        color: $font;
      }
    }
  }
  & .tooltip {
    .tooltip-inner {
      padding: 21px 19px;
      background-color: $primary;
      color: white;
      max-width: 300px;
      box-shadow: 2px 0 52.4px 15.6px rgba(3, 0, 69, 0.1);
    }
    &.bs-tooltip-left .arrow::before {
      border-color: transparent;
      border-left-color: $primary;
    }
    &.bs-tooltip-right .arrow::before {
      border-color: transparent;
      border-right-color: $primary;
    }
    &.bs-tooltip-bottom .arrow::before {
      border-color: transparent;
      border-bottom-color: $primary;
    }
    &.bs-tooltip-top .arrow::before {
      border-color: transparent;
      border-top-color: $primary;
    }

    .custom-tooltip-container-body {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .custom-tooltip-complement-switch {
        font-weight: bold;
        margin-top: 10px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .custom-tooltip-complement-msg {
        margin-top: 10px;
      }
    }
  }

}
