//@import "_colors.scss";
//
.react-datepicker-wrapper,.react-datepicker__input-container{
  width: 100%;
}

//.react-datepicker__header {
//  background: $primary !important;
//  color: white !important;
//}
//.react-datepicker__month-option {
//  background: $primary !important;
//  color: white !important;
//  &:hover {
//    background: $hover-color !important;
//  }
//}
//span.react-datepicker__year-read-view--down-arrow {
//  border: none !important;
//}
//
//.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown,
//span.react-datepicker__month-read-view--down-arrow,
//.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
//  display: none;
//}
//.react-datepicker__day-name {
//  color: white !important;
//}
//.react-datepicker__month {
//  background: $primary !important;
//  color: white !important;
//}
//
//.react-datepicker__year-option,
//.react-datepicker__month-option,
//.react-datepicker__month-year-option {
//  background: $primary !important;
//  color: white !important;
//}
//
//.react-datepicker {
//  background: $primary !important;
//  color: white !important;
//}
//.react-datepicker__triangle {
//  border-top-color: $primary !important;
//}
//.react-datepicker__day.react-datepicker__day--weekend {
//  color: white !important;
//}
//
//.react-datepicker__day {
//  color: white !important;
//}
//.react-datepicker__day:hover {
//  color: $primary !important;
//  background: white !important;
//  border-radius: 50px;
//}
//
//button.react-datepicker__navigation.react-datepicker__navigation--previous {
//  &:hover {
//    border-right-color: white !important;
//  }
//  border-right-color: white !important;
//}
//button.react-datepicker__navigation.react-datepicker__navigation--next {
//  &:hover {
//    border-left-color: white !important;
//  }
//  border-left-color: white !important;
//}
//.react-datepicker-wrapper,
//.react-datepicker__input-container {
//  width: 100%;
//}
