@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_measures.scss";

$size: 20px;
.stepper {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 3px;
  background-color: #f4f4fa;
  text-align: center;
  & .stepper-progress {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4fa;
    & .progress {
      width: 100%;
      height: 100%;
      border-radius: 0;
      & .progress-bar {
        background-color: $primary;
      }
    }
  }
  .stepper-title {
    color: $font;
    font-size: 20px;
    font-weight: 600;
  }
}
