@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_measures.scss";

.device-details-container {
  height: 100%;
  align-items: center;
  padding: 4rem 0;
  min-height: 40rem;
  & .device-details {
    display: flex;
    flex-direction: column;
    & .input-field.custom {
      button {
        margin: 0.5rem;
        &:first-child{
          margin-left:0;
        }
      }
    }
  }
  & .device-help {
    max-height: 100%;
    overflow: auto;
  }
  & .device-image {
    height: 20rem;
    width: 20rem;
    margin: 0 auto 1rem auto;
    &.device-description {
      margin: 1rem auto 2rem auto;
    }
    &.gmd {
      width: 14rem;
    }
    &.sw, &.eth, &.tph, &.mst, &.sw1, &.mst1 {
      height: 15rem;
      width: 21rem;
    }
    &.gmdtri1, &.gmdmono1, &.mst_g3mono3, &.mst_g3tri3 {
      height: 15rem;
      width: 26rem;
    }
    &.gmdtri2, &.gmdmono2 {
      height: 15rem;
      width: 15rem;
    }
    &.eth1 {
      height: 14rem;
      width: 15rem;
    }
    &.eth2, &.sw2, &.mst2 {
      height: 12rem;
      width: 28rem;
    }
    &.rs485_mc1, &.rs485_mc3 {
      height: 19rem;
      width: 12rem;
    }
    &.rs485_mc11, &.rs485_mc12, &.rs485_mc31, &.rs485_mc32{
      display: none;
    }
    &.mst_g3mono1, &.mst_g3tri1, &.mst_g3mono5, &.mst_g3tri5 {
      display: none;
    }
    &.mst_g3mono2, &.mst_g3tri2 {
      height: 8rem;
      width: 16rem;
    }
    &.mst_g3mono4, &.mst_g3tri4 {
      height: 4rem;
    }
  }
}
@media (max-width: $breakpoint_large){
  .device-details-container {
    padding: 2rem 0;
    & .device-help {
      height: auto;
      padding: 4rem 1rem;
      overflow: unset;
      & span:last-child {
        padding-bottom: 2rem;
      }
    }
    & .device-image {
      height: 15rem;
      width: 15rem;
      &.gmd {
        width: 11rem;
      }
      &.eth {
        width: 15rem;
        height: 11rem;
      }
      &.mst_g3tri3, &.mst_g3mono3, &.gmdtri1, &.gmdmono1, &.eth2 {
        width: 75%;
        height: 12rem;
      }
      &.eth2 {
        height: 9rem;
      }
      &.rs485_mc1, &.rs485_mc3 {
        width: 8rem;
        height: 15rem;
      }
    }
  }
}
@media (max-width: $breakpoint) {
  .device-details-container {
    & .device-details {
      & .form-container {
        padding-top: 0;
      }
    }
  }
}
