.tenant-logo {
  border-radius: 25px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  img {
    max-height: 30px;
  }
  label {
    margin: 0;
    padding: 0;
    vertical-align: -2px;
  }
}
