@import "../../assets/styles/_colors.scss";

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1.5rem;
  a {
    display: block;
    color: $primary;
    margin-bottom: 0.5rem;
    &:hover {
      color: $hover-color;
    }
  }
}
