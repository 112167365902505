$padding : 15px;
$margin-vertical: 10px;
$margin-horizontal: 20px;
$breakpoint_mobile: 576px;
$breakpoint_mobile_safe_min: 577px;
$breakpoint : 768px;
$breakpoint_safe_min : 769px;
$breakpoint_large: 992px;
$breakpoint_large_safe_min: 993px;

$margin: 16px;

$main-menu-item-height: 60px;
$main-menu-item-height-mobile: 65px;
$main-menu-content-header-height: 75px;
$main-menu-content-header-height-mobile: 60px;
$menu-width: 250px;
$customers-header-height: 60px;

$headerHeight: 90px;
$padding-rem: 3rem;
