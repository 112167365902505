
.administrativeProcedureDescription {
  color: #afafb7;
  font-size: 0.813rem;
  padding: 0.5rem 1rem;
  line-height: 1.125rem;
}

.downloadArpeCard {
  border-radius: 8px;
  background: #f4f4fa;
  padding: 1rem;
  margin-left: 0.75rem;
  margin-bottom: 1rem;

  p {
    color: #4a4a4a;
    font-size: 0.813rem;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.25rem;

    a {
      color: #018fa7;
      font-size: 1rem;
      font-weight: bold;
      text-decoration-line: underline;
    }
  }
}
