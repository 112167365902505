@import "../../assets/styles/_colors.scss";

.badge-status {
  font-family: Lato, sans-serif;
  font-weight: bold;
  label {
    margin-bottom: 0;
    color: $neutral;
  }
  &.success {
    label {
      color: $correct;
    }
  }
  &.warning {
    label {
      color: $warning;
    }
  }
}
