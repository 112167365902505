@import "../../assets/styles/_colors.scss";

.search-input {
  background-color: white;
  color: $primary;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0;
  input {
    background-color: transparent;
    border: none;
    color: $primary;
    -webkit-transition: 0.5s ease-out;
    -moz-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    width: 0;
    &:focus {
      border: none;
      outline: none !important;
    }
    margin-left: 0;
    margin-top: 1px;
  }
  i {
    cursor: pointer;
    height: 100%;
    padding: 0.3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
  }
  &:hover {
    color: $background;
    background-color: $hover-color;
  }
}

.search-input.expended {
  input {
    transition: 0.5s ease-out;
    width: 100px;
    margin-left: 10px;
  }
  &:hover{
    color: $primary;
    background-color: $background;
  }
  i {
    &:hover {
      color: $hover-color;
    }
  }
}
